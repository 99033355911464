var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "menu" },
      [
        _c("sidebar", {
          staticClass: "sidebar-container",
          attrs: { isCollapse: _vm.isCollapse }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "route-view", class: [_vm.isCollapse ? "resize" : ""] },
      [
        _c("navbar"),
        _c(
          "div",
          { staticClass: "page" },
          [
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [
                _c(
                  "keep-alive",
                  [_vm.keepAlive ? _c("router-view") : _vm._e()],
                  1
                )
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [!_vm.keepAlive ? _c("router-view") : _vm._e()],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }