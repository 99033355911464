import config from './config.js'
function dateFormat(val) {
  if (!val) {
    return ''
  } else {
    const s = (Number(val) - 621355968000000000) / 10000
    const date = new Date(s)
    let month = date.getMonth() + 1
    let day = date.getDate()
    return (
      date.getFullYear() +
      '-' +
      (month > 9 ? month : '0' + month) +
      '-' +
      (day > 9 ? day : '0' + day)
    )
  }
}

function dateTimeFormat(val) {
  if (!val) {
    return ''
  } else {
    const s = (Number(val) - 621355968000000000) / 10000
    const date = new Date(s)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let min = date.getMinutes()
    return (
      date.getFullYear() +
      '-' +
      (month > 9 ? month : '0' + month) +
      '-' +
      (day > 9 ? day : '0' + day) +
      ' ' +
      (hour > 9 ? hour : '0' + hour) +
      ':' +
      (min > 9 ? min : '0' + min)
    )
  }
}

function dateFormatWithOutYear(val) {
  if (!val) {
    return ''
  } else {
    if (val.indexOf('-') > 0) {
      let dateStrings = val.split('-')
      return dateStrings[1] + '-' + dateStrings[2]
    }
    const s = (Number(val) - 621355968000000000) / 10000
    const date = new Date(s)
    let month = date.getMonth() + 1
    let day = date.getDate()
    return (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day)
  }
}

const minute = 1000 * 60
const hour = minute * 60
const day = hour * 24
const week = day * 7
const month = day * 30

function timeStringFormat(val) {
  var time1 = new Date().getTime() //当前的时间戳
  const s = (Number(val) - 621355968000000000) / 10000
  const time2 = new Date(s).getTime()
  var time = time1 - time2

  var result = null
  if (time < 0) {
    return dateFormat(val)
  } else if (time / month >= 1) {
    //result = parseInt(time / month) + "月前";
    return dateFormatWithOutYear(val)
  } else if (time / week >= 1) {
    //result = parseInt(time / week) + "周前";
    return dateFormatWithOutYear(val)
  } else if (time / day >= 1) {
    result = parseInt(time / day) + '天前'
  } else if (time / hour >= 1) {
    result = parseInt(time / hour) + '小时前'
  } else if (time / minute >= 1) {
    result = parseInt(time / minute) + '分钟前'
  } else {
    result = '刚刚'
  }
  return result
}

function progressPercent(beginDate, endDate) {
  let current = new Date().getTime()
  let begin = new Date(dateFormat(beginDate))
  begin = begin.getTime()
  let passDays = (current - begin) / (24 * 60 * 60 * 1000)
  if (passDays <= 0) return 0
  let end = new Date(dateFormat(endDate))
  end = end.getTime()
  let total = (end - begin) / (24 * 60 * 60 * 1000)
  if (total <= 0) return 0
  let ret = passDays / total
  return (ret * 100).toFixed(0)
}

function boolFormat(val) {
  if (val) {
    return '是'
  } else {
    return '否'
  }
}

function dateProgressPercent(beginDate, endDate) {
  let current = new Date().getTime()
  let begin = new Date(dateFormat(beginDate))
  begin = begin.getTime()
  let passDays = (current - begin) / (24 * 60 * 60 * 1000)
  if (passDays <= 0) return 0
  let end = new Date(dateFormat(endDate))
  end = end.getTime()
  let total = (end - begin) / (24 * 60 * 60 * 1000)
  if (total <= 0) return 0
  let ret = passDays / total
  return (ret * 100).toFixed(0)
}

function floatAdd(arg1, arg2) {
  var r1, r2, m
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}

function floatSub(arg1, arg2) {
  var r1, r2, m, n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  //动态控制精度长度
  n = (r1 = r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

function getAgeByDateObj(birth) {
  if (birth) {
    let year = birth.getFullYear()
    let month = birth.getMonth() + 1
    let day = birth.getDate()
    let date = year + '-' + month + '-' + day
    return getAgeByDateString(date)
  }
  return "";
}

function getAgeByDateString(str) {
  var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/)
  if (r == null) return false
  var d = new Date(r[1], r[3] - 1, r[4])
  if (
    d.getFullYear() == r[1] &&
    d.getMonth() + 1 == r[3] &&
    d.getDate() == r[4]
  ) {
    var Y = new Date().getFullYear()
    return Y - r[1]
  }
  return "";
}

function convertCurrency(money) {
  //汉字的数字
  var cnNums = new Array(
    '零',
    '壹',
    '贰',
    '叁',
    '肆',
    '伍',
    '陆',
    '柒',
    '捌',
    '玖'
  )
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆')
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  //整数金额时后面跟的字符
  var cnInteger = '整'
  //整型完以后的单位
  var cnIntLast = '元'
  //最大处理的数字
  var maxNum = 999999999999999.9999
  //金额整数部分
  var integerNum
  //金额小数部分
  var decimalNum
  //输出的中文金额字符串
  var chineseStr = ''
  //分离金额后用的数组，预定义
  var parts
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    //超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

function estimateIsImage(name) {
  let array = name.split('.')
  if (array.length > 1) {
    let ext = array[array.length - 1].toLowerCase()
    if (ext == 'jpg' || ext == 'png' || ext == 'bmp' || ext == 'jpeg') {
      return true
    }
  }
  return false
}

function kneeHurtTypeFormat(val) {
  return config.kneeHurtType.find((r) => r.value === val || r.key === val).name
}

function checkTypeFormat(val) {
  return config.checkType.find((r) => r.value === val || r.key === val).name
}

function improveTypeFormat(val) {
  return config.checkType.find((r) => r.value === val || r.key === val).name
}

function checkSourceFormat(val) {
  return config.checkSource.find((r) => r.value === val || r.key === val).name
}

function runTypeFormat(val) {
  return config.runType.find((r) => r.value === val || r.key === val).name
}

function prescTypeFormat(val) {
  return config.prescType.find((r) => r.value === val || r.key === val).name
}

function wineTypeFormat(val) {
  return config.wineType.find((r) => r.value === val || r.key === val).name
}

function appAccountTypeFormat(val) {
  return config.appAccoutTypes.find((r) => r.value === val || r.key === val)
    .name
}

function attachmentTypeFormat(val) {
  return config.attachmentType.find((r) => r.value === val || r.key === val)
    .name
}

function memberTypeFormat(val) {
  return config.memberType.find((r) => r.value === val || r.key === val).name
}

function traningStatusFormat(val) {
  return config.traningStatus.find((r) => r.value === val || r.key === val).name
}

function kneeHurtZhiTuiShangTaiFormat(val) {
  return config.kneeHurtZhiTuiShangTai.find((r) => r.value === val || r.key === val).name
}
function kneeHurtKuanGuanJieShenZhanFormat(val) {
  return config.kneeHurtKuanGuanJieShenZhan.find((r) => r.value === val || r.key === val).name
}
function kneeHurtXiGuanJieQuShenFormat(val) {
  return config.kneeHurtXiGuanJieQuShen.find((r) => r.value === val || r.key === val).name
}
function kneeHurtHuaiGuanJieLingHuoFormat(val) {
  return config.kneeHurtHuaiGuanJieLingHuo.find((r) => r.value === val || r.key === val).name
}
function kneeHurtKuanGuanJieNeiXuanFormat(val) {
  return config.kneeHurtKuanGuanJieNeiXuan.find((r) => r.value === val || r.key === val).name
}
function kneeHurtDanTuiZhanLiFormat(val) {
  return config.kneeHurtDanTuiZhanLi.find((r) => r.value === val || r.key === val).name
}
function acceptStatusFormat(val){
  return config.acceptableStatus.find((r) => r.value === val || r.key === val).name
}

function kneeHurtGongJianBuFormat(val) {
  if (!val)
    return "";
  let list = val.split(',');
  let result = "";
  list.forEach(e => {
    let valNum = Number.parseInt(e);
    let obj = config.kneeHurtGongJianBu.find((r) => r.value === valNum || r.key === valNum);
    if (obj) {
      result += obj.name;
      result += ";"
    }
  });
  return result;

}

function kneeHurtLuoDiHuanChongFormat(val) {
  if (!val)
    return "";
  let list = val.split(',');
  let result = "";
  list.forEach(e => {
    let valNum = Number.parseInt(e);
    let obj = config.kneeHurtLuoDiHuanChong.find((r) => r.value === valNum || r.key === valNum);
    if (obj) {
      result += obj.name;
      result += ";"
    }
  });
  return result;

}

function kneeHurtShenDunFormat(val) {
  if (!val)
    return "";
  let list = val.split(',');
  let result = "";
  list.forEach(e => {
    let valNum = Number.parseInt(e);
    let obj = config.kneeHurtShenDun.find((r) => r.value === valNum || r.key === valNum);
    if (obj) {
      result += obj.name;
      result += ";"
    }
  });
  return result;

}

function kneeHurtKuanGuanJieJiaoLianFormat(val) {
  if (!val)
    return "";
  let list = val.split(',');
  let result = "";
  list.forEach(e => {
    let valNum = Number.parseInt(e);
    let obj = config.kneeHurtKuanGuanJieJiaoLian.find((r) => r.value === valNum || r.key === valNum);
    if (obj) {
      result += obj.name;
      result += ";"
    }
  });
  return result;
}

export default {
  boolFormat,
  dateFormat,
  dateTimeFormat,
  progressPercent,
  dateFormatWithOutYear,
  timeStringFormat,
  estimateIsImage,
  floatAdd,
  floatSub,

  checkTypeFormat,
  improveTypeFormat,
  checkSourceFormat,
  appAccountTypeFormat,
  runTypeFormat,
  wineTypeFormat,
  getAgeByDateString,
  attachmentTypeFormat,
  memberTypeFormat,
  kneeHurtTypeFormat,
  prescTypeFormat,
  traningStatusFormat,

  kneeHurtZhiTuiShangTaiFormat,
  kneeHurtKuanGuanJieShenZhanFormat,
  kneeHurtXiGuanJieQuShenFormat,
  kneeHurtHuaiGuanJieLingHuoFormat,
  kneeHurtKuanGuanJieNeiXuanFormat,
  kneeHurtDanTuiZhanLiFormat,

  kneeHurtGongJianBuFormat,
  kneeHurtLuoDiHuanChongFormat,
  kneeHurtShenDunFormat,
  kneeHurtKuanGuanJieJiaoLianFormat,
  acceptStatusFormat
}
