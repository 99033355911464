<template>
  <div>
    <attchmentEditor
      :attachments="kneeHurt.Attachments"
      bussiness="KneeHurt"
      :readOnly="disable"
    ></attchmentEditor>
    <el-form
      :model="kneeHurt"
      :rules="kneeHurtRules"
      ref="KneeHurt"
      label-width="60px"
      :disabled="disable"
    >
      <div class="row">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>主动直腿上抬</span>
          </div>
          <el-form-item label="左" prop="LeftZdztstOption">
            <enumSelector
              :options="$config.kneeHurtZhiTuiShangTai"
              v-model="kneeHurt.LeftZdztstOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="LeftZdztst">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.LeftZdztst"
              @change="calZdztstOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="右" prop="RightZdztstOption">
            <enumSelector
              :options="$config.kneeHurtZhiTuiShangTai"
              v-model="kneeHurt.RightZdztstOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="RightZdztst">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.RightZdztst"
              @change="calZdztstOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="评分" prop="TotalZdztst">
            <el-tag type="warning">{{ kneeHurt.TotalZdztst }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>俯卧髋关节主动伸展</span>
          </div>
          <el-form-item label="左" prop="LeftFwzdskOption">
            <enumSelector
              :options="$config.kneeHurtKuanGuanJieShenZhan"
              v-model="kneeHurt.LeftFwzdskOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="LeftFwzdsk">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.LeftFwzdsk"
              @change="calFwzdskOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="右" prop="RightFwzdskOption">
            <enumSelector
              :options="$config.kneeHurtKuanGuanJieShenZhan"
              v-model="kneeHurt.RightFwzdskOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="RightFwzdsk">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.RightFwzdsk"
              @change="calFwzdskOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="评分" prop="TotalFwzdsk">
            <el-tag type="warning">{{ kneeHurt.TotalFwzdsk }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>俯卧膝关节主动屈曲</span>
          </div>
          <el-form-item label="左" prop="LeftFwxgjzdqqOption">
            <enumSelector
              :options="$config.kneeHurtXiGuanJieQuShen"
              v-model="kneeHurt.LeftFwxgjzdqqOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="LeftFwxgjzdqq">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.LeftFwxgjzdqq"
              @change="calFwxgjzdqqOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="右" prop="RightFwxgjzdqqOption">
            <enumSelector
              :options="$config.kneeHurtXiGuanJieQuShen"
              v-model="kneeHurt.RightFwxgjzdqqOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="RightFwxgjzdqq">
            <el-input-number
              size="small"
              :precision="1"
              @change="calFwxgjzdqqOption"
              v-model="kneeHurt.RightFwxgjzdqq"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="评分" prop="TotalFwxgjzdqq">
            <el-tag type="warning">{{ kneeHurt.TotalFwxgjzdqq }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>踝关节灵活性</span>
          </div>
          <el-form-item label="左" prop="LeftHgjlhxOption">
            <enumSelector
              :options="$config.kneeHurtHuaiGuanJieLingHuo"
              v-model="kneeHurt.LeftHgjlhxOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="LeftHgjlhx">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.LeftHgjlhx"
              @change="calHgjlhxOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="右" prop="RightHgjlhxOption">
            <enumSelector
              :options="$config.kneeHurtHuaiGuanJieLingHuo"
              v-model="kneeHurt.RightHgjlhxOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="RightHgjlhx">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.RightHgjlhx"
              @change="calHgjlhxOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="评分" prop="TotalHgjlhx">
            <el-tag type="warning">{{ kneeHurt.TotalHgjlhx }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>俯卧屈膝髋关节内旋</span>
          </div>
          <el-form-item label="左" prop="LeftFwqxgjnxOption">
            <enumSelector
              :options="$config.kneeHurtKuanGuanJieNeiXuan"
              v-model="kneeHurt.LeftFwqxgjnxOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="LeftFwqxgjnx">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.LeftFwqxgjnx"
              @change="calFwqxgjnxOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="右" prop="RightFwqxgjnxOption">
            <enumSelector
              :options="$config.kneeHurtKuanGuanJieNeiXuan"
              v-model="kneeHurt.RightFwqxgjnxOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="RightFwqxgjnx">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.RightFwqxgjnx"
              @change="calFwqxgjnxOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="评分" prop="TotalFwqxgjnx">
            <el-tag type="warning">{{ kneeHurt.TotalFwqxgjnx }}</el-tag>
          </el-form-item>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>动作控制</span>
          </div>
          <el-form-item label="左" prop="LeftBydtzlwdxOption">
            <enumSelector
              :options="$config.kneeHurtDanTuiZhanLi"
              v-model="kneeHurt.LeftBydtzlwdxOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="LeftBydtzlwdx">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.LeftBydtzlwdx"
              @change="calBydtzlwdxOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="右" prop="RightBydtzlwdxOption">
            <enumSelector
              :options="$config.kneeHurtDanTuiZhanLi"
              v-model="kneeHurt.RightBydtzlwdxOption"
            />
          </el-form-item>
          <el-form-item label="值" prop="RightBydtzlwdx">
            <el-input-number
              size="small"
              :precision="1"
              v-model="kneeHurt.RightBydtzlwdx"
              @change="calBydtzlwdxOption"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="评分" prop="TotalBydtzlwdx">
            <el-tag type="warning">{{ kneeHurt.TotalBydtzlwdx }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span> 单腿下蹲 </span>
          </div>
          <el-form-item label="左" prop="LeftGjbOptions">
            <enumSelector
              :multiple="true"
              :multipleValueString="true"
              :options="$config.kneeHurtGongJianBu"
              v-model="kneeHurt.LeftGjbOptions"
            />
          </el-form-item>
          <el-form-item label="分" prop="LeftGjb">
            <el-tag type="info">{{ kneeHurt.LeftGjb }}</el-tag>
          </el-form-item>
          <el-form-item label="右" prop="RightGjbOptions">
            <enumSelector
              :multiple="true"
              :multipleValueString="true"
              :options="$config.kneeHurtGongJianBu"
              v-model="kneeHurt.RightGjbOptions"
            />
          </el-form-item>
          <el-form-item label="分" prop="RightGjb">
            <el-tag type="info">{{ kneeHurt.RightGjb }}</el-tag>
          </el-form-item>
          <el-form-item label="评分" prop="TotalGjb">
            <el-tag type="warning">{{ kneeHurt.TotalGjb }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>髋关节铰链</span>
          </div>
          <el-form-item label="测评" prop="TotalKgjjlOptions">
            <enumSelector
              :multiple="true"
              :multipleValueString="true"
              :options="$config.kneeHurtKuanGuanJieJiaoLian"
              v-model="kneeHurt.TotalKgjjlOptions"
            />
          </el-form-item>
          <el-form-item label="评分" prop="TotalKgjjl">
            <el-tag type="warning">{{ kneeHurt.TotalKgjjl }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>深蹲</span>
          </div>
          <el-form-item label="测评" prop="TotalSdOptions">
            <enumSelector
              :multiple="true"
              :multipleValueString="true"
              :options="$config.kneeHurtShenDun"
              v-model="kneeHurt.TotalSdOptions"
            />
          </el-form-item>
          <el-form-item label="评分" prop="TotalSd">
            <el-tag type="warning">{{ kneeHurt.TotalSd }}</el-tag>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>落地缓冲</span>
          </div>
          <el-form-item label="测评" prop="TotalLdhcOptions">
            <enumSelector
              :multiple="true"
              :multipleValueString="true"
              :options="$config.kneeHurtLuoDiHuanChong"
              v-model="kneeHurt.TotalLdhcOptions"
            />
          </el-form-item>
          <el-form-item label="评分" prop="TotalLdhc">
            <el-tag type="warning">{{ kneeHurt.TotalLdhc }}</el-tag>
          </el-form-item>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>评估结果</span>
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label-width="120" label="关节活动度" prop="Score1">
                <el-tag>{{ score1 }}</el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="120" label="功能动作" prop="Score2">
                <el-tag>{{ score2 }}</el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="120" label="总得分" prop="Score">
                <el-tag>{{ score }}</el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label-width="200"
                label="关节活动度评估结果"
                prop="Score1"
              >
                <el-input
                  type="textarea"
                  rows="5"
                  style="width: 70%"
                  v-model="kneeHurt.Summary1"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label-width="200"
                label="功能动作评估结果"
                prop="Score2"
              >
                <el-input
                  type="textarea"
                  rows="5"
                  style="width: 70%"
                  v-model="kneeHurt.Summary2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
  </div>
</template>
<script>
import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
    value: {
      default: {},
    },
  },
  watch: {
    value: {
      handler(v) {
        this.kneeHurt = v;
        this.ensureAttachments();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.TotalLdhcOptions": {
      handler(v) {
        if (this.kneeHurt.TotalLdhcOptions) {
          if (this.kneeHurt.TotalLdhcOptions.indexOf(0) >= 0) {
            this.kneeHurt.TotalLdhc = 0;
          } else {
            this.kneeHurt.TotalLdhc =
              5 - this.kneeHurt.TotalLdhcOptions.filter((r) => r != 0).length;
          }
        } else {
          this.kneeHurt.TotalLdhc = 5;
        }
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.TotalKgjjlOptions": {
      handler(v) {
        if (this.kneeHurt.TotalKgjjlOptions) {
          if (this.kneeHurt.TotalKgjjlOptions.indexOf(0) >= 0) {
            this.kneeHurt.TotalKgjjl = 0;
          } else {
            this.kneeHurt.TotalKgjjl =
              5 - this.kneeHurt.TotalKgjjlOptions.filter((r) => r != 0).length;
          }
        } else {
          this.kneeHurt.TotalKgjjl = 5;
        }
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.TotalSdOptions": {
      handler(v) {
        debugger;
        if (this.kneeHurt.TotalSdOptions) {
          if (this.kneeHurt.TotalSdOptions.indexOf(0) >= 0) {
            this.kneeHurt.TotalSd = 0;
          } else {
            this.kneeHurt.TotalSd =
              5 - this.kneeHurt.TotalSdOptions.filter((r) => r != 0).length;
          }
        } else {
          this.kneeHurt.TotalSd = 5;
        }
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.LeftGjbOptions": {
      handler(v) {
        if (this.kneeHurt.LeftGjbOptions) {
          if (this.kneeHurt.LeftGjbOptions.indexOf(0) >= 0) {
            this.kneeHurt.LeftGjb = 0;
          } else {
            this.kneeHurt.LeftGjb =
              5 - this.kneeHurt.LeftGjbOptions.filter((r) => r != 0).length;
          }
        } else {
          this.kneeHurt.LeftGjb = 5;
        }
        if (this.kneeHurt.LeftGjb > this.kneeHurt.RightGjb) {
          this.kneeHurt.TotalGjb = this.kneeHurt.RightGjb;
        } else {
          this.kneeHurt.TotalGjb = this.kneeHurt.LeftGjb;
        }
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightGjbOptions": {
      handler(v) {
        if (this.kneeHurt.RightGjbOptions) {
          if (this.kneeHurt.RightGjbOptions.indexOf(0) >= 0) {
            this.kneeHurt.RightGjb = 0;
          } else {
            this.kneeHurt.RightGjb =
              5 - this.kneeHurt.RightGjbOptions.filter((r) => r != 0).length;
          }
        } else {
          this.kneeHurt.RightGjb = 5;
        }
        if (this.kneeHurt.LeftGjb > this.kneeHurt.RightGjb) {
          this.kneeHurt.TotalGjb = this.kneeHurt.RightGjb;
        } else {
          this.kneeHurt.TotalGjb = this.kneeHurt.LeftGjb;
        }
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.LeftBydtzlwdxOption": {
      handler(v) {
        this.calBydtzlwdx();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightBydtzlwdxOption": {
      handler(v) {
        this.calBydtzlwdx();
      },
      deep: true,
      immediate: true,
    },

    "kneeHurt.LeftFwqxgjnxOption": {
      handler(v) {
        this.calFwqxgjnx();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightFwqxgjnxOption": {
      handler(v) {
        this.calFwqxgjnx();
      },
      deep: true,
      immediate: true,
    },

    "kneeHurt.LeftHgjlhxOption": {
      handler(v) {
        this.calHgjlhx();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightHgjlhxOption": {
      handler(v) {
        this.calHgjlhx();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.LeftFwxgjzdqqOption": {
      handler(v) {
        this.calFwxgjzdqq();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightFwxgjzdqqOption": {
      handler(v) {
        this.calFwxgjzdqq();
      },
      deep: true,
      immediate: true,
    },

    "kneeHurt.LeftZdztstOption": {
      handler(v) {
        this.calZdztst();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightZdztstOption": {
      handler(v) {
        this.calZdztst();
      },
      deep: true,
      immediate: true,
    },

    "kneeHurt.LeftFwzdskOption": {
      handler(v) {
        this.calFwzdsk();
      },
      deep: true,
      immediate: true,
    },
    "kneeHurt.RightFwzdskOption": {
      handler(v) {
        this.calFwzdsk();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    options() {},
  },
  components: { attchmentEditor, enumSelector },
  data() {
    return {
      checkType: "KneeHurt",
      //膝关节损伤
      kneeHurt: {},
      kneeHurtRules: {
        Summary1: { required: true, message: "请输入", trigger: "null" },
        Summary2: { required: true, message: "请输入", trigger: "null" },
        Score1: {
          required: true,
          message: "请完成测评",
          trigger: "null",
        },
        Score2: {
          required: true,
          message: "请完成测评",
          trigger: "null",
        },
        Score: {
          required: true,
          message: "请完成测评",
          trigger: "null",
        },
        LeftZdztst: {
          required: true,
          message: "请输入主动直腿上抬",
          trigger: "null",
        },
        LeftZdztstOption: {
          required: true,
          message: "请选择左侧主动直腿上抬",
          trigger: "null",
        },
        RightZdztst: {
          required: true,
          message: "请输入主动直腿上抬",
          trigger: "null",
        },
        RightZdztstOption: {
          required: true,
          message: "请选择右侧主动直腿上抬",
          trigger: "null",
        },

        LeftFwxgjzdqq: {
          required: true,
          message: "请输入俯卧膝关节主动屈曲度数",
          trigger: "null",
        },
        LeftFwxgjzdqqOption: {
          required: true,
          message: "请选择俯卧膝关节主动屈曲",
          trigger: "null",
        },
        RightFwxgjzdqq: {
          required: true,
          message: "请输入俯卧膝关节主动屈曲",
          trigger: "null",
        },
        RightFwxgjzdqqOption: {
          required: true,
          message: "请选择俯卧膝关节主动屈曲",
          trigger: "null",
        },
        LeftHgjlhx: {
          required: true,
          message: "请输入踝关节灵活性",
          trigger: "null",
        },
        LeftHgjlhxOption: {
          required: true,
          message: "请选择左侧踝关节灵活性",
          trigger: "null",
        },
        RightHgjlhx: {
          required: true,
          message: "请输入踝关节灵活性",
          trigger: "null",
        },
        RightHgjlhxOption: {
          required: true,
          message: "请选择右侧踝关节灵活性",
          trigger: "null",
        },
        LeftFwzdsk: {
          required: true,
          message: "请输入俯卧主动伸髋",
          trigger: "null",
        },
        LeftFwzdskOption: {
          required: true,
          message: "请选择左侧俯卧主动伸髋",
          trigger: "null",
        },
        RightFwzdsk: {
          required: true,
          message: "请输入俯卧主动伸髋",
          trigger: "null",
        },
        RightFwzdskOption: {
          required: true,
          message: "请选择右侧俯卧主动伸髋",
          trigger: "null",
        },
        LeftFwqxgjnx: {
          required: true,
          message: "请输入俯卧屈膝髋关节内旋",
          trigger: "null",
        },
        LeftFwqxgjnxOption: {
          required: true,
          message: "请选择左侧俯卧屈膝髋关节内旋",
          trigger: "null",
        },
        RightFwqxgjnx: {
          required: true,
          message: "请输入俯卧屈膝髋关节内旋",
          trigger: "null",
        },
        RightFwqxgjnxOption: {
          required: true,
          message: "请选择右侧俯卧屈膝髋关节内旋",
          trigger: "null",
        },
        LeftBydtzlwdx: {
          required: true,
          message: "请输入动作控制",
          trigger: "null",
        },
        LeftBydtzlwdxOption: {
          required: true,
          message: "请选择左侧动作控制",
          trigger: "null",
        },
        RightBydtzlwdx: {
          required: true,
          message: "请输入动作控制",
          trigger: "null",
        },
        RightBydtzlwdxOption: {
          required: true,
          message: "请选择右侧动作控制",
          trigger: "null",
        },
      },
    };
  },
  computed: {
    score1() {
      let summary1 = "";
      if (this.kneeHurt.TotalZdztst <= 3) {
        summary1 += "主动直腿上抬，";
      }
      if (this.kneeHurt.TotalFwzdsk <= 3) {
        summary1 += "俯卧髋关节主动伸展，";
      }
      if (this.kneeHurt.TotalFwxgjzdqq <= 3) {
        summary1 += "俯卧膝关节主动屈曲，";
      }
      if (this.kneeHurt.TotalFwqxgjnx <= 3) {
        summary1 += "俯卧屈膝髋关节内旋，";
      }
      if (this.kneeHurt.TotalHgjlhx <= 3) {
        summary1 += "踝关节灵活性，";
      }
      if (!summary1) {
        this.kneeHurt.Summary1 = "关节活动度良好。";
      } else {
        this.kneeHurt.Summary1 =
          summary1.substring(0, summary1.length - 1) + "受限！";
      }
      let score1 =
        this.kneeHurt.TotalZdztst +
        this.kneeHurt.TotalFwzdsk +
        this.kneeHurt.TotalFwxgjzdqq +
        this.kneeHurt.TotalFwqxgjnx +
        this.kneeHurt.TotalHgjlhx;
      this.kneeHurt.Score1 = score1 * 2;
      return this.kneeHurt.Score1;
    },
    score2() {
      let summary2 = "";
      if (this.kneeHurt.TotalBydtzlwdx <= 3) {
        summary2 += "动作控制，";
      }
      if (this.kneeHurt.TotalKgjjl <= 3) {
        summary2 += "髋关节铰链，";
      }
      if (this.kneeHurt.TotalSd <= 3) {
        summary2 += "深蹲，";
      }
      if (this.kneeHurt.TotalGjb <= 3) {
        summary2 += "单腿下蹲，";
      }
      if (this.kneeHurt.TotalLdhc <= 3) {
        summary2 += "落地缓冲，";
      }
      if (!summary2) {
        this.kneeHurt.Summary2 = "动作模式良好。";
      } else {
        this.kneeHurt.Summary2 =
          summary2.substring(0, summary2.length - 1) + "较差！";
      }
      let score2 =
        this.kneeHurt.TotalGjb +
        this.kneeHurt.TotalLdhc +
        this.kneeHurt.TotalBydtzlwdx +
        this.kneeHurt.TotalSd +
        this.kneeHurt.TotalKgjjl;
      this.kneeHurt.Score2 = score2 * 2;
      return this.kneeHurt.Score2;
    },
    score() {
      let totalScore =
        this.kneeHurt.TotalZdztst +
        this.kneeHurt.TotalFwzdsk +
        this.kneeHurt.TotalFwxgjzdqq +
        this.kneeHurt.TotalFwqxgjnx +
        this.kneeHurt.TotalHgjlhx +
        this.kneeHurt.TotalBydtzlwdx +
        this.kneeHurt.TotalKgjjl +
        this.kneeHurt.TotalSd +
        this.kneeHurt.TotalGjb +
        this.kneeHurt.TotalLdhc;
      this.kneeHurt.Score = totalScore * 2;
      return this.kneeHurt.Score;
    },
  },
  methods: {
    uploadSuccess(val) {},
    //确保有附件集合对象;
    ensureAttachments() {
      if (!this.kneeHurt.Attachments) {
        this.$set(this.kneeHurt, "Attachments", {
          ItemType: "XTH.Attachment",
          Items: [],
        });
      }
    },
    calBydtzlwdxOption() {
      if(this.kneeHurt.LeftBydtzlwdx>this.kneeHurt.RightBydtzlwdx){
      if(this.kneeHurt.RightBydtzlwdx!=0){
         let distanceValue =this.kneeHurt.LeftBydtzlwdx-this.kneeHurt.RightBydtzlwdx;
         let distanceRate = distanceValue/this.kneeHurt.RightBydtzlwdx;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftBydtzlwdxOption=1;
          this.kneeHurt.RightBydtzlwdxOption=1;
          return;
         }
      }
    }
    if(this.kneeHurt.RightBydtzlwdx>this.kneeHurt.LeftBydtzlwdx){
      if(this.kneeHurt.LeftBydtzlwdx!=0){
         let distanceValue =this.kneeHurt.RightBydtzlwdx-this.kneeHurt.LeftBydtzlwdx;
         let distanceRate = distanceValue/this.kneeHurt.LeftBydtzlwdx;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftBydtzlwdxOption=1;
          this.kneeHurt.RightBydtzlwdxOption=1;
          return;
         }
      }
    }

      if (this.kneeHurt.LeftBydtzlwdx == 0) {
        this.kneeHurt.LeftBydtzlwdxOption = 0;
      } else {
        if (this.kneeHurt.LeftBydtzlwdx <= 7) {
          this.kneeHurt.LeftBydtzlwdxOption = 2;
        }
        if (
          this.kneeHurt.LeftBydtzlwdx > 7 &&
          this.kneeHurt.LeftBydtzlwdx <= 14
        ) {
          this.kneeHurt.LeftBydtzlwdxOption = 3;
        }
        if (
          this.kneeHurt.LeftBydtzlwdx > 14 &&
          this.kneeHurt.LeftBydtzlwdx <= 20
        ) {
          this.kneeHurt.LeftBydtzlwdxOption = 4;
        }
        if (this.kneeHurt.LeftBydtzlwdx > 20) {
          this.kneeHurt.LeftBydtzlwdxOption = 5;
        }
      }

      if (this.kneeHurt.RightBydtzlwdx == 0) {
        this.kneeHurt.RightBydtzlwdxOption = 0;
      } else {
        if (this.kneeHurt.RightBydtzlwdx <= 7) {
          this.kneeHurt.RightBydtzlwdxOption = 2;
        }
        if (
          this.kneeHurt.RightBydtzlwdx > 7 &&
          this.kneeHurt.RightBydtzlwdx <= 14
        ) {
          this.kneeHurt.RightBydtzlwdxOption = 3;
        }
        if (
          this.kneeHurt.RightBydtzlwdx > 14 &&
          this.kneeHurt.RightBydtzlwdx <= 20
        ) {
          this.kneeHurt.RightBydtzlwdxOption = 4;
        }
        if (this.kneeHurt.RightBydtzlwdx > 20) {
          this.kneeHurt.RightBydtzlwdxOption = 5;
        }
      }
    },
    calBydtzlwdx() {
      if (
        !this.kneeHurt.LeftBydtzlwdxOption ||
        !this.kneeHurt.RightBydtzlwdxOption
      ) {
        this.kneeHurt.TotalBydtzlwdx = 0;
        return;
      }
      if (
        this.kneeHurt.LeftBydtzlwdxOption > this.kneeHurt.RightBydtzlwdxOption
      ) {
        this.kneeHurt.TotalBydtzlwdx = this.kneeHurt.RightBydtzlwdxOption;
      } else {
        this.kneeHurt.TotalBydtzlwdx = this.kneeHurt.LeftBydtzlwdxOption;
      }
    },
    calFwqxgjnxOption() {

      if(this.kneeHurt.LeftFwqxgjnx>this.kneeHurt.RightFwqxgjnx){
      if(this.kneeHurt.RightFwqxgjnx!=0){
         let distanceValue =this.kneeHurt.LeftFwqxgjnx-this.kneeHurt.RightFwqxgjnx;
         let distanceRate = distanceValue/this.kneeHurt.RightFwqxgjnx;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftFwqxgjnxOption=1;
          this.kneeHurt.RightFwqxgjnxOption=1;
          return;
         }
      }
    }
    if(this.kneeHurt.RightFwqxgjnx>this.kneeHurt.LeftFwqxgjnx){
      if(this.kneeHurt.LeftFwqxgjnx!=0){
         let distanceValue =this.kneeHurt.RightFwqxgjnx-this.kneeHurt.LeftFwqxgjnx;
         let distanceRate = distanceValue/this.kneeHurt.LeftFwqxgjnx;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftFwqxgjnxOption=1;
          this.kneeHurt.RightFwqxgjnxOption=1;
          return;
         }
      }
    }

      if (this.kneeHurt.LeftFwqxgjnx == 0) {
        this.kneeHurt.LeftFwqxgjnxOption = 0;
      } else {
        if (this.kneeHurt.LeftFwqxgjnx < 20) {
          this.kneeHurt.LeftFwqxgjnxOption = 2;
        }
        if (
          this.kneeHurt.LeftFwqxgjnx >= 20 &&
          this.kneeHurt.LeftFwqxgjnx < 30
        ) {
          this.kneeHurt.LeftFwqxgjnxOption = 3;
        }
        if (
          this.kneeHurt.LeftFwqxgjnx >= 30 &&
          this.kneeHurt.LeftFwqxgjnx < 40
        ) {
          this.kneeHurt.LeftFwqxgjnxOption = 4;
        }
        if (this.kneeHurt.LeftFwqxgjnx >= 40) {
          this.kneeHurt.LeftFwqxgjnxOption = 5;
        }
      }

      if (this.kneeHurt.RightFwqxgjnx == 0) {
        this.kneeHurt.RightFwqxgjnxOption = 0;
      } else {
        if (this.kneeHurt.RightFwqxgjnx < 20) {
          this.kneeHurt.RightFwqxgjnxOption = 2;
        }
        if (
          this.kneeHurt.RightFwqxgjnx >= 20 &&
          this.kneeHurt.RightFwqxgjnx < 30
        ) {
          this.kneeHurt.RightFwqxgjnxOption = 3;
        }
        if (
          this.kneeHurt.RightFwqxgjnx >= 30 &&
          this.kneeHurt.RightFwqxgjnx < 40
        ) {
          this.kneeHurt.RightFwqxgjnxOption = 4;
        }
        if (this.kneeHurt.RightFwqxgjnx >= 40) {
          this.kneeHurt.RightFwqxgjnxOption = 5;
        }
      }
    },
    calFwqxgjnx() {
      if (
        !this.kneeHurt.LeftFwqxgjnxOption ||
        !this.kneeHurt.RightFwqxgjnxOption
      ) {
        this.kneeHurt.TotalFwqxgjnx = 0;
        return;
      }
      if (
        this.kneeHurt.LeftFwqxgjnxOption > this.kneeHurt.RightFwqxgjnxOption
      ) {
        this.kneeHurt.TotalFwqxgjnx = this.kneeHurt.RightFwqxgjnxOption;
      } else {
        this.kneeHurt.TotalFwqxgjnx = this.kneeHurt.LeftFwqxgjnxOption;
      }
    },
    calHgjlhxOption() {

      if(this.kneeHurt.LeftHgjlhx>this.kneeHurt.RightHgjlhx){
      if(this.kneeHurt.RightHgjlhx!=0){
         let distanceValue =this.kneeHurt.LeftHgjlhx-this.kneeHurt.RightHgjlhx;
         let distanceRate = distanceValue/this.kneeHurt.RightHgjlhx;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftHgjlhxOption=1;
          this.kneeHurt.RightHgjlhxOption=1;
          return;
         }
      }
    }
    if(this.kneeHurt.RightHgjlhx>this.kneeHurt.LeftHgjlhx){
      if(this.kneeHurt.LeftHgjlhx!=0){
         let distanceValue =this.kneeHurt.RightHgjlhx-this.kneeHurt.LeftHgjlhx;
         let distanceRate = distanceValue/this.kneeHurt.LeftHgjlhx;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftHgjlhxOption=1;
          this.kneeHurt.RightHgjlhxOption=1;
          return;
         }
      }
    }

      if (this.kneeHurt.LeftHgjlhx == 0) {
        this.kneeHurt.LeftHgjlhxOption = 0;
      } else {
        if (this.kneeHurt.LeftHgjlhx < 20) {
          this.kneeHurt.LeftHgjlhxOption = 2;
        }
        if (this.kneeHurt.LeftHgjlhx >= 20 && this.kneeHurt.LeftHgjlhx < 30) {
          this.kneeHurt.LeftHgjlhxOption = 3;
        }
        if (this.kneeHurt.LeftHgjlhx >= 30 && this.kneeHurt.LeftHgjlhx < 40) {
          this.kneeHurt.LeftHgjlhxOption = 4;
        }
        if (this.kneeHurt.LeftHgjlhx >= 40) {
          this.kneeHurt.LeftHgjlhxOption = 5;
        }
      }

      if (this.kneeHurt.RightHgjlhx == 0) {
        this.kneeHurt.RightHgjlhxOption = 0;
      } else {
        if (this.kneeHurt.RightHgjlhx < 20) {
          this.kneeHurt.RightHgjlhxOption = 2;
        }
        if (this.kneeHurt.RightHgjlhx >= 20 && this.kneeHurt.RightHgjlhx < 30) {
          this.kneeHurt.RightHgjlhxOption = 3;
        }
        if (this.kneeHurt.RightHgjlhx >= 30 && this.kneeHurt.RightHgjlhx < 40) {
          this.kneeHurt.RightHgjlhxOption = 4;
        }
        if (this.kneeHurt.RightHgjlhx >= 40) {
          this.kneeHurt.RightHgjlhxOption = 5;
        }
      }
    },
    calHgjlhx() {
      if (!this.kneeHurt.LeftHgjlhxOption || !this.kneeHurt.RightHgjlhxOption) {
        this.kneeHurt.TotalHgjlhx = 0;
        return;
      }
      if (this.kneeHurt.LeftHgjlhxOption > this.kneeHurt.RightHgjlhxOption) {
        this.kneeHurt.TotalHgjlhx = this.kneeHurt.RightHgjlhxOption;
      } else {
        this.kneeHurt.TotalHgjlhx = this.kneeHurt.LeftHgjlhxOption;
      }
    },
    //俯卧膝关节主动屈曲
    calFwxgjzdqqOption() {

if(this.kneeHurt.LeftFwxgjzdqq>this.kneeHurt.RightFwxgjzdqq){
      if(this.kneeHurt.RightFwxgjzdqq!=0){
         let distanceValue =this.kneeHurt.LeftFwxgjzdqq-this.kneeHurt.RightFwxgjzdqq;
         let distanceRate = distanceValue/this.kneeHurt.RightFwxgjzdqq;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftFwxgjzdqqOption=1;
          this.kneeHurt.RightFwxgjzdqqOption=1;
          return;
         }
      }
    }
    if(this.kneeHurt.RightFwxgjzdqq>this.kneeHurt.LeftFwxgjzdqq){
      if(this.kneeHurt.LeftFwxgjzdqq!=0){
         let distanceValue =this.kneeHurt.RightFwxgjzdqq-this.kneeHurt.LeftFwxgjzdqq;
         let distanceRate = distanceValue/this.kneeHurt.LeftFwxgjzdqq;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftFwxgjzdqqOption=1;
          this.kneeHurt.RightFwxgjzdqqOption=1;
          return;
         }
      }
    }

      if (this.kneeHurt.LeftFwxgjzdqq == 0) {
        this.kneeHurt.LeftFwxgjzdqqOption = 0;
      } else {
        if (this.kneeHurt.LeftFwxgjzdqq < 105) {
          this.kneeHurt.LeftFwxgjzdqqOption = 2;
        }
        if (
          this.kneeHurt.LeftFwxgjzdqq >= 105 &&
          this.kneeHurt.LeftFwxgjzdqq < 120
        ) {
          this.kneeHurt.LeftFwxgjzdqqOption = 3;
        }
        if (
          this.kneeHurt.LeftFwxgjzdqq >= 120 &&
          this.kneeHurt.LeftFwxgjzdqq < 135
        ) {
          this.kneeHurt.LeftFwxgjzdqqOption = 4;
        }
        if (this.kneeHurt.LeftFwxgjzdqq >= 135) {
          this.kneeHurt.LeftFwxgjzdqqOption = 5;
        }
      }

      //
      if (this.kneeHurt.RightFwxgjzdqq == 0) {
        this.kneeHurt.RightFwxgjzdqqOption = 0;
      } else {
        if (this.kneeHurt.RightFwxgjzdqq < 105) {
          this.kneeHurt.RightFwxgjzdqqOption = 2;
        }
        if (
          this.kneeHurt.RightFwxgjzdqq >= 105 &&
          this.kneeHurt.RightFwxgjzdqq < 120
        ) {
          this.kneeHurt.RightFwxgjzdqqOption = 3;
        }
        if (
          this.kneeHurt.RightFwxgjzdqq >= 120 &&
          this.kneeHurt.RightFwxgjzdqq < 135
        ) {
          this.kneeHurt.RightFwxgjzdqqOption = 4;
        }
        if (this.kneeHurt.RightFwxgjzdqq >= 135) {
          this.kneeHurt.RightFwxgjzdqqOption = 5;
        }
      }
    },
    calFwxgjzdqq() {
      if (
        !this.kneeHurt.LeftFwxgjzdqqOption ||
        !this.kneeHurt.RightFwxgjzdqqOption
      ) {
        this.kneeHurt.TotalFwxgjzdqq = 0;
        return;
      }
      if (
        this.kneeHurt.LeftFwxgjzdqqOption > this.kneeHurt.RightFwxgjzdqqOption
      ) {
        this.kneeHurt.TotalFwxgjzdqq = this.kneeHurt.RightFwxgjzdqqOption;
      } else {
        this.kneeHurt.TotalFwxgjzdqq = this.kneeHurt.LeftFwxgjzdqqOption;
      }
    },
    //主动直腿上抬选项
    calZdztstOption() {
    if(this.kneeHurt.LeftZdztst>this.kneeHurt.RightZdztst){
      if(this.kneeHurt.RightZdztst!=0){
         let distanceValue =this.kneeHurt.LeftZdztst-this.kneeHurt.RightZdztst;
         let distanceRate = distanceValue/this.kneeHurt.RightZdztst;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftZdztstOption=1;
          this.kneeHurt.RightZdztstOption=1;
          return;
         }
      }
    }
    if(this.kneeHurt.RightZdztst>this.kneeHurt.LeftZdztst){
      if(this.kneeHurt.LeftZdztst!=0){
         let distanceValue =this.kneeHurt.RightZdztst-this.kneeHurt.LeftZdztst;
         let distanceRate = distanceValue/this.kneeHurt.LeftZdztst;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftZdztstOption=1;
          this.kneeHurt.RightZdztstOption=1;
          return;
         }
      }
    }

      if (this.kneeHurt.LeftZdztst == 0) {
        this.kneeHurt.LeftZdztstOption = 0;
      } else {
        if (this.kneeHurt.LeftZdztst < 30) {
          this.kneeHurt.LeftZdztstOption = 2;
        }
        if (this.kneeHurt.LeftZdztst >= 30 && this.kneeHurt.LeftZdztst < 50) {
          this.kneeHurt.LeftZdztstOption = 3;
        }
        if (this.kneeHurt.LeftZdztst >= 50 && this.kneeHurt.LeftZdztst < 70) {
          this.kneeHurt.LeftZdztstOption = 4;
        }
        if (this.kneeHurt.LeftZdztst > 70) {
          this.kneeHurt.LeftZdztstOption = 5;
        }
      }

      if (this.kneeHurt.RightZdztst == 0) {
        this.kneeHurt.RightZdztstOption = 0;
      } else {
        if (this.kneeHurt.RightZdztst < 30) {
          this.kneeHurt.RightZdztstOption = 2;
        }
        if (this.kneeHurt.RightZdztst >= 30 && this.kneeHurt.RightZdztst < 50) {
          this.kneeHurt.RightZdztstOption = 3;
        }
        if (this.kneeHurt.RightZdztst >= 50 && this.kneeHurt.RightZdztst < 70) {
          this.kneeHurt.RightZdztstOption = 4;
        }
        if (this.kneeHurt.RightZdztst > 70) {
          this.kneeHurt.RightZdztstOption = 5;
        }
      }
    },
    //主动直腿上抬得分
    calZdztst() {
      if (!this.kneeHurt.LeftZdztstOption || !this.kneeHurt.RightZdztstOption) {
        this.kneeHurt.TotalZdztst = 0;
        return;
      }
      if (this.kneeHurt.LeftZdztstOption > this.kneeHurt.RightZdztstOption) {
        this.kneeHurt.TotalZdztst = this.kneeHurt.RightZdztstOption;
      } else {
        this.kneeHurt.TotalZdztst = this.kneeHurt.LeftZdztstOption;
      }
    },

    //俯卧髋关节主动伸展
    calFwzdskOption() {
      if(this.kneeHurt.LeftFwzdsk>this.kneeHurt.RightFwzdsk){
      if(this.kneeHurt.RightFwzdsk!=0){
         let distanceValue =this.kneeHurt.LeftFwzdsk-this.kneeHurt.RightFwzdsk;
         let distanceRate = distanceValue/this.kneeHurt.RightFwzdsk;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftFwzdskOption=1;
          this.kneeHurt.RightFwzdskOption=1;
          return;
         }
      }
    }
    if(this.kneeHurt.RightFwzdsk>this.kneeHurt.LeftFwzdsk){
      if(this.kneeHurt.LeftFwzdsk!=0){
         let distanceValue =this.kneeHurt.RightFwzdsk-this.kneeHurt.LeftFwzdsk;
         let distanceRate = distanceValue/this.kneeHurt.LeftFwzdsk;
         if(distanceRate>=0.1){
          this.kneeHurt.LeftFwzdskOption=1;
          this.kneeHurt.RightFwzdskOption=1;
          return;
         }
      }
    }

      if (this.kneeHurt.LeftFwzdsk == 0) {
        this.kneeHurt.LeftFwzdskOption = 0;
      } else {
        if (this.kneeHurt.LeftFwzdsk < 10) {
          this.kneeHurt.LeftFwzdskOption = 2;
        }
        if (this.kneeHurt.LeftFwzdsk >= 10 && this.kneeHurt.LeftFwzdsk < 20) {
          this.kneeHurt.LeftFwzdskOption = 3;
        }
        if (this.kneeHurt.LeftFwzdsk >= 20 && this.kneeHurt.LeftFwzdsk < 30) {
          this.kneeHurt.LeftFwzdskOption = 4;
        }
        if (this.kneeHurt.LeftFwzdsk >= 30) {
          this.kneeHurt.LeftFwzdskOption = 5;
        }
      }

      if (this.kneeHurt.RightFwzdsk == 0) {
        this.kneeHurt.RightFwzdskOption = 0;
      } else {
        if (this.kneeHurt.RightFwzdsk < 10) {
          this.kneeHurt.RightFwzdskOption = 2;
        }
        if (this.kneeHurt.RightFwzdsk >= 10 && this.kneeHurt.RightFwzdsk < 20) {
          this.kneeHurt.RightFwzdskOption = 3;
        }
        if (this.kneeHurt.RightFwzdsk >= 20 && this.kneeHurt.RightFwzdsk < 30) {
          this.kneeHurt.RightFwzdskOption = 4;
        }
        if (this.kneeHurt.RightFwzdsk >= 30) {
          this.kneeHurt.RightFwzdskOption = 5;
        }
      }
    },
    calFwzdsk() {
      if (!this.kneeHurt.LeftFwzdskOption || !this.kneeHurt.RightFwzdskOption) {
        this.kneeHurt.TotalFwzdsk = 0;
        return;
      }
      if (this.kneeHurt.LeftFwzdskOption > this.kneeHurt.RightFwzdskOption) {
        this.kneeHurt.TotalFwzdsk = this.kneeHurt.RightFwzdskOption;
      } else {
        this.kneeHurt.TotalFwzdsk = this.kneeHurt.LeftFwzdskOption;
      }
    },
    doValidate() {
      this.$refs[this.checkType].validate((valid) => {
        if (valid) {
          this.$emit("passValidate", this.kneeHurt);
        } else {
          setTimeout(() => {
            this.$refs[this.checkType].clearValidate();
          }, 3000);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 20px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      padding-right: 50px;
      .form-item {
        .form-item-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
        .form-item-label {
          text-align: center;
          padding: 10px 0;
          font-size: 14px;
          width: 100%;
          font-weight: bolder;
        }
      }
    }
  }
  .form-item {
    .form-item-label {
      text-align: center;
      padding: 10px 0;
    }
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>