<template>
  <el-select
    v-model="data"
    @change="dataChange"
    value-key="key"
    :clearable="clearable"
    :multiple="multiple"
    @clear="dataChange"
    style="width: 100%"
    :disabled="disabled"
    :size="size"
  >
    <el-option
      v-for="item in options"
      :key="item.key"
      :label="item.name"
      :value="item.key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    size: {
      default: "small",
    },
    value: {
      default: "",
    },
    options: {
      default: [],
    },
    clearable: {
      default: true,
    },
    disabled: {
      default: false,
    },
    multiple: {
      default: false,
    },
  },
  data() {
    return {
      data: "",
    };
  },
  watch: {
    value: {
      handler(v) {
          this.data = v;
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler(v) {},
      deep: true,
      immediate: true,
    },
  },
  methods: {
    dataChange(v) {
        this.$emit("change", v);
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>