<template>
  <el-drawer :title="formTitle" size="60%" :visible.sync="formVisible">
    <customerEditor
      ref="customerEditor"
      :readOnly="readOnly"
      @update="update"
    />
  </el-drawer>
</template>
<script>
import customerEditor from "./customerEditor.vue";
import { updateCheckMemberBaseInfo } from "@/api/check/check.js";
export default {
  components: {
    customerEditor,
  },
  props: {
    readOnly: {
      default: false,
    },
    checkKey: {
      default: "",
    },
  },
  data() {
    return {
      formTitle: "客户信息",
      formVisible: false,
    };
  },
  methods: {
    update() {
      this.$emit("update");
      if (this.checkKey) {
        let param = { key: this.checkKey };
        updateCheckMemberBaseInfo(param);
      }
      this.formVisible = false;
    },
    show(key) {
      this.formTitle = "客户信息";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.customerEditor.show(key);
      });
    },
    handleCreate() {
      this.formTitle = "新增客户信息";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.customerEditor.handleCreate();
      });
    },
    handleEdit(row) {
      this.formTitle = "修改客户信息";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.customerEditor.handleEdit(row);
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px;
}
/deep/.el-form-item__error {
  padding-top: 0;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-drawer__header {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f !important;
}
.footer {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
}
</style>