var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dragDialog", rawName: "v-dragDialog" }],
      attrs: {
        title: "预约问卷自测详情",
        visible: _vm.formVisible,
        width: "1100px",
        "close-on-click-modal": false,
        "append-to-body": true
      },
      on: { close: _vm.close }
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _vm.heartKey
            ? _c(
                "el-tab-pane",
                { attrs: { label: "心血管风险筛查", name: "Heart" } },
                [
                  _c("heartEditor", {
                    ref: "heartEditor",
                    attrs: {
                      memberKey: _vm.memberKey,
                      keyValue: _vm.heartKey,
                      readOnly: true
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ikdcKey
            ? _c(
                "el-tab-pane",
                { attrs: { label: "IKDC", name: "IKDC" } },
                [
                  _c("ikdceditor", {
                    ref: "ikdceditor",
                    attrs: {
                      memberKey: _vm.memberKey,
                      keyValue: _vm.ikdcKey,
                      readOnly: true
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.vasKey
            ? _c(
                "el-tab-pane",
                { attrs: { label: "VAS", name: "VAS" } },
                [
                  _c("vasEditor", {
                    ref: "vasEditor",
                    attrs: {
                      memberKey: _vm.memberKey,
                      keyValue: _vm.vasKey,
                      readOnly: true
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }