import request from '@/utils/request'

export function getUserPageInfo() {
  return request({
    url: 'member/getUserPages',
    method: 'post',
  })
}

export function getAll(data) {
  return request({
    url: 'member/getAll',
    method: 'post',
    data,
  })
}

export function getMemberRoles(data) {
  return request({
    url: 'member/getMemberRoles',
    method: 'post',
    data,
  })
}

export function addMemberRole(data) {
  return request({
    url: 'member/addMemberRole',
    method: 'post',
    data,
  })
}

export function deleteMemberRole(data) {
  return request({
    url: 'member/deleteMemberRole',
    method: 'post',
    data,
  })
}

export function save(data) {
  return request({
    url: 'member/save',
    method: 'post',
    data,
  })
}

export function addDesc(data) {
  return request({
    url: 'member/addDesc',
    method: 'post',
    data,
  })
}

export function del(data) {
  return request({
    url: 'member/deleteMember',
    method: 'post',
    data,
  })
}

export function getPageList(data) {
  return request({
    url: 'member/getPageList',
    method: 'post',
    data,
  })
}

export function changePassword(data) {
  return request({
    url: 'member/changePassword',
    method: 'post',
    data,
  })
}

export function login(data) {
  return request({
    url: 'member/login',
    method: 'post',
    data
  })
}

export function logout(token) {
  const data = { token }
  return request({
    url: 'member/logout',
    method: 'post',
    data,
  })
}

export function getAuthorityRestPage(data) {
  return request({
    url: 'member/getAuthorityRestPage',
    method: 'post',
    data,
  })
}

export function getAuthorityUserAllPage(data) {
  return request({
    url: 'member/getAuthorityUserAllPage',
    method: 'post',
    data,
  })
}

export function getAuthorityPage(data) {
  return request({
    url: 'member/getAuthorityPage',
    method: 'post',
    data,
  })
}

export function deleteAuthorityPage(data) {
  return request({
    url: 'member/deleteAuthorityPage',
    method: 'post',
    data,
  })
}

export function saveAuthorityPage(data) {
  return request({
    url: 'member/saveAuthorityPage',
    method: 'post',
    data,
  })
}

export function getRightsProcess(data) {
  return request({
    url: 'member/getRightsProcess',
    method: 'post',
    data,
  })
}

export function changeMemberType(data) {
  return request({
    url: 'member/changeMemberType',
    method: 'post',
    data,
  })
}

export function getVerifyCode(data) {
  return request({
    url: 'member/getVerifyCode',
    method: 'post',
    data
  })
}

export function updateBloodPressure(data) {
  return request({
    url: 'member/updateBloodPressure',
    method: 'post',
    data,
  })
}

export function getAllCustomer(data) {
  return request({
    url: 'member/getAllCustomer',
    method: 'post',
    data,
  })
}


export function getPageListCustomer(data) {
  return request({
    url: 'member/getPageListCustomer',
    method: 'post',
    data,
  })
}

export function getSingle(data) {
  return request({
    url: 'member/getSingle',
    method: 'post',
    data,
  })
}
