var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("attchmentEditor", {
        attrs: {
          attachments: _vm.runPosture.Attachments,
          bussiness: "RunPosture",
          readOnly: _vm.disable
        },
        on: { uploaded: _vm.uploadSuccess }
      }),
      _c(
        "el-form",
        {
          ref: "RunPosture",
          attrs: {
            model: _vm.runPosture,
            rules: _vm.runPostureRules,
            "label-width": "120px",
            disabled: _vm.disable
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("步态参数")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("基础指标")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "配速:", prop: "Speed" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Speed,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Speed", $$v)
                                },
                                expression: "runPosture.Speed"
                              }
                            }),
                            _vm._v("千米/小时(km/h) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "弹性能量交换",
                              prop: "ElasticExchange"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ElasticExchange,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "ElasticExchange",
                                    $$v
                                  )
                                },
                                expression: "runPosture.ElasticExchange"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "步频", prop: "Striderate" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Striderate,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Striderate", $$v)
                                },
                                expression: "runPosture.Striderate"
                              }
                            }),
                            _vm._v("次/分钟 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "跨步时间", prop: "StrideTime" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.StrideTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "StrideTime", $$v)
                                },
                                expression: "runPosture.StrideTime"
                              }
                            }),
                            _vm._v("秒（s） ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "步幅", prop: "Stridelength" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Stridelength,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Stridelength", $$v)
                                },
                                expression: "runPosture.Stridelength"
                              }
                            }),
                            _vm._v("米(m) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "垂直振幅", prop: "Vdisp" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Vdisp,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Vdisp", $$v)
                                },
                                expression: "runPosture.Vdisp"
                              }
                            }),
                            _vm._v("米(m) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "触地时间", prop: "Contacttime" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Contacttime,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Contacttime", $$v)
                                },
                                expression: "runPosture.Contacttime"
                              }
                            }),
                            _vm._v("秒（s） ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("角度")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "足部触地角度",
                              prop: "Footstrikeangle"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Footstrikeangle,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "Footstrikeangle",
                                    $$v
                                  )
                                },
                                expression: "runPosture.Footstrikeangle"
                              }
                            }),
                            _vm._v("度 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "前倾角度", prop: "Spineangle" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Spineangle,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Spineangle", $$v)
                                },
                                expression: "runPosture.Spineangle"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("作用力")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "垂直作用力", prop: "Vertforce" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Vertforce,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Vertforce", $$v)
                                },
                                expression: "runPosture.Vertforce"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "制动力", prop: "Brakeforce" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Brakeforce,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Brakeforce", $$v)
                                },
                                expression: "runPosture.Brakeforce"
                              }
                            }),
                            _vm._v("力(N) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "侧向作用力", prop: "Latforce" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Latforce,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Latforce", $$v)
                                },
                                expression: "runPosture.Latforce"
                              }
                            }),
                            _vm._v("力(N) ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("评估结果")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "跑步经济性",
                              prop: "WorkTotalNorm"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.WorkTotalNorm,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "WorkTotalNorm", $$v)
                                },
                                expression: "runPosture.WorkTotalNorm"
                              }
                            }),
                            _vm._v("Joules/kg/m ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "步态评分", prop: "Striderating" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.Striderating,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "Striderating", $$v)
                                },
                                expression: "runPosture.Striderating"
                              }
                            }),
                            _vm._v("星级/5星 ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("总机械功")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "身体做功",
                              prop: "WorkExternalVlocityNorm"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.WorkExternalVlocityNorm,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "WorkExternalVlocityNorm",
                                    $$v
                                  )
                                },
                                expression: "runPosture.WorkExternalVlocityNorm"
                              }
                            }),
                            _vm._v("Joules/kg/m ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "恢复做功",
                              prop: "WorkInternalNorm"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.WorkInternalNorm,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "WorkInternalNorm",
                                    $$v
                                  )
                                },
                                expression: "runPosture.WorkInternalNorm"
                              }
                            }),
                            _vm._v("Joules/kg/m ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "重力做功",
                              prop: "WorkExternalGravityNorm"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.WorkExternalGravityNorm,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "WorkExternalGravityNorm",
                                    $$v
                                  )
                                },
                                expression: "runPosture.WorkExternalGravityNorm"
                              }
                            }),
                            _vm._v("Joules/kg/m ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("力与力矩")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "关节压力指数", prop: "Jointloading" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.runPosture.Jointloading,
                              callback: function($$v) {
                                _vm.$set(_vm.runPosture, "Jointloading", $$v)
                              },
                              expression: "runPosture.Jointloading"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("膝关节")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "矢状面力矩左",
                              prop: "KneeMomentSagittalLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeMomentSagittalLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeMomentSagittalLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeMomentSagittalLeft"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "矢状面力矩右",
                              prop: "KneeMomentSagittalRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeMomentSagittalRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeMomentSagittalRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeMomentSagittalRight"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "额状面力矩左",
                              prop: "KneemomentFrontalLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneemomentFrontalLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneemomentFrontalLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneemomentFrontalLeft"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "额状面力矩右",
                              prop: "KneemomentFrontalRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneemomentFrontalRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneemomentFrontalRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneemomentFrontalRight"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "垂直作用力左",
                              prop: "KneeforceVerticalLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeforceVerticalLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeforceVerticalLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeforceVerticalLeft"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "垂直作用力右",
                              prop: "KneeforceVerticalRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeforceVerticalRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeforceVerticalRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeforceVerticalRight"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "侧向作用力左",
                              prop: "KneeforceMediolateralLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeforceMediolateralLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeforceMediolateralLeft",
                                    $$v
                                  )
                                },
                                expression:
                                  "runPosture.KneeforceMediolateralLeft"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "侧向作用力右",
                              prop: "KneeforceMediolateralRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.runPosture.KneeforceMediolateralRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeforceMediolateralRight",
                                    $$v
                                  )
                                },
                                expression:
                                  "runPosture.KneeforceMediolateralRight"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("髋关节")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "矢状面力矩左",
                              prop: "HipMomentSagittalLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipMomentSagittalLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipMomentSagittalLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.HipMomentSagittalLeft"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "矢状面力矩右",
                              prop: "HipMomentSagittalRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipMomentSagittalRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipMomentSagittalRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.HipMomentSagittalRight"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "额状面力矩左",
                              prop: "HipmomentFrontalLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipmomentFrontalLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipmomentFrontalLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.HipmomentFrontalLeft"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "额状面力矩右",
                              prop: "HipmomentFrontalRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipmomentFrontalRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipmomentFrontalRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.HipmomentFrontalRight"
                              }
                            }),
                            _vm._v("BWm ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "垂直作用力左",
                              prop: "HipforceVerticalLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipforceVerticalLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipforceVerticalLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.HipforceVerticalLeft"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "垂直作用力右",
                              prop: "HipforceVerticalRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipforceVerticalRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipforceVerticalRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.HipforceVerticalRight"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "侧向作用力左",
                              prop: "HipforceMediolateralLeft"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipforceMediolateralLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipforceMediolateralLeft",
                                    $$v
                                  )
                                },
                                expression:
                                  "runPosture.HipforceMediolateralLeft"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "侧向作用力右",
                              prop: "HipforceMediolateralRight"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.HipforceMediolateralRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "HipforceMediolateralRight",
                                    $$v
                                  )
                                },
                                expression:
                                  "runPosture.HipforceMediolateralRight"
                              }
                            }),
                            _vm._v("BW ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "20px" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("步态分析-矢状面")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("触地时膝关节屈曲度")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "总", prop: "KneeFlexLanding" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeFlexLanding,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeFlexLanding",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeFlexLanding"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "左", prop: "KneeflexlandingLeft" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeflexlandingLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeflexlandingLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeflexlandingLeft"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "右", prop: "KneeflexlandingRight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeflexlandingRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeflexlandingRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeflexlandingRight"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("站立时最大膝关节屈曲度")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "总", prop: "KneeFlexStanceMax" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeFlexStanceMax,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeFlexStanceMax",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeFlexStanceMax"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "左", prop: "KneeFlexStanceLeft" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeFlexStanceLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeFlexStanceLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeFlexStanceLeft"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "右", prop: "KneeFlexStanceRight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeFlexStanceRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeFlexStanceRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeFlexStanceRight"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("摆动时最大膝关节屈曲度")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "总", prop: "KneeflexswingMax" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeflexswingMax,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeflexswingMax",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeflexswingMax"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "左", prop: "KneeflexswingLeft" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeflexswingLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeflexswingLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeflexswingLeft"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "右", prop: "KneeflexswingRight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.KneeflexswingRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "KneeflexswingRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.KneeflexswingRight"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("股骨最大屈曲度")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "总", prop: "ThighflexMax" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ThighflexMax,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "ThighflexMax", $$v)
                                },
                                expression: "runPosture.ThighflexMax"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "左", prop: "ThighflexLeft" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ThighflexLeft,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "ThighflexLeft", $$v)
                                },
                                expression: "runPosture.ThighflexLeft"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "右", prop: "ThighflexRight" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ThighflexRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "ThighflexRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.ThighflexRight"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("股骨最大伸展度")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "总", prop: "ThighextMax" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ThighextMax,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "ThighextMax", $$v)
                                },
                                expression: "runPosture.ThighextMax"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "左", prop: "ThighextLeft" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ThighextLeft,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "ThighextLeft", $$v)
                                },
                                expression: "runPosture.ThighextLeft"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "右", prop: "ThighextRight" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ThighextRight,
                                callback: function($$v) {
                                  _vm.$set(_vm.runPosture, "ThighextRight", $$v)
                                },
                                expression: "runPosture.ThighextRight"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("触地时小腿角度")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "总", prop: "ShankangleLeft" } },
                          [_vm._v(" " + _vm._s(_vm.Shankangle) + "度(°) ")]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "左", prop: "ShankangleLeft" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ShankangleLeft,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "ShankangleLeft",
                                    $$v
                                  )
                                },
                                expression: "runPosture.ShankangleLeft"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "右", prop: "ShankangleRight" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.runPosture.ShankangleRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.runPosture,
                                    "ShankangleRight",
                                    $$v
                                  )
                                },
                                expression: "runPosture.ShankangleRight"
                              }
                            }),
                            _vm._v("度(°) ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("步态分析-额状面+三维动作评分")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c(
                    "div",
                    { staticClass: "form-row", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        { staticClass: "form-item" },
                        [
                          _c("div", { staticClass: "form-item-label" }, [
                            _vm._v("步态离散度")
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左", prop: "StepwidthLeft" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.runPosture.StepwidthLeft,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.runPosture,
                                      "StepwidthLeft",
                                      $$v
                                    )
                                  },
                                  expression: "runPosture.StepwidthLeft"
                                }
                              }),
                              _vm._v("米(m) ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右", prop: "StepwidthRight" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.runPosture.StepwidthRight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.runPosture,
                                      "StepwidthRight",
                                      $$v
                                    )
                                  },
                                  expression: "runPosture.StepwidthRight"
                                }
                              }),
                              _vm._v("米(m) ")
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-item-label" }, [
                            _vm._v("左膝内外翻角度")
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左", prop: "KneerotLeft" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.runPosture.KneerotLeft,
                                  callback: function($$v) {
                                    _vm.$set(_vm.runPosture, "KneerotLeft", $$v)
                                  },
                                  expression: "runPosture.KneerotLeft"
                                }
                              }),
                              _vm._v("度(°) ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右", prop: "KneerotRight" } },
                            [
                              _c("el-input-number", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.runPosture.KneerotRight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.runPosture,
                                      "KneerotRight",
                                      $$v
                                    )
                                  },
                                  expression: "runPosture.KneerotRight"
                                }
                              }),
                              _vm._v("度(°) ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-item" },
                        [
                          _c("div", { staticClass: "form-item-label" }, [
                            _vm._v("评分")
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "跑步性能得分", prop: "Score1" }
                            },
                            [_c("el-tag", [_vm._v(_vm._s(_vm.score1))])],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "跑步特征得分", prop: "Score2" }
                            },
                            [_c("el-tag", [_vm._v(_vm._s(_vm.score2))])],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "关节负荷得分", prop: "Score3" }
                            },
                            [_c("el-tag", [_vm._v(_vm._s(_vm.score3))])],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "三维动作总分", prop: "Score" } },
                            [
                              _c("el-tag", [
                                _vm._v(_vm._s(_vm.runPosture.Score.toFixed(0)))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-item",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c("div", { staticClass: "form-item-label" }, [
                            _vm._v("评估结果")
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "跑者类型", prop: "Level" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "请选择"
                                  },
                                  on: { change: _vm.caculateSummary },
                                  model: {
                                    value: _vm.runPosture.Level,
                                    callback: function($$v) {
                                      _vm.$set(_vm.runPosture, "Level", $$v)
                                    },
                                    expression: "runPosture.Level"
                                  }
                                },
                                _vm._l(_vm.runTypes, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "评估结果", prop: "Summary" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: { type: "textarea", rows: "5" },
                                model: {
                                  value: _vm.runPosture.Summary,
                                  callback: function($$v) {
                                    _vm.$set(_vm.runPosture, "Summary", $$v)
                                  },
                                  expression: "runPosture.Summary"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }