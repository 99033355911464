<template>
  <el-card shadow="never">
    <div slot="header">
      <span>{{ title }}</span>
      <el-tag v-if="!readOnly" type="warning">{{ desc }}</el-tag>
      <div style="float: right">
        <div v-if="!readOnly" style="display: flex; align-items: center">
          <el-tag>附件类型</el-tag>
          <enumSelector
            ref="enumSelector"
            :options="options"
            :clearable="false"
            size="small"
            v-model="attachmentType"
          />
          <el-button
            class="submit-btn"
            type="warning"
            size="mini"
            @click="selectFile"
            >点此上传</el-button
          >
        </div>
      </div>
    </div>
    <div class="files" style="margin-bottom: 20px">
      <el-table
        size="small"
        :data="attachments.Items"
        highlight-current-row
        expand-on-click-node="false"
        :show-header="false"
      >
        <el-table-column type="index" width="50" sortable> </el-table-column>
        <el-table-column prop="Name" label="文件名称" min-width="150" sortable>
          <template slot-scope="scope">
            <span style="font-size: 14px">{{ scope.row.Name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Name" label="文件类别" min-width="120" sortable>
          <template slot-scope="scope">
            <span style="font-size: 14px">{{
              format.attachmentTypeFormat(scope.row.Type)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Url" label="查看" width="200" sortable>
          <template slot-scope="scope">
            <viewer v-if="format.estimateIsImage(scope.row.Name)">
              <img
                v-if="scope.row.Path"
                :src="baseUrl + scope.row.Path"
                :key="scope.row.Path"
                style="height: 40px"
              />
            </viewer>
            <span v-else style="font-size: 14px"
              ><a :href="baseUrl + scope.row.Path" target="_blank">
                点此下载</a
              ></span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="Url"
          label="操作"
          width="100"
          v-if="!readOnly"
          sortable
        >
          <template slot-scope="scope">
            <el-button
              class="submit-btn"
              type="danger"
              size="mini"
              @click="handleRemoveFile(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <fileUploadNoUI ref="fileUploadNoUI" @uploadSuccess="uploadSuccess" />
    </div>
  </el-card>
</template>
<script>
import fileUploadNoUI from "@/components/fileUploadNoUI";
import enumSelector from "@/components/enumSelector";
export default {
  components: {
    fileUploadNoUI,
    enumSelector,
  },
  props: {
    title: {
      default: "附件",
    },
    desc: {
      default: "请选择类型后上传",
    },
    readOnly: {
      default: false,
    },
    attachments: {
      default: [],
    },
    bussiness: "",
  },
  data() {
    return {
      attachmentType: "Other",
    };
  },
  computed: {
    options() {
      if (this.bussiness) {
        switch (this.bussiness) {
          case "HeartEcg":
            return this.$config.attachmentType.filter(
              (r) => (r.value >= 800 && r.value < 900) || r.value == 1000
            );
          case "FootPressure":
            return this.$config.attachmentType.filter(
              (r) => (r.value >= 100 && r.value < 200) || r.value == 1000
            );
          case "HeartLung":
            return this.$config.attachmentType.filter(
              (r) => (r.value >= 500 && r.value < 600) || r.value == 1000
            );
          case "BodyComposition":
            return this.$config.attachmentType.filter(
              (r) => (r.value >= 400 && r.value < 500) || r.value == 1000
            );
          case "RunPosture":
            return this.$config.attachmentType.filter(
              (r) => (r.value >= 600 && r.value < 700) || r.value == 1000
            );
          case "Breath":
            return this.$config.attachmentType.filter(
              (r) => (r.value >= 700 && r.value < 800) || r.value == 1000
            );
          case "BodyFunction":
          case "KneeHurt":
          default:
            return this.$config.attachmentType.filter((r) => r.value == 1000);
        }
      } else {
        return this.$config.attachmentType.filter((r) => r.value == 1000);
      }
    },
  },
  watch: {},
  methods: {
    selectFile() {
      this.$refs.fileUploadNoUI.chooseFile(this.attachmentType);
    },
    uploadSuccess(val) {
      this.attachments.Items.push(val);
      this.$emit("uploaded", { bussiness: this.bussiness, file: val });
    },
    handleRemoveFile(val) {
      const index = this.attachments.Items.indexOf(val);
      if (index >= 0) {
        this.attachments.Items.splice(index, 1);
      }
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-card__header {
  font-size: 16px;
}
/deep/.el-card__body {
  padding-bottom: 0px;
}
</style>
