var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-upload", {
    ref: "upload",
    attrs: {
      action: "1",
      "auto-upload": true,
      limit: 1,
      "show-file-list": false,
      "on-change": _vm.fileChange,
      "before-upload": _vm.beforeUpload,
      "http-request": _vm.uploadSubmit
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }