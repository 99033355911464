var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-container" },
    _vm._l(_vm.routeHistory, function(item) {
      return _c(
        "el-tag",
        {
          key: item.name,
          staticClass: "tag",
          class: { "tag-active": _vm.routeName === item.name },
          attrs: { closable: "" },
          on: {
            click: function($event) {
              return _vm.tagTo(item)
            },
            close: function($event) {
              return _vm.tagClose(item)
            }
          }
        },
        [_vm._v(_vm._s(item.name))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }