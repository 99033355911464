import request from '@/utils/request'

export function getPageList(data) {
    return request({
      url: 'checkSelf/getPageList',
      method: 'post',
      data
    })
  }

  export function save(data) {
    return request({
      url: 'checkSelf/save',
      method: 'post',
      data
    })
  }

  export function get(data) {
    return request({
      url: 'checkSelf/get',
      method: 'post',
      data
    })
  }

  export function getByKey(data) {
    return request({
      url: 'checkSelf/getByKey',
      method: 'post',
      data
    })
  }