<template>
  <div class="customer">
    <el-descriptions :column="5">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          编号 </template
        >{{ checkInfo.Code }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"><i class="el-icon-user"></i>受试人</template>
        <span>{{ checkInfo.Member.RealName }}</span>
        <span>@{{ checkInfo.Member.Mobile }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <span v-if="checkInfo.Member">
            <i v-if="checkInfo.Member.Sex == '女'" class="el-icon-female"></i>
            <i v-else class="el-icon-male">性别年龄</i>
          </span>
        </template>
        <span v-if="checkInfo.Member"
          >{{ checkInfo.Member.Sex }}/
          {{
            format.getAgeByDateString(
              format.dateFormat(checkInfo.Member.Birthday)
            )
          }}岁</span
        >
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> <i class="el-icon-star-off"></i>BMI </template>
        <span>{{ checkInfo.Member.Weight }}kg</span>/
        <span v-if="checkInfo.Member">{{ checkInfo.Member.Height }}cm</span>
        <el-tag type="danger" size="small" v-if="checkInfo.Member">{{
          checkInfo.Member.BMI.toFixed(2)
        }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> <i class="el-icon-star-off"></i>血压 </template>
        <div>
          <!-- 低<el-input-number
            v-if="!readOnly"
            size="mini"
            v-model="checkInfo.Member.BloodPressureMin"
            style="width: 120px"
          ></el-input-number> -->
          <span
            >{{ checkInfo.Member.BloodPressureMin }}-{{
              checkInfo.Member.BloodPressureMax
            }}</span
          >
          <!-- 高<el-input-number
            v-if="!readOnly"
            size="mini"
            v-model="checkInfo.Member.BloodPressureMax"
            style="width: 120px"
          ></el-input-number> -->
          <!-- <span>{{ checkInfo.Member.BloodPressureMax }}</span> -->
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-star-off"></i>体脂率
        </template>
        <div v-if="checkInfo.Member">{{ checkInfo.Member.FatRate }}</div>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="checkInfo.CheckSelfHeart && checkInfo.CheckSelfHeart.Key"
      >
        <template slot="label">
          <i class="el-icon-star-off"></i>心血管筛查</template
        >
        <div @click="showAnswerViewer('Heart')" style="cursor: pointer">
          <el-tag type="warning" size="small">{{
            checkInfo.CheckSelfHeart.AcceptableStatus
          }}</el-tag
          ><span style="color: #3c5be6">查看</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="checkInfo.CheckSelfVAS && checkInfo.CheckSelfVAS.Key"
      >
        <template slot="label"> <i class="el-icon-star-off"></i>VAS </template>
        <div @click="showAnswerViewer('VAS')" style="cursor: pointer">
          <el-tag type="warning" size="small"
            >{{ checkInfo.CheckSelfVAS.Count }}处</el-tag
          ><span style="color: #3c5be6">查看</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="checkInfo.CheckSelfIKDC && checkInfo.CheckSelfIKDC.Key"
      >
        <template slot="label"> <i class="el-icon-star-off"></i>IKDC</template>
        <div @click="showAnswerViewer('IKDC')" style="cursor: pointer">
          <el-tag type="warning" size="small">{{
            checkInfo.CheckSelfIKDC.IKDCScore
          }}</el-tag
          ><span style="color: #3c5be6">查看</span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> <i class="el-icon-star-off"></i>鞋码</template>
        <span>{{ checkInfo.Member.ShoesSize }}</span>
        <!-- <el-input-number
          v-if="!readOnly"
          size="mini"
          v-model="checkInfo.Member.ShoesSize"
          style="width: 120px"
        ></el-input-number> -->
        <el-button
          v-if="!readOnly"
          type="primary"
          style="margin-left: 50px"
          size="small"
          @click="showMemberEditor"
          >完善基本信息</el-button
        >
      </el-descriptions-item>
    </el-descriptions>
    <answerViewer ref="answerViewer"></answerViewer>
    <customerForm ref="customerForm" :checkKey="checkInfo.Key"></customerForm>
  </div>
</template>
<script>
import answerViewer from "@/views/customer/components/answerViewer.vue";
import customerForm from "@/views/customer/components/customerForm.vue";
export default {
  components: {
    answerViewer,
    customerForm,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default: {},
    },
    readOnly: {
      default: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.checkInfo = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      checkInfo: { Key: "", Member: {} },
    };
  },
  methods: {
    showAnswerViewer(tab) {
      let heartKey = this.checkInfo.CheckSelfHeart.Key;
      let ikdcKey = this.checkInfo.CheckSelfIKDC.Key;
      let vasKey = this.checkInfo.CheckSelfVAS.Key;
      this.$refs.answerViewer.showByKey(heartKey, ikdcKey, vasKey, tab);
    },
    showMemberEditor() {
      let json = JSON.parse(JSON.stringify(this.checkInfo.Member));
      this.$refs.customerForm.handleEdit(json);
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.customer {
  background-color: #fff;
}
</style>