<template>
  <div style="width: 1000px">
    <el-form
      :model="dataForm"
      ref="dataForm"
      :rules="dataFormRule"
      label-width="120px"
    >
      <el-card shadow="never">
        <div slot="header">
          <span style="font-size: 16px">运动习惯</span>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="参加高危运动" prop="JoinDangerExercise">
              <el-switch
                v-model="dataForm.JoinDangerExercise"
                :disabled="readOnly"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否每日久坐" prop="IsLongSit">
              <el-switch v-model="dataForm.IsLongSit" :disabled="readOnly" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="日常运动形式" prop="Exercise">
              <el-select
                v-model="dataForm.Exercise"
                size="small"
                multiple
                placeholder="日常运动形式"
                style="width: 100%"
                :disabled="readOnly"
              >
                <el-option
                  v-for="item in $config.exercise"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="每次运动量" prop="ExerciseAmount">
              <el-input-number
                v-model.number="dataForm.ExerciseAmount"
                size="small"
                :disabled="readOnly"
                placeholder="请输入运动量"
                :min="0"
              ></el-input-number
              >分钟
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="每周运动次数" prop="ExerciseFrequency">
              <el-input-number
                v-model.number="dataForm.ExerciseFrequency"
                size="small"
                :min="0"
                :disabled="readOnly"
                placeholder="请输入每周运动次数"
              ></el-input-number
              >次
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="參加跑步类型" prop="RunType">
              <enumSelector2
                ref="wineTypeSelector"
                :options="$config.runType"
                size="small"
                v-model="dataForm.RunType"
                :disabled="readOnly"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="跑步成绩" prop="RunScoreHour">
              <div style="display: flex; width: 100%">
                <el-input-number
                  v-model.number="dataForm.RunScoreHour"
                  :disabled="readOnly"
                  :min="0"
                  size="small"
                  class="input"
                  placeholder="请输入"
                ></el-input-number
                >小时
                <el-input-number
                  v-model.number="dataForm.RunScoreMin"
                  :disabled="readOnly"
                  :min="0"
                  size="small"
                  class="input"
                  placeholder="请输入"
                ></el-input-number>
                分钟
                <el-input-number
                  v-model.number="dataForm.RunScoreSec"
                  :disabled="readOnly"
                  :min="0"
                  size="small"
                  class="input"
                  placeholder="请输入"
                ></el-input-number>
                秒
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="never">
        <div slot="header">
          <span style="font-size: 16px">病史及用药</span>
        </div>
        <el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="既往病史" prop="DiseaseHistory">
                <el-select
                  v-model="dataForm.DiseaseHistory"
                  size="small"
                  multiple
                  placeholder="请选择既往病史"
                  style="width: 100%"
                  :disabled="readOnly"
                >
                  <el-option
                    v-for="item in $config.diseases"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="遗传病史" prop="Inheritance">
                <el-select
                  v-model="dataForm.Inheritance"
                  size="small"
                  multiple
                  placeholder="请选择遗传病史"
                  style="width: 100%"
                  :disabled="readOnly"
                >
                  <el-option
                    v-for="item in $config.inheritances"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="用药史" prop="MedicalHistory">
                <el-select
                  v-model="dataForm.MedicalHistory"
                  size="small"
                  multiple
                  placeholder="请选择用药史"
                  style="width: 100%"
                  :disabled="readOnly"
                >
                  <el-option
                    v-for="item in $config.medicals"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="24">
            <el-form-item label="近期状态" prop="HealStatus">
              <el-select
                v-model="dataForm.HealthStatus"
                size="small"
                multiple
                placeholder="请选择近期状态"
                class="input"
                style="width: 100%"
                :disabled="readOnly"
              >
                <el-option
                  v-for="item in $config.healths"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="never">
        <div slot="header">
          <span style="font-size: 16px">生活习惯</span>
          <div style="float: right; display: flex; align-items: center">
            <el-switch :disabled="readOnly"
              v-model="dataForm.IsSmoke"
              active-color="red"
              @change="isSmokeChanged"
              active-text="吸烟"
              inactive-text="不吸烟"
            ></el-switch>
            <el-switch
              style="margin-left: 30px"
              v-model="dataForm.IsDrinkWine"
              @change="isDrinkChanged"
              :disabled="readOnly"
              active-color="red"
              active-text="饮酒"
              inactive-text="不饮酒"
            ></el-switch>
          </div>
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item label="吸烟史" prop="SmokeLength">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model="dataForm.SmokeLength"
                  :precision="1"
                  size="small"
                  :disabled="readOnly"
                  :min="0"
                  placeholder="请输入吸烟史"
                  style="width: 100%"
                ></el-input-number
                >年
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="每天平均" prop="SmokeDayCount">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model="dataForm.SmokeDayCount"
                  size="small"
                  :precision="1"
                  :min="0"
                  :disabled="readOnly"
                  style="width: 100%"
                ></el-input-number
                >支
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="已戒" prop="SmokeStopLength">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model="dataForm.SmokeStopLength"
                  :precision="1"
                  size="small"
                  :min="0"
                  :disabled="readOnly"
                  style="width: 100%"
                ></el-input-number>
                年
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="饮酒类型" prop="DrinkWineType">
              <enumSelector2
                ref="wineTypeSelector"
                :options="$config.wineType"
                size="mini"
                v-model="dataForm.DrinkWineType"
                :disabled="readOnly"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="饮酒" prop="DrinkWine">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model="dataForm.DrinkWine"
                  size="small"
                  :precision="1"
                  :min="0"
                  class="input"
                  placeholder="请输入饮酒史"
                  :disabled="readOnly"
                  style="width: 100%"
                ></el-input-number
                >年
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="已戒" prop="DrinkStopLength">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model.number="dataForm.DrinkStopLength"
                  size="small"
                  :precision="1"
                  :min="0"
                  :disabled="readOnly"
                  placeholder="已戒几年"
                  style="width: 100%"
                ></el-input-number
                >年
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="每周饮酒" prop="DrinkWeekCount">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model="dataForm.DrinkWeekCount"
                  size="small"
                  :min="0"
                  :precision="1"
                  :disabled="readOnly"
                  style="width: 100%"
                ></el-input-number
                >次
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="每次饮酒" prop="DrinkWineAmount">
              <div style="display: flex; align-items: center">
                <el-input-number
                  v-model="dataForm.DrinkWineAmount"
                  size="small"
                  :min="0"
                  :precision="1"
                  :disabled="readOnly"
                  style="width: 100%"
                ></el-input-number>
                两
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card shadow="never" v-if="!readOnly">
      <div class="footer">
        <el-button
          class="submit-btn"
          v-if="step"
          type="warning"
          @click="handlePreStep"
        >
          上一步
        </el-button>
        <el-button class="submit-btn" type="success" @click="handleSubmit">{{
          step ? "下一步" : "保存"
        }}</el-button>
      </div>
    </el-card>
  </div>
</template>
    <script>
import { save, getByKey } from "@/api/check/checkSelf.js";
import enumSelector2 from "@/components/enumSelector2";
import customerSelector from "@/components/customerSelector";
export default {
  components: {
    enumSelector2,
    customerSelector,
  },
  props: {
    memberKey: {
      default: "",
    },
    keyValue: {
      default: "",
    },
    readOnly: {
      default: false,
    },
    step: {
      default: false,
    },
  },
  watch: {
    keyValue(val) {
      this.getHeart();
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      dataForm: {
        IsLongSit: false,
        HealthStatus: "",
        MedicalHistory: "",
        DiseaseHistory: "",
        Inheritance: "",
        BloodPressureMin: 0,
        BloodPressureMax: 0,

        SmokeLength: 0,
        SmokeDayCount: 0,
        SmokeStopLength: 0,

        DrinkWine: 0,
        DrinkWineType: 0,
        DrinkWeekCount: 0,
        DrinkWineAmount: 0,
        DrinkStopLength: 0,

        Exercise: "",
        ExerciseAmount: 0,
        ExerciseFrequency: 0,
        JoinDangerExercise: false,
        RunType: 0,
        RunScoreHour: 0,
        RunScoreMin: 0,
        RunScoreSce:0,
        RunScore: "",
      },
      dataFormRule: {
        SmokeLength: [
          { required: true, message: "请输入吸烟史", trigger: "change" },
        ],
        DrinkWine: [
          { required: true, message: "请输入饮酒史", trigger: "change" },
        ],
        BloodPressureMin: [
          { required: true, message: "请输入低血压值", trigger: "change" },
        ],
        BloodPressureMax: [
          { required: true, message: "请输入高血压值", trigger: "change" },
        ],
        ExerciseAmount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        ExerciseFrequency: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        RunType: [{ required: true, message: "请输入", trigger: "change" }],
        RunScoreHour: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        RunScoreMin: [{ required: true, message: "请输入", trigger: "change" }],
        DrinkWineType: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        DrinkWeekCount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        DrinkWineAmount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        DrinkStopLength: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        SmokeLength: [{ required: true, message: "请输入", trigger: "change" }],
        SmokeDayCount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        SmokeStopLength: [
          { required: true, message: "请输入", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    isSmokeChanged() {
      this.dataForm.SmokeDayCount = 0;
      this.dataForm.SmokeLength = 0;
      this.dataForm.SmokeStopLength = 0;
    },
    isDrinkChanged() {
      this.dataForm.DrinkWeekCount = 0;
      this.dataForm.DrinkStopLength = 0;
      this.dataForm.DrinkWineAmount = 0;
      this.dataForm.DrinkWineType = 0;
      this.dataForm.DrinkWine = 0;
    },
    getHeart() {
      if (this.keyValue) {
        let param = { key: this.keyValue, type: "Heart" };
        console.log(param);
        getByKey(param).then((res) => {
          this.dataForm = res;
          if (res.DiseaseHistory)
            this.dataForm.DiseaseHistory = res.DiseaseHistory.split(",");
          else this.dataForm.DiseaseHistory = [];
          if (res.MedicalHistory)
            this.dataForm.MedicalHistory = res.MedicalHistory.split(",");
          else this.dataForm.MedicalHistory = [];
          if (res.Inheritance)
            this.dataForm.Inheritance = res.Inheritance.split(",");
          else this.dataForm.Inheritance = [];
          if (res.HealthStatus)
            this.dataForm.HealthStatus = res.HealthStatus.split(",");
          else this.dataForm.HealthStatus = [];
          if (res.Exercise) this.dataForm.Exercise = res.Exercise.split(",");
          else this.dataForm.Exercise = [];
        });
      } else {
        this.handleCreate();
      }
    },
    handleCreate() {
      this.dataForm = {
        Member: { Key: this.memberKey },
        IsLongSit: false,
        HealthStatus: "",
        MedicalHistory: "",
        DiseaseHistory: "",
        Inheritance: "",
        IsSmoke: false,
        SmokeLength: 0,
        SmokeDayCount: 0,
        SmokeStopLength: 0,
        IsDrinkWine: false,
        DrinkWine: 0,
        DrinkWineType: 0,
        DrinkWeekCount: 0,
        DrinkWineAmount: 0,
        DrinkStopLength: 0,
        Exercise: "",
        ExerciseAmount: 0,
        ExerciseFrequency: 0,
        JoinDangerExercise: false,
        RunType: 0,
        RunScoreHour: 0,
        RunScoreMin: 0,
        RunScore: "",
      };
    },
    handleEdit(row) {
      this.dataForm = row;
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate();
      });
    },
    handleSubmit() {
      let data = JSON.parse(JSON.stringify(this.dataForm));
      if (data.MedicalHistory) {
        data.MedicalHistory = data.MedicalHistory.join(",");
      }
      if (data.HealthStatus) {
        data.HealthStatus = data.HealthStatus.join(",");
      }
      if (data.DiseaseHistory) {
        data.DiseaseHistory = data.DiseaseHistory.join(",");
      }
      if (data.Inheritance) {
        data.Inheritance = data.Inheritance.join(",");
      }
      if (data.Exercise) {
        data.Exercise = data.Exercise.join(",");
      }
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.step) {
            this.$emit("next", data);
          } else {
            save(data).then((res) => {
              if (res) {
                this.msg.successMsg("操作成功");
                this.$emit("update", data);
              }
            });
          }
        } else {
          setTimeout(() => {
            this.$refs.dataForm.clearValidate();
          }, 3000);
        }
      });
    },
    handlePreStep() {
      this.$emit("prev", this.dataForm);
    },
  },
  mounted() {
    this.getHeart();
  },
};
</script>
    <style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px;
}
/deep/.el-form-item__error {
  padding-top: 0;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.footer {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
}
</style>