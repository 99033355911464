var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "1000px" } },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataFormRule,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("span", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v("客户基本信息")
                ]),
                _vm.step
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          float: "right"
                        }
                      },
                      [
                        _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("选择已有客户：")
                        ]),
                        _c("customerSelector", {
                          ref: "customerSelector",
                          on: { change: _vm.customerChanged }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "编号", prop: "Code" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              disabled: true,
                              placeholder: "系统自动产生"
                            },
                            model: {
                              value: _vm.dataForm.Code,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "Code", $$v)
                              },
                              expression: "dataForm.Code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "RealName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.readOnly,
                              size: "small",
                              placeholder: "请输入真实姓名"
                            },
                            model: {
                              value: _vm.dataForm.RealName,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "RealName", $$v)
                              },
                              expression: "dataForm.RealName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别", prop: "Sex" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                placeholder: "请输入性别",
                                disabled: _vm.readOnly
                              },
                              model: {
                                value: _vm.dataForm.Sex,
                                callback: function($$v) {
                                  _vm.$set(_vm.dataForm, "Sex", $$v)
                                },
                                expression: "dataForm.Sex"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "男", value: "男" }
                              }),
                              _c("el-option", {
                                attrs: { label: "女", value: "女" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生日", prop: "Birthday" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              disabled: _vm.readOnly,
                              type: "date",
                              size: "small",
                              placeholder: "请选择出生日期"
                            },
                            model: {
                              value: _vm.dataForm.Birthday,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "Birthday", $$v)
                              },
                              expression: "dataForm.Birthday"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身高", prop: "Height" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 1,
                              size: "small",
                              min: 0,
                              placeholder: "请输入身高",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value: _vm.dataForm.Height,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "Height", $$v)
                              },
                              expression: "dataForm.Height"
                            }
                          }),
                          _vm._v(" cm ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "体重", prop: "Weight" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              size: "small",
                              min: 0,
                              placeholder: "请输入体重",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value: _vm.dataForm.Weight,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "Weight", $$v)
                              },
                              expression: "dataForm.Weight"
                            }
                          }),
                          _vm._v(" kg ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "BMI", prop: "BMI" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.dataForm.BMI.toFixed(2)) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "鞋码", prop: "ShoesSize" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              size: "small",
                              min: 0,
                              placeholder: "请输入鞋码",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value: _vm.dataForm.ShoesSize,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "ShoesSize", $$v)
                              },
                              expression: "dataForm.ShoesSize"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "Mobile" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.readOnly,
                              size: "small",
                              placeholder: "请输入手机号"
                            },
                            model: {
                              value: _vm.dataForm.Mobile,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "Mobile", $$v)
                              },
                              expression: "dataForm.Mobile"
                            }
                          }),
                          _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("手机号是关联微信小程序标识，请准确输入。")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份证号", prop: "IdCode" } },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: {
                              disabled: _vm.readOnly,
                              size: "small",
                              placeholder: "请输入身份证号"
                            },
                            model: {
                              value: _vm.dataForm.IdCode,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "IdCode", $$v)
                              },
                              expression: "dataForm.IdCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "低血压:", prop: "BloodPressureMin" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.readOnly,
                                  size: "small",
                                  precision: 1,
                                  min: 0,
                                  placeholder: "低血压值"
                                },
                                model: {
                                  value: _vm.dataForm.BloodPressureMin,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "BloodPressureMin",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.BloodPressureMin"
                                }
                              }),
                              _vm._v(" mmHg ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "高血压:", prop: "BloodPressureMax" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.readOnly,
                                  size: "small",
                                  precision: 1,
                                  min: 0,
                                  placeholder: "高血压值"
                                },
                                model: {
                                  value: _vm.dataForm.BloodPressureMax,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "BloodPressureMax",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.BloodPressureMax"
                                }
                              }),
                              _vm._v(" mmHg ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.readOnly
        ? _c("el-card", { attrs: { shadow: "never" } }, [
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "submit-btn",
                    attrs: { type: "success" },
                    on: { click: _vm.submit }
                  },
                  [_vm._v(_vm._s(_vm.step ? "下一步" : "保存"))]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }