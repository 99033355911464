var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("attchmentEditor", {
        attrs: {
          attachments: _vm.kneeHurt.Attachments,
          bussiness: "KneeHurt",
          readOnly: _vm.disable
        }
      }),
      _c(
        "el-form",
        {
          ref: "KneeHurt",
          attrs: {
            model: _vm.kneeHurt,
            rules: _vm.kneeHurtRules,
            "label-width": "60px",
            disabled: _vm.disable
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("主动直腿上抬")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftZdztstOption" } },
                    [
                      _c("enumSelector", {
                        attrs: { options: _vm.$config.kneeHurtZhiTuiShangTai },
                        model: {
                          value: _vm.kneeHurt.LeftZdztstOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftZdztstOption", $$v)
                          },
                          expression: "kneeHurt.LeftZdztstOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "LeftZdztst" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calZdztstOption },
                        model: {
                          value: _vm.kneeHurt.LeftZdztst,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftZdztst", $$v)
                          },
                          expression: "kneeHurt.LeftZdztst"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightZdztstOption" } },
                    [
                      _c("enumSelector", {
                        attrs: { options: _vm.$config.kneeHurtZhiTuiShangTai },
                        model: {
                          value: _vm.kneeHurt.RightZdztstOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightZdztstOption", $$v)
                          },
                          expression: "kneeHurt.RightZdztstOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "RightZdztst" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calZdztstOption },
                        model: {
                          value: _vm.kneeHurt.RightZdztst,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightZdztst", $$v)
                          },
                          expression: "kneeHurt.RightZdztst"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalZdztst" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalZdztst))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("俯卧髋关节主动伸展")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftFwzdskOption" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          options: _vm.$config.kneeHurtKuanGuanJieShenZhan
                        },
                        model: {
                          value: _vm.kneeHurt.LeftFwzdskOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftFwzdskOption", $$v)
                          },
                          expression: "kneeHurt.LeftFwzdskOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "LeftFwzdsk" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calFwzdskOption },
                        model: {
                          value: _vm.kneeHurt.LeftFwzdsk,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftFwzdsk", $$v)
                          },
                          expression: "kneeHurt.LeftFwzdsk"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightFwzdskOption" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          options: _vm.$config.kneeHurtKuanGuanJieShenZhan
                        },
                        model: {
                          value: _vm.kneeHurt.RightFwzdskOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightFwzdskOption", $$v)
                          },
                          expression: "kneeHurt.RightFwzdskOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "RightFwzdsk" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calFwzdskOption },
                        model: {
                          value: _vm.kneeHurt.RightFwzdsk,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightFwzdsk", $$v)
                          },
                          expression: "kneeHurt.RightFwzdsk"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalFwzdsk" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalFwzdsk))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("俯卧膝关节主动屈曲")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftFwxgjzdqqOption" } },
                    [
                      _c("enumSelector", {
                        attrs: { options: _vm.$config.kneeHurtXiGuanJieQuShen },
                        model: {
                          value: _vm.kneeHurt.LeftFwxgjzdqqOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftFwxgjzdqqOption", $$v)
                          },
                          expression: "kneeHurt.LeftFwxgjzdqqOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "LeftFwxgjzdqq" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calFwxgjzdqqOption },
                        model: {
                          value: _vm.kneeHurt.LeftFwxgjzdqq,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftFwxgjzdqq", $$v)
                          },
                          expression: "kneeHurt.LeftFwxgjzdqq"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightFwxgjzdqqOption" } },
                    [
                      _c("enumSelector", {
                        attrs: { options: _vm.$config.kneeHurtXiGuanJieQuShen },
                        model: {
                          value: _vm.kneeHurt.RightFwxgjzdqqOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightFwxgjzdqqOption", $$v)
                          },
                          expression: "kneeHurt.RightFwxgjzdqqOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "RightFwxgjzdqq" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calFwxgjzdqqOption },
                        model: {
                          value: _vm.kneeHurt.RightFwxgjzdqq,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightFwxgjzdqq", $$v)
                          },
                          expression: "kneeHurt.RightFwxgjzdqq"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalFwxgjzdqq" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalFwxgjzdqq))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("踝关节灵活性")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftHgjlhxOption" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          options: _vm.$config.kneeHurtHuaiGuanJieLingHuo
                        },
                        model: {
                          value: _vm.kneeHurt.LeftHgjlhxOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftHgjlhxOption", $$v)
                          },
                          expression: "kneeHurt.LeftHgjlhxOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "LeftHgjlhx" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calHgjlhxOption },
                        model: {
                          value: _vm.kneeHurt.LeftHgjlhx,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftHgjlhx", $$v)
                          },
                          expression: "kneeHurt.LeftHgjlhx"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightHgjlhxOption" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          options: _vm.$config.kneeHurtHuaiGuanJieLingHuo
                        },
                        model: {
                          value: _vm.kneeHurt.RightHgjlhxOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightHgjlhxOption", $$v)
                          },
                          expression: "kneeHurt.RightHgjlhxOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "RightHgjlhx" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calHgjlhxOption },
                        model: {
                          value: _vm.kneeHurt.RightHgjlhx,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightHgjlhx", $$v)
                          },
                          expression: "kneeHurt.RightHgjlhx"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalHgjlhx" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalHgjlhx))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("俯卧屈膝髋关节内旋")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftFwqxgjnxOption" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          options: _vm.$config.kneeHurtKuanGuanJieNeiXuan
                        },
                        model: {
                          value: _vm.kneeHurt.LeftFwqxgjnxOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftFwqxgjnxOption", $$v)
                          },
                          expression: "kneeHurt.LeftFwqxgjnxOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "LeftFwqxgjnx" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calFwqxgjnxOption },
                        model: {
                          value: _vm.kneeHurt.LeftFwqxgjnx,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftFwqxgjnx", $$v)
                          },
                          expression: "kneeHurt.LeftFwqxgjnx"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightFwqxgjnxOption" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          options: _vm.$config.kneeHurtKuanGuanJieNeiXuan
                        },
                        model: {
                          value: _vm.kneeHurt.RightFwqxgjnxOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightFwqxgjnxOption", $$v)
                          },
                          expression: "kneeHurt.RightFwqxgjnxOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "RightFwqxgjnx" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calFwqxgjnxOption },
                        model: {
                          value: _vm.kneeHurt.RightFwqxgjnx,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightFwqxgjnx", $$v)
                          },
                          expression: "kneeHurt.RightFwqxgjnx"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalFwqxgjnx" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalFwqxgjnx))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("动作控制")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftBydtzlwdxOption" } },
                    [
                      _c("enumSelector", {
                        attrs: { options: _vm.$config.kneeHurtDanTuiZhanLi },
                        model: {
                          value: _vm.kneeHurt.LeftBydtzlwdxOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftBydtzlwdxOption", $$v)
                          },
                          expression: "kneeHurt.LeftBydtzlwdxOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "LeftBydtzlwdx" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calBydtzlwdxOption },
                        model: {
                          value: _vm.kneeHurt.LeftBydtzlwdx,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftBydtzlwdx", $$v)
                          },
                          expression: "kneeHurt.LeftBydtzlwdx"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightBydtzlwdxOption" } },
                    [
                      _c("enumSelector", {
                        attrs: { options: _vm.$config.kneeHurtDanTuiZhanLi },
                        model: {
                          value: _vm.kneeHurt.RightBydtzlwdxOption,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightBydtzlwdxOption", $$v)
                          },
                          expression: "kneeHurt.RightBydtzlwdxOption"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "值", prop: "RightBydtzlwdx" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small", precision: 1 },
                        on: { change: _vm.calBydtzlwdxOption },
                        model: {
                          value: _vm.kneeHurt.RightBydtzlwdx,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightBydtzlwdx", $$v)
                          },
                          expression: "kneeHurt.RightBydtzlwdx"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalBydtzlwdx" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalBydtzlwdx))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v(" 单腿下蹲 ")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左", prop: "LeftGjbOptions" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          multiple: true,
                          multipleValueString: true,
                          options: _vm.$config.kneeHurtGongJianBu
                        },
                        model: {
                          value: _vm.kneeHurt.LeftGjbOptions,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "LeftGjbOptions", $$v)
                          },
                          expression: "kneeHurt.LeftGjbOptions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分", prop: "LeftGjb" } },
                    [
                      _c("el-tag", { attrs: { type: "info" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.LeftGjb))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右", prop: "RightGjbOptions" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          multiple: true,
                          multipleValueString: true,
                          options: _vm.$config.kneeHurtGongJianBu
                        },
                        model: {
                          value: _vm.kneeHurt.RightGjbOptions,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "RightGjbOptions", $$v)
                          },
                          expression: "kneeHurt.RightGjbOptions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分", prop: "RightGjb" } },
                    [
                      _c("el-tag", { attrs: { type: "info" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.RightGjb))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalGjb" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalGjb))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("髋关节铰链")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "测评", prop: "TotalKgjjlOptions" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          multiple: true,
                          multipleValueString: true,
                          options: _vm.$config.kneeHurtKuanGuanJieJiaoLian
                        },
                        model: {
                          value: _vm.kneeHurt.TotalKgjjlOptions,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "TotalKgjjlOptions", $$v)
                          },
                          expression: "kneeHurt.TotalKgjjlOptions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalKgjjl" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalKgjjl))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("深蹲")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "测评", prop: "TotalSdOptions" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          multiple: true,
                          multipleValueString: true,
                          options: _vm.$config.kneeHurtShenDun
                        },
                        model: {
                          value: _vm.kneeHurt.TotalSdOptions,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "TotalSdOptions", $$v)
                          },
                          expression: "kneeHurt.TotalSdOptions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalSd" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalSd))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("落地缓冲")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "测评", prop: "TotalLdhcOptions" } },
                    [
                      _c("enumSelector", {
                        attrs: {
                          multiple: true,
                          multipleValueString: true,
                          options: _vm.$config.kneeHurtLuoDiHuanChong
                        },
                        model: {
                          value: _vm.kneeHurt.TotalLdhcOptions,
                          callback: function($$v) {
                            _vm.$set(_vm.kneeHurt, "TotalLdhcOptions", $$v)
                          },
                          expression: "kneeHurt.TotalLdhcOptions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评分", prop: "TotalLdhc" } },
                    [
                      _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v(_vm._s(_vm.kneeHurt.TotalLdhc))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("评估结果")])]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120",
                                label: "关节活动度",
                                prop: "Score1"
                              }
                            },
                            [_c("el-tag", [_vm._v(_vm._s(_vm.score1))])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120",
                                label: "功能动作",
                                prop: "Score2"
                              }
                            },
                            [_c("el-tag", [_vm._v(_vm._s(_vm.score2))])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "120",
                                label: "总得分",
                                prop: "Score"
                              }
                            },
                            [_c("el-tag", [_vm._v(_vm._s(_vm.score))])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "200",
                                label: "关节活动度评估结果",
                                prop: "Score1"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "70%" },
                                attrs: { type: "textarea", rows: "5" },
                                model: {
                                  value: _vm.kneeHurt.Summary1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.kneeHurt, "Summary1", $$v)
                                  },
                                  expression: "kneeHurt.Summary1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "200",
                                label: "功能动作评估结果",
                                prop: "Score2"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "70%" },
                                attrs: { type: "textarea", rows: "5" },
                                model: {
                                  value: _vm.kneeHurt.Summary2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.kneeHurt, "Summary2", $$v)
                                  },
                                  expression: "kneeHurt.Summary2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }