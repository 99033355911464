var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-container" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical",
          attrs: {
            mode: "vertical",
            "default-active": _vm.$route.path,
            collapse: _vm.isCollapse,
            "background-color": "rgb(48, 65, 86)",
            "text-color": "#fff",
            "active-text-color": "#ee7031",
            "collapse-transition": false
          }
        },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _vm.isCollapse && _vm.showLogo
                ? _c("transition", { attrs: { name: "el-fade-in" } }, [
                    _c("img", {
                      attrs: {
                        src: "/images/logo2.png",
                        height: "40",
                        width: "40"
                      }
                    })
                  ])
                : _c("transition", { attrs: { name: "el-fade-in" } }, [
                    !_vm.isCollapse && _vm.showLogo
                      ? _c("div", { staticClass: "logo2" }, [
                          _c("img", {
                            attrs: {
                              src: "/images/logo2.png",
                              height: "30",
                              width: "30"
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "20px"
                              }
                            },
                            [_vm._v("心体汇")]
                          )
                        ])
                      : _vm._e()
                  ])
            ],
            1
          ),
          _c("sidebar-item", { attrs: { routes: _vm.routers } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }