<template>
  <div class="component-container">
    <el-tabs v-model="checkType" tab-position="left" @tab-click="activeChange">
      <el-tab-pane name="HeartEcg" v-if="(check.CheckTypes & 128) == 128 && heartEcg">
        <span slot="label"
          ><i
            :class="
              heartEcg.Key ? 'el-icon-check done' : 'el-icon-time wait'
            "
            >心电图运动负荷试验({{
              heartEcg.Result ? heartEcg.Result : "-"
            }})</i
          >
        </span>
        <checkEditorHeartEcg
          ref="HeartEcg"
          @passValidate="doSave"
          :checkKey="checkKey"
          v-model="heartEcg"
        />
      </el-tab-pane>
      <el-tab-pane name="BodyComposition" v-if="(check.CheckTypes & 2) == 2 && bodyComposition">
        <span slot="label"
          ><i
            :class="
              bodyComposition.Key ? 'el-icon-check done' : 'el-icon-time wait'
            "
            >人体成分评估({{
              bodyComposition.Score ? bodyComposition.Score : "-"
            }})</i
          >
        </span>
        <checkEditorBodyComposition
          ref="BodyComposition"
          @passValidate="doSave"
          :checkKey="checkKey"
          v-model="bodyComposition"
        />
      </el-tab-pane>
      <el-tab-pane name="FootPressure" v-if="(check.CheckTypes & 4) == 4 && footPressure">
        <span slot="label"
          ><i
            :class="
              footPressure.Key ? 'el-icon-check done' : 'el-icon-time wait'
            "
            >足底健康评估({{
              footPressure.Score ? footPressure.Score : "-"
            }})</i
          >
        </span>
        <checkEditorFootPressure
          ref="FootPressure"
          :checkKey="checkKey"
          v-model="footPressure"
          @passValidate="doSave"
        />
      </el-tab-pane>
      <el-tab-pane name="KneeHurt" v-if="(check.CheckTypes & 8) == 8 && kneeHurt">
        <span slot="label"
          ><i :class="kneeHurt.Key ? 'el-icon-check done' : 'el-icon-time wait'"
            >关节功能评估({{ kneeHurt.Score ? kneeHurt.Score : "-" }})</i
          >
        </span>
        <checkEditorKneeHurt
          ref="KneeHurt"
          :checkKey="checkKey"
          v-model="kneeHurt"
          @passValidate="doSave"
        />
      </el-tab-pane>
      <el-tab-pane name="RunPosture" v-if="(check.CheckTypes & 16) == 16 && runPosture">
        <span slot="label"
          ><i
            :class="runPosture.Key ? 'el-icon-check done' : 'el-icon-time wait'"
            >三维动作捕捉({{ runPosture.Score ? runPosture.Score : "-" }})</i
          >
        </span>
        <checkEditorRunPosture
          ref="RunPosture"
          :checkKey="checkKey"
          v-model="runPosture"
          @passValidate="doSave"
        />
      </el-tab-pane>
      <el-tab-pane name="Breath" v-if="(check.CheckTypes & 32) == 32 && breath">
        <span slot="label"
          ><i :class="breath.Key ? 'el-icon-check done' : 'el-icon-time wait'"
            >呼吸能力评估({{ breath.Score ? breath.Score : "-" }})</i
          >
        </span>
        <checkEditorBreath
          ref="Breath"
          v-model="breath"
          :checkKey="checkKey"
          @passValidate="doSave"
        />
      </el-tab-pane>
      <el-tab-pane name="HeartLung" v-if="(check.CheckTypes & 64) == 64 && heartLung">
        <span slot="label"
          ><i
            :class="heartLung.Key ? 'el-icon-check done' : 'el-icon-time wait'"
            >心肺耐力评估({{ heartLung.Score ? heartLung.Score : "-" }})</i
          >
        </span>
        <checkEditorHeartLung
          ref="HeartLung"
          v-model="heartLung"
          :checkKey="checkKey"
          @passValidate="doSave"
        />
      </el-tab-pane>
    </el-tabs>
    <div class="btns">
      <el-button type="warning" @click="saveCheck">保存测评结果</el-button>
      <el-button type="success" @click="completedCheck">结束客户测评</el-button>
    </div>
  </div>
</template>
<script>
import { getLocalCheck, saveCheckItem } from "@/api/check/check.js";
import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
import checkEditorKneeHurt from "./checkEditorKneeHurt.vue";
import checkEditorBodyComposition from "./checkEditorBodyComposition.vue";
import checkEditorHeartLung from "./checkEditorHeartLung.vue";
import checkEditorFootPressure from "./checkEditorFootPressure.vue";
import checkEditorRunPosture from "./checkEditorRunPosture.vue";
import checkEditorBreath from "./checkEditorBreath.vue";
import checkEditorHeartEcg from "./checkEditorHeartEcg.vue";
export default {
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
  },
  watch: {
    checkKey: {
      
      handler (v) {
        debugger;
        this.getData();
      },
      deep: true,
      immediate: true,
    }
  },
  components: {
    attchmentEditor,
    enumSelector,
    checkEditorBodyComposition,
    checkEditorKneeHurt,
    checkEditorFootPressure,
    checkEditorHeartLung,
    checkEditorRunPosture,
    checkEditorBreath,
    checkEditorHeartEcg
  },
  data() {
    return {
      checkType: "BodyComposition",
      //check
      check: {
        CheckTypes:0,
      },
      heartEcg:{},
      //体成分
      bodyComposition: {},
      //足底压力
      footPressure: {},
      //膝关节损伤
      kneeHurt: {},
      //步态分析
      runPosture: {},
      //心肺实验
      heartLung: {},
      //呼吸能力
      breath: {},
    };
  },
  computed: {
    checkName() {
      if (this.checkType) return this.format.checkTypeFormat(this.checkType);
      return "";
    },
  },

  methods: {
    //获取
    getData() {
      if (!this.checkKey) return;
      let param = {
        checkKey: this.checkKey,
      };
      getLocalCheck(param).then((res) => {
        this.check =res.check;
        this.heartEcg=res.heartEcg;
        this.bodyComposition = res.bodyComposition;
        this.footPressure = res.footPressure;
        if(res.kneeHurt)
        {
          this.kneeHurtToArray(res.kneeHurt);
          this.kneeHurt = res.kneeHurt;
        }
        this.heartLung = res.heartLung;
        this.runPosture = res.runPosture;
        this.breath = res.breath;
      });
    },
    kneeHurtToArray(obj) {
      if (obj.TotalLdhcOptions) {
        let array = obj.TotalLdhcOptions.split(",");
        obj.TotalLdhcOptions = [];
        array.forEach((element) => {
          obj.TotalLdhcOptions.push(Number(element));
        });
      }
      if (obj.TotalKgjjlOptions) {
        let array = obj.TotalKgjjlOptions.split(",");
        obj.TotalKgjjlOptions = [];
        array.forEach((element) => {
          obj.TotalKgjjlOptions.push(Number(element));
        });
      }
      if (obj.TotalSdOptions) {
        let array = obj.TotalSdOptions.split(",");
        obj.TotalSdOptions = [];
        array.forEach((element) => {
          obj.TotalSdOptions.push(Number(element));
        });
      }
      if (obj.LeftGjbOptions) {
        let array = obj.LeftGjbOptions.split(",");
        obj.LeftGjbOptions = [];
        array.forEach((element) => {
          obj.LeftGjbOptions.push(Number(element));
        });
      }
      if (obj.RightGjbOptions) {
        let array = obj.RightGjbOptions.split(",");
        obj.RightGjbOptions = [];
        array.forEach((element) => {
          obj.RightGjbOptions.push(Number(element));
        });
      }
    },
    //保存
    saveCheck() {
      let msg = "请确认是否保存【" + this.checkName + "】测评数据？";
      let that = this;
      this.$confirm(msg, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that.$refs[that.checkType].doValidate();
      });
    },
    completedCheck() {
      let msg = "以下项目：";
      let findUnSave = false;
     
    
      if ((this.check.CheckTypes & 2)==2 && this.bodyComposition && !this.bodyComposition.Key) {
        msg += "【人体成分评估】";
        findUnSave = true;
      }
      if ((this.check.CheckTypes & 4)==4 && this.footPressure && !this.footPressure.Key) {
        msg += "【足底健康评估】";
        findUnSave = true;
      }
      if ((this.check.CheckTypes & 8)==8 && this.kneeHurt && !this.kneeHurt.Key) {
        msg += "【关节功能评估】";
        findUnSave = true;
      }
      if ((this.check.CheckTypes & 16)==16 && this.runPosture && !this.runPosture.Key) {
        msg += "【三维动作捕捉】";
        findUnSave = true;
      }
      if ((this.check.CheckTypes & 32)==32 && this.breath && !this.breath.Key) {
        msg += "【呼吸能力评估】";
        findUnSave = true;
      }
      if ((this.check.CheckTypes & 64)==64 && this.heartLung && !this.heartLung.Key) {
        msg += "【心肺耐力评估】";
        findUnSave = true;
      }
      if ((this.check.CheckTypes & 128)==128 && this.heartEcg && !this.heartEcg.Key) {
        msg += "【心电图运动负荷试验】";
        findUnSave = true;
      }
      if(findUnSave){
        msg += "无测评信息，无法结束测评！";
        this.msg.errorMsg(msg)
        return;
      }
      if (findUnSave) {
        msg += "无测评信息，确定忽略未测评内容并结束测评吗？";
      } else {
        msg = "确定结束测评吗？";
      }
      let that = this;
      this.$confirm(msg, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("completed");
      });
    },
    doSave(obj) {
      let saveObj = JSON.parse(JSON.stringify(obj));
      let param = {
        checkKey: this.checkKey,
        checkTypeObj: saveObj,
        checkType: this.checkType,
      };
      if (this.checkType == "KneeHurt") {
        if (obj.TotalLdhcOptions && obj.TotalLdhcOptions.length > 0)
          saveObj.TotalLdhcOptions = obj.TotalLdhcOptions.join(",");
        else saveObj.TotalLdhcOptions = "";

        if (obj.TotalKgjjlOptions && obj.TotalKgjjlOptions.length > 0)
          saveObj.TotalKgjjlOptions = obj.TotalKgjjlOptions.join(",");
        else saveObj.TotalKgjjlOptions = "";

        if (obj.TotalSdOptions && obj.TotalSdOptions.length > 0)
          saveObj.TotalSdOptions = obj.TotalSdOptions.join(",");
        else saveObj.TotalSdOptions = "";

        if (obj.LeftGjbOptions && obj.LeftGjbOptions.length > 0)
          saveObj.LeftGjbOptions = obj.LeftGjbOptions.join(",");
        else saveObj.LeftGjbOptions = "";

        if (obj.RightGjbOptions && obj.RightGjbOptions.length > 0)
          saveObj.RightGjbOptions = obj.RightGjbOptions.join(",");
        else saveObj.RightGjbOptions = "";
      }
      saveCheckItem(param)
        .then((res) => {
          if (this.checkType == "HeartEcg") {
            this.heartEcg = res;
            this.$emit("update");
          }
          if (this.checkType == "BodyComposition") {
            this.bodyComposition = res;
            this.$emit("update");
          }
          if (this.checkType == "FootPressure") {
            this.footPressure = res;
          }
          if (this.checkType == "KneeHurt") {
            this.kneeHurtToArray(res);
            this.kneeHurt = res;
          }
          if (this.checkType == "HeartLung") {
            this.heartLung = res;
          }
          if (this.checkType == "RunPosture") {
            this.runPosture = res;
          }
          if (this.checkType == "Breath") {
            this.breath = res;
          }
          this.msg.successMsg("保存成功");
        })
        .catch((ex) => {
          this.msg.errorMsg(ex);
        });
    },
    activeChange() {},
  },

  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-collapse-item__content {
  padding: 0 20px 20px 20px;
}
/deep/.el-collapse-item__header {
  padding-left: 20px;
}
/deep/.el-tabs {
  height: 100%;
}
/deep/.el-card__header {
  font-size: 16px;
  font-weight: bold;
  color: #60430d;
}
/deep/.el-tabs__content {
  height: 100%;
  overflow-y: auto;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 18px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .form-item {
    .form-item-label {
      text-align: center;
      padding: 10px 0;
    }
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 100;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>