var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("attchmentEditor", {
        attrs: {
          attachments: _vm.bodyComposition.Attachments,
          bussiness: "BodyComposition",
          readOnly: _vm.disable
        },
        on: { uploaded: _vm.uploadSuccess }
      }),
      _c(
        "el-form",
        {
          ref: "BodyComposition",
          attrs: {
            model: _vm.bodyComposition,
            rules: _vm.bodyCompositionRules,
            "label-width": "110px",
            disabled: _vm.disable
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("骨骼肌")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左上肢", prop: "GGJTopLeft" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.GGJTopLeft,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJTopLeft", $$v)
                          },
                          expression: "bodyComposition.GGJTopLeft"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右上肢", prop: "GGJTopRight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.GGJTopRight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJTopRight", $$v)
                          },
                          expression: "bodyComposition.GGJTopRight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左下肢", prop: "GGJBottomLeft" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.GGJBottomLeft,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJBottomLeft", $$v)
                          },
                          expression: "bodyComposition.GGJBottomLeft"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右下肢", prop: "GGJBottomRight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.GGJBottomRight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJBottomRight", $$v)
                          },
                          expression: "bodyComposition.GGJBottomRight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "躯干", prop: "GGJBody" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.GGJBody,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJBody", $$v)
                          },
                          expression: "bodyComposition.GGJBody"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "骨骼肌总量", prop: "GGJ" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.GGJ,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJ", $$v)
                          },
                          expression: "bodyComposition.GGJ"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "判定", prop: "GGJJudge" } },
                    [
                      _c("enumSelector", {
                        staticStyle: { width: "200px" },
                        attrs: { options: _vm.$config.standards },
                        model: {
                          value: _vm.bodyComposition.GGJJudge,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "GGJJudge", $$v)
                          },
                          expression: "bodyComposition.GGJJudge"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("体脂")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左上肢", prop: "TZTopLeft" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZTopLeft,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZTopLeft", $$v)
                          },
                          expression: "bodyComposition.TZTopLeft"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右上肢", prop: "TZTopRight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZTopRight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZTopRight", $$v)
                          },
                          expression: "bodyComposition.TZTopRight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左下肢", prop: "TZBottomLeft" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZBottomLeft,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZBottomLeft", $$v)
                          },
                          expression: "bodyComposition.TZBottomLeft"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "右下肢", prop: "TZBottomRight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZBottomRight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZBottomRight", $$v)
                          },
                          expression: "bodyComposition.TZBottomRight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "躯干", prop: "TZBody" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZBody,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZBody", $$v)
                          },
                          expression: "bodyComposition.TZBody"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "体脂量", prop: "TZ" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZ,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZ", $$v)
                          },
                          expression: "bodyComposition.TZ"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "体脂率", prop: "TZRate" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.TZRate,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZRate", $$v)
                          },
                          expression: "bodyComposition.TZRate"
                        }
                      }),
                      _vm._v("% ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "判定", prop: "TZJudge" } },
                    [
                      _c("enumSelector", {
                        staticStyle: { width: "200px" },
                        attrs: { options: _vm.$config.standards },
                        model: {
                          value: _vm.bodyComposition.TZJudge,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "TZJudge", $$v)
                          },
                          expression: "bodyComposition.TZJudge"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("内脏脂肪")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内脏脂肪", prop: "NZ" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.NZ,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "NZ", $$v)
                          },
                          expression: "bodyComposition.NZ"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内脏脂肪面积", prop: "NZArea" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.NZArea,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "NZArea", $$v)
                          },
                          expression: "bodyComposition.NZArea"
                        }
                      }),
                      _vm._v("cm2 ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内脏脂肪等级", prop: "NZDengJi" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.NZDengJi,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "NZDengJi", $$v)
                          },
                          expression: "bodyComposition.NZDengJi"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "判定", prop: "NZJudge" } },
                    [
                      _c("enumSelector", {
                        staticStyle: { width: "200px" },
                        attrs: { options: _vm.$config.nzDengji },
                        model: {
                          value: _vm.bodyComposition.NZJudge,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "NZJudge", $$v)
                          },
                          expression: "bodyComposition.NZJudge"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("代谢")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "基础代谢量", prop: "DXBase" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.DXBase,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "DXBase", $$v)
                          },
                          expression: "bodyComposition.DXBase"
                        }
                      }),
                      _vm._v("kcal ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "总代谢消耗", prop: "DX" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.DX,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "DX", $$v)
                          },
                          expression: "bodyComposition.DX"
                        }
                      }),
                      _vm._v("kcal ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "判定", prop: "DXJudge" } },
                    [
                      _c("enumSelector", {
                        staticStyle: { width: "200px" },
                        attrs: { options: _vm.$config.standards },
                        model: {
                          value: _vm.bodyComposition.DXJudge,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "DXJudge", $$v)
                          },
                          expression: "bodyComposition.DXJudge"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("体型")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身高", prop: "BodyHeight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.BodyHeight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "BodyHeight", $$v)
                          },
                          expression: "bodyComposition.BodyHeight"
                        }
                      }),
                      _vm._v("cm ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "体重", prop: "Jcdxl" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.BodyWeight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "BodyWeight", $$v)
                          },
                          expression: "bodyComposition.BodyWeight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "去脂体重", prop: "BodyWeightNoTZ" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.BodyWeightNoTZ,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "BodyWeightNoTZ", $$v)
                          },
                          expression: "bodyComposition.BodyWeightNoTZ"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "判定", prop: "BodyJudge" } },
                    [
                      _c("enumSelector", {
                        staticStyle: { width: "200px" },
                        attrs: { options: _vm.$config.bodyStyleStandards },
                        model: {
                          value: _vm.bodyComposition.BodyJudge,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "BodyJudge", $$v)
                          },
                          expression: "bodyComposition.BodyJudge"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("评估结果")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "得分", prop: "Score" } },
                    [_c("el-tag", [_vm._v(_vm._s(_vm.bodyComposition.Score))])],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "建议体重", prop: "RecWeight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.RecWeight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "RecWeight", $$v)
                          },
                          expression: "bodyComposition.RecWeight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "体重控制", prop: "ConWeight" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.ConWeight,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "ConWeight", $$v)
                          },
                          expression: "bodyComposition.ConWeight"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "肌肉控制", prop: "ConJiRou" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.ConJiRou,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "ConJiRou", $$v)
                          },
                          expression: "bodyComposition.ConJiRou"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "脂肪控制", prop: "ConZhiFang" } },
                    [
                      _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.bodyComposition.ConZhiFang,
                          callback: function($$v) {
                            _vm.$set(_vm.bodyComposition, "ConZhiFang", $$v)
                          },
                          expression: "bodyComposition.ConZhiFang"
                        }
                      }),
                      _vm._v("kg ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }