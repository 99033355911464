<template>
  <div>
    <attchmentEditor
      :attachments="heartLung.Attachments"
      bussiness="HeartLung"
      @uploaded="uploadSuccess"
      :readOnly="disable"
      desc="请按如下顺序上传文件：全阶段数据>运动肺>心肺九图"
    ></attchmentEditor>
    <el-form
      :model="heartLung"
      :rules="heartLungRules"
      ref="HeartLung"
      label-width="100px"
      :disabled="disable"
      label-position="left"
    >
      <div class="row">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>最大脂肪氧化强度</span>
          </div>
          <div class="box-card-body">
            <div class="form">
              <div class="form-item">
                <el-form-item label="摄氧量" prop="ZfSy">
                  <el-input-number
                    v-model="heartLung.ZfSy"
                    size="small"
                  ></el-input-number
                  >ml/min
                </el-form-item>
                <el-form-item label="公斤摄氧量" prop="ZfSykg">
                  <el-input-number
                    v-model="heartLung.ZfSykg"
                    size="small"
                  ></el-input-number
                  >ml/min/kg
                </el-form-item>
                <el-form-item label="心率" prop="ZfBpm">
                  <el-input-number
                    v-model="heartLung.ZfBpm"
                    size="small"
                  ></el-input-number
                  >bpm
                </el-form-item>
                <el-form-item label="跑台速度" prop="ZfPaoSu">
                  <el-input-number
                    v-model="heartLung.ZfPaoSu"
                    size="small"
                  ></el-input-number
                  >km/h
                </el-form-item>
                <el-form-item label="耗糖量" prop="ZfHaoTang">
                  <el-input-number
                    v-model="heartLung.ZfHaoTang"
                    size="small"
                  ></el-input-number
                  >g/h
                </el-form-item>
                <el-form-item label="减脂量" prop="ZfJianZhi">
                  <el-input-number
                    v-model="heartLung.ZfJianZhi"
                    size="small"
                  ></el-input-number
                  >g/h
                </el-form-item>
                <el-form-item label="能量消耗" prop="ZfXiaoHao">
                  <el-input-number
                    v-model="heartLung.ZfXiaoHao"
                    size="small"
                  ></el-input-number
                  >kcal/h
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>无氧阈强度</span>
          </div>
          <div class="box-card-body">
            <div class="form">
              <div class="form-item">
                <el-form-item label="摄氧量" prop="WyySy">
                  <el-input-number
                    v-model="heartLung.WyySy"
                    size="small"
                  ></el-input-number
                  >ml/min
                </el-form-item>
                <el-form-item label="公斤摄氧量" prop="WyySykg">
                  <el-input-number
                    v-model="heartLung.WyySykg"
                    size="small"
                  ></el-input-number
                  >ml/min/kg
                </el-form-item>
                <el-form-item label="心率" prop="WyyBpm">
                  <el-input-number
                    v-model="heartLung.WyyBpm"
                    size="small"
                  ></el-input-number
                  >bpm
                </el-form-item>
                <el-form-item label="跑台速度" prop="WyyPaoSu">
                  <el-input-number
                    v-model="heartLung.WyyPaoSu"
                    size="small"
                  ></el-input-number
                  >km/h
                </el-form-item>
                <el-form-item label="耗糖量" prop="WyyHaoTang">
                  <el-input-number
                    v-model="heartLung.WyyHaoTang"
                    size="small"
                  ></el-input-number
                  >g/h
                </el-form-item>
                <el-form-item label="减脂量" prop="WyyJianZhi">
                  <el-input-number
                    v-model="heartLung.WyyJianZhi"
                    size="small"
                  ></el-input-number
                  >g/h
                </el-form-item>
                <el-form-item label="能量消耗" prop="WyyXiaoHao">
                  <el-input-number
                    v-model="heartLung.WyyXiaoHao"
                    size="small"
                  ></el-input-number
                  >kcal/h
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>最大摄氧量强度</span>
          </div>
          <div class="box-card-body">
            <div class="form">
              <div class="form-item">
                <el-form-item label="摄氧量" prop="MaxSy">
                  <el-input-number
                    v-model="heartLung.MaxSy"
                    size="small"
                  ></el-input-number
                  >ml/min
                </el-form-item>
                <el-form-item label="公斤摄氧量" prop="MaxSykg">
                  <el-input-number
                    v-model="heartLung.MaxSykg"
                    size="small"
                  ></el-input-number
                  >ml/min/kg
                </el-form-item>
                <el-form-item label="心率" prop="MaxSyBpm">
                  <el-input-number
                    v-model="heartLung.MaxSyBpm"
                    size="small"
                  ></el-input-number
                  >bpm
                </el-form-item>
                <el-form-item label="跑台速度" prop="MaxSyPaoSu">
                  <el-input-number
                    v-model="heartLung.MaxSyPaoSu"
                    size="small"
                  ></el-input-number
                  >km/h
                </el-form-item>
                <el-form-item label="耗糖量" prop="MaxSyHaoTang">
                  <el-input-number
                    v-model="heartLung.MaxSyHaoTang"
                    size="small"
                  ></el-input-number
                  >g/h
                </el-form-item>
                <el-form-item label="减脂量" prop="MaxSyJianZhi">
                  <el-input-number
                    v-model="heartLung.MaxSyJianZhi"
                    size="small"
                  ></el-input-number
                  >g/h
                </el-form-item>
                <el-form-item label="能量消耗" prop="MaxSyXiaoHao">
                  <el-input-number
                    v-model="heartLung.MaxSyXiaoHao"
                    size="small"
                  ></el-input-number
                  >kcal/h
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人最好成绩与理想成绩相关</span>
          </div>
          <div class="box-card-body">
            <el-form-item
              style="width: 80%"
              label-width="400"
              label="马拉松个人历史最好成绩"
              prop="ScoreMls"
            >
              <el-input-number
                v-model="heartLung.ScoreMls"
                size="small"
              ></el-input-number
              >s
            </el-form-item>
            <el-form-item
              style="width: 80%"
              label-width="400"
              label="当前无氧阈值对应理想成绩"
              prop="ScoreWyy"
            >
              <el-input-number
                v-model="heartLung.ScoreWyy"
                size="small"
              ></el-input-number
              >s
            </el-form-item>
            <el-form-item
              style="width: 80%"
              label-width="400"
              label="当前90%无氧阈最好成绩"
              prop="ScoreWyySy90"
            >
              <el-input-number
                v-model="heartLung.ScoreWyySy90"
                size="small"
              ></el-input-number
              >s
            </el-form-item>
            <el-form-item
              style="width: 80%"
              label-width="400"
              label="当前最大脂肪氧化强度最好成绩"
              prop="ZFScore"
            >
              <el-input-number
                v-model="heartLung.ZFScore"
                size="small"
              ></el-input-number
              >s
            </el-form-item>
            <el-form-item
              style="width: 80%"
              label-width="400"
              label="当前最大摄氧量对应理想成绩"
              prop="ScoreMaxSy"
            >
              <el-input-number
                v-model="heartLung.ScoreMaxSy"
                size="small"
              ></el-input-number
              >s
            </el-form-item>
            <el-form-item
              style="width: 80%"
              label-width="400"
              label="个人历史最好成绩与当前无氧阈值对应理想成绩的差值"
              prop="ScoreWyyDistance"
            >
              {{
                heartLung.ScoreWyyDistance ? heartLung.ScoreWyyDistance : "-"
              }}s
            </el-form-item>
            <el-form-item
              style="width: 80%"
              label-width="500"
              label="个人历史最好成绩与最大摄氧量对应理想成绩的差值"
              prop="ScoreMaxSyDistance"
            >
              {{
                heartLung.ScoreMaxSyDistance
                  ? heartLung.ScoreMaxSyDistance
                  : "-"
              }}s
            </el-form-item>
          </div>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>跑步经济性</span>
          </div>
          <div class="box-card-body">
            <div class="form">
              <div class="form-row">
                <div class="form-item">
                  <el-form-item label="跑步经济性" prop="RunEconomic">
                    <el-input-number
                      v-model="heartLung.RunEconomic"
                      size="small"
                    ></el-input-number
                    >ml/kg/km
                  </el-form-item>
                  <el-form-item label="摄氧量" prop="RunSy">
                    <el-input-number
                      v-model="heartLung.RunSy"
                      size="small"
                    ></el-input-number
                    >ml/min/kg
                  </el-form-item>
                  <el-form-item label="心率" prop="RunBpm">
                    <el-input-number
                      v-model="heartLung.RunBpm"
                      size="small"
                    ></el-input-number
                    >bpm
                  </el-form-item>
                  <el-form-item label="跑台速度" prop="RunPaoSu">
                    <el-input-number
                      v-model="heartLung.RunPaoSu"
                      size="small"
                    ></el-input-number
                    >km/h
                  </el-form-item>
                  <el-form-item label="耗糖量" prop="RunHaoTang">
                    <el-input-number
                      v-model="heartLung.RunHaoTang"
                      size="small"
                    ></el-input-number
                    >g/h
                  </el-form-item>
                  <el-form-item label="减脂量" prop="RunJianZhi">
                    <el-input-number
                      v-model="heartLung.RunJianZhi"
                      size="small"
                    ></el-input-number
                    >g/h
                  </el-form-item>
                  <el-form-item label="能量消耗" prop="RunXiaoHao">
                    <el-input-number
                      v-model="heartLung.RunXiaoHao"
                      size="small"
                    ></el-input-number
                    >kcal/h
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>评估结果</span>
          </div>
          <div class="box-card-body">
            <div class="form">
              <div class="form-row">
                <div class="form-item" style="width: 100%">
                  <el-form-item label="评估得分" prop="Score">
                    <el-tag>{{ heartLung.Score }}</el-tag>
                  </el-form-item>
                  <!-- <el-form-item label="评估结果" prop="Summary">
                    <el-input
                      type="textarea"
                      rows="5"
                      style="width: 100%"
                      v-model="heartLung.Summary"
                    ></el-input>
                  </el-form-item> -->
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </el-form>
  </div>
</template>
<script>
import { loadFileData } from "@/api/check/check.js";
import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
    value: {
      default: {},
    },
  },
  watch: {
    value: {
      handler(v) {
        this.heartLung = v;
        this.ensureAttachments();
      },
      deep: true,
      immediate: true,
    },
  },
  components: { attchmentEditor, enumSelector },
  data() {
    return {
      checkType: "HeartLung",
      //膝关节损伤
      heartLung: {},
      heartLungRules: {
        // Summary: { required: true, message: "请输入", trigger: "null" },
        ZfSy: { required: true, message: "请输入", trigger: "null" },
        ZfSykg: { required: true, message: "请输入", trigger: "null" },
        ZfBpm: { required: true, message: "请输入", trigger: "null" },
        ZfPaoSu: { required: true, message: "请输入", trigger: "null" },
        ZfHaoTang: { required: true, message: "请输入", trigger: "null" },
        ZfJianZhi: { required: true, message: "请输入", trigger: "null" },
        ZfXiaoHao: { required: true, message: "请输入", trigger: "null" },
        ZFScore: { required: true, message: "请输入", trigger: "null" },

        WyySy: { required: true, message: "请输入", trigger: "null" },
        WyySykg: { required: true, message: "请输入", trigger: "null" },
        WyyBpm: { required: true, message: "请输入", trigger: "null" },
        WyyPaoSu: { required: true, message: "请输入", trigger: "null" },
        WyyHaoTang: { required: true, message: "请输入", trigger: "null" },
        WyyJianZhi: { required: true, message: "请输入", trigger: "null" },
        WyyXiaoHao: { required: true, message: "请输入", trigger: "null" },

        MaxSy: { required: true, message: "请输入", trigger: "null" },
        MaxSykg: { required: true, message: "请输入", trigger: "null" },
        MaxSyBpm: { required: true, message: "请输入", trigger: "null" },
        MaxSyPaoSu: { required: true, message: "请输入", trigger: "null" },
        MaxSyHaoTang: { required: true, message: "请输入", trigger: "null" },
        MaxSyJianZhi: { required: true, message: "请输入", trigger: "null" },
        MaxSyXiaoHao: { required: true, message: "请输入", trigger: "null" },

        ScoreWyy: { required: true, message: "请输入", trigger: "null" },
        ScoreMaxSy: { required: true, message: "请输入", trigger: "null" },
        ScoreWyySy90: { required: true, message: "请输入", trigger: "null" },

        RunEconomic: { required: true, message: "请输入", trigger: "null" },
        RunSy: { required: true, message: "请输入", trigger: "null" },
        RunBpm: { required: true, message: "请输入", trigger: "null" },
        RunPaoSu: { required: true, message: "请输入", trigger: "null" },
        RunHaoTang: { required: true, message: "请输入", trigger: "null" },
        RunJianZhi: { required: true, message: "请输入", trigger: "null" },
        RunXiaoHao: { required: true, message: "请输入", trigger: "null" },

        VitalCapacity: { required: true, message: "请输入", trigger: "null" },
        ExertVitalCapacity: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        OneSecond: { required: true, message: "请输入", trigger: "null" },
        OneSecondRate: { required: true, message: "请输入", trigger: "null" },
        MaxGas: { required: true, message: "请输入", trigger: "null" },

        VitalCapacityForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ExertVitalCapacityForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        OneSecondForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        OneSecondRateForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxGasForecast: { required: true, message: "请输入", trigger: "null" },

        MaxAbsorbPressure: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxAbsorbForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxExhalePressure: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        MaxExhaleForecast: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
      },
    };
  },
  computed: {},
  methods: {
    uploadSuccess(val) {
      if (
        val.file.Type == "HeartLungTotal" ||
        val.file.Type == "HeartLungMove" ||
        val.file.Type == "HeartLungStatic" ||
        val.file.Type == "HeartLungJiuTu" ||
        val.file.Type == "HeartLungAll" ||
        val.file.Type == "BreatheReport" ||
        val.file.Type == "HeartLungStaticStandard" ||
        val.file.Type == "MaxFatYangHua"
      ) {
        let param = {
          checkKey: this.checkKey,
          checkType: val.bussiness,
          attachType: val.file.Type,
          dataFile: val.file.Path,
          checkItem: this.heartLung,
        };
        loadFileData(param).then((res) => {
          if (res) {
            debugger;
            this.heartLung = res;
          }
        });
      }
    },
    //确保有附件集合对象;
    ensureAttachments() {
      if (!this.heartLung.Attachments) {
        this.$set(this.heartLung, "Attachments", {
          ItemType: "XTH.Attachment",
          Items: [],
        });
      }
    },
    doValidate() {
      this.$refs[this.checkType].validate((valid) => {
        if (valid) {
          this.$emit("passValidate", this.heartLung);
        } else {
          setTimeout(() => {
            this.$refs[this.checkType].clearValidate();
          }, 3000);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 20px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 90%;
      padding-right: 50px;

      .form-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .form-item-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
        .form-item-label {
          text-align: center;
          padding: 10px 0;
          font-size: 14px;
          width: 100%;
          font-weight: bolder;
        }
      }
    }
  }

  .input200 {
    width: 200px;
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>