const acceptableStatus = [{
  key: 'None',
  name: '未评估',
  value: 0,
},
{
  key: 'Low',
  name: '低危',
  value: 10,
},
{
  key: 'High',
  name: '高危',
  value: 20,
}
]
const checkStatus = [
  {
    key: 'Appoint',
    name: '已预约',
    value: 10,
  },
  {
    key: 'Done',
    name: '完成',
    value: 100,
  },
  {
    key: 'Cancel',
    name: '已取消',
    value: 200,
  },
  {
    key: 'Refuse',
    name: '已拒绝',
    value: 300,
  },
]
const checkType = [
  {
    key: 'BodyComposition',
    name: '人体成分评估',
    value: 2,
  },
  {
    key: 'FootPressure',
    name: '足底健康评估',
    value: 4,
  },
  {
    key: 'KneeHurt',
    name: '关节功能评估',
    value: 8,
  },
  {
    key: 'RunPosture',
    name: '三维动作捕捉',
    value: 16,
  },
  {
    key: 'Breath',
    name: '呼吸功能评估',
    value: 32,
  },
  {
    key: 'HeartLung',
    name: '心肺耐力评估',
    value: 64,
  },
  {
    key: 'HeartEcg',
    name: '心电图运动负荷试验',
    value: 128,
  },
]


const improveType = [
  {
    key: 'RationalDiet',
    name: '膳食改善方案',
    value: 2,
  },
  {
    key: 'LowerLimbs',
    name: '下肢功能改善',
    value: 8,
  },
  {
    key: 'Breath',
    name: '呼吸功能改善',
    value: 32,
  },
  {
    key: 'HeartLung',
    name: '心肺功能改善',
    value: 64,
  }
]

const checkSource = [
  {
    key: 'OffLine',
    name: '线下',
    value: 1,
  },
  {
    key: 'OnLine',
    name: '线上',
    value: 2,
  },
]

const appAccoutTypes = [
  {
    key: 'Other',
    name: '其他',
    value: 0,
  },
  {
    key: 'WXPay',
    name: '微信支付',
    value: 10,
  },
  {
    key: 'WXApp',
    name: '微信小程序',
    value: 20,
  },
  {
    key: 'WXPublicApp',
    name: '微信公众号',
    value: 30,
  },
]

const appointTimes = [
  {
    key: 'Nine',
    name: '9点',
    value: 9,
  },
  {
    key: 'Ten',
    name: '10点',
    value: 10,
  },
  {
    key: 'Eleven',
    name: '11点',
    value: 11,
  },
  {
    key: 'Thirteen',
    name: '13点',
    value: 13,
  },
  {
    key: 'Fourteen',
    name: '14点',
    value: 14,
  },
  {
    key: 'Fifteen',
    name: '15点',
    value: 15,
  },
  {
    key: 'Sixteen',
    name: '16点',
    value: 16,
  },
  {
    key: 'Seventeen',
    name: '17点',
    value: 17,
  },
]

const wineType = [
  {
    key: 'None',
    name: '不饮酒',
    value: 0,
  },
  {
    key: 'Bai',
    name: '白酒',
    value: 10,
  },
  {
    key: 'Hong',
    name: '红酒',
    value: 20,
  },
  {
    key: 'Pi',
    name: '啤酒',
    value: 30,
  },
]
const fengZhiYaLiPostions = [
  {
    key: "左足前侧",
    name: '左足前侧',
    value: "左足前侧",
  },
  {
    key: "左足后侧",
    name: '左足后侧',
    value: '左足后侧',
  },
  {
    key: "右足前侧",
    name: '右足前侧',
    value: "右足前侧",
  },
  {
    key: "右足后侧",
    name: '右足后侧',
    value: "右足后侧",
  }
]

const kneeHurtType = [{
  key: "ShangTai",
  name: '主动直腿上抬',
  value: 1,
},
{
  key: "ShenKuan",
  name: '俯卧髋关节主动伸展',
  value: 2,
},
{
  key: "QuXi",
  name: '俯卧膝关节主动屈曲',
  value: 3,
},
{
  key: "NeiXuan",
  name: '俯卧屈膝髋关节内旋',
  value: 4,
},
{
  key: "LingHuo",
  name: '踝关节灵活性',
  value: 5,
},
{
  key: "WenDing",
  name: '闭眼单脚站立稳定性',
  value: 6,
},
{
  key: "GongJianBu",
  name: '弓箭步',
  value: 7,
},
{
  key: "JiaoLian",
  name: '髋关节铰链',
  value: 8,
}, {
  key: "HuanChong",
  name: '落地缓冲',
  value: 9,
}, {
  key: "ShenDun",
  name: '深蹲',
  value: 10,
}]
const kneeHurtZhiTuiShangTai = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '明显不对称（左右差异>10%)',
    value: 1,
  },
  {
    key: 2,
    name: '屈曲角度 <30°',
    value: 2,
  },
  {
    key: 3,
    name: '30°≤屈曲角度 <50°',
    value: 3,
  },
  {
    key: 4,
    name: '50≤屈曲角度 <70°',
    value: 4,
  },
  {
    key: 5,
    name: '70°≤屈曲角度',
    value: 5,
  },
]
const prescType = [
  {
    key: "KneeItem101",
    name: '关节活动度/主动直腿上抬',
    value: 101,
  },
  {
    key: "KneeItem102",
    name: '关节活动度/俯卧髋关节主动伸展',
    value: 102,
  },
  {
    key: "KneeItem103",
    name: '关节活动度/俯卧膝关节主动屈曲',
    value: 103,
  },
  {
    key: "KneeItem104",
    name: '关节活动度/俯卧屈膝髋关节内旋',
    value: 104,
  },
  {
    key: "KneeItem105",
    name: '关节活动度/踝关节灵活性',
    value: 105,
  },
  {
    key: "KneeItem106",
    name: '关节活动度/动作控制',
    value: 106,
  },
  {
    key: "KneeItem107",
    name: '关节活动度/髋关节铰链',
    value: 107,
  },
  {
    key: "KneeItem108",
    name: '关节活动度/深蹲',
    value: 108,
  },
  {
    key: "KneeItem109",
    name: '关节活动度/单腿下蹲',
    value: 109,
  },
  {
    key: "KneeItem110",
    name: '关节活动度/落地缓冲',
    value: 110,
  },
  {
    key: "Breathe",
    name: '呼吸功能',
    value: 200,
  },
  {
    key: "AbdominalBreathing",
    name: '功能训练/腹式呼吸',
    value: 100,
  },
  {
    key: "HeartLung",
    name: '心肺功能',
    value: 600,
  },
]
const kneeHurtKuanGuanJieShenZhan = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '明显不对称（左右差异>10%)',
    value: 1,
  },
  {
    key: 2,
    name: '大腿离地角度 <10°',
    value: 2,
  },
  {
    key: 3,
    name: '10°≤离地角度 <20°',
    value: 3,
  },
  {
    key: 4,
    name: '20°≤离地角度 <30°',
    value: 4,
  },
  {
    key: 5,
    name: '30°≤离地角度',
    value: 5,
  },
]

const kneeHurtXiGuanJieQuShen = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '明显不对称（左右差异>10%)',
    value: 1,
  },
  {
    key: 2,
    name: '屈曲角度 <105°',
    value: 2,
  },
  {
    key: 3,
    name: '105°≤屈曲角度 <120°',
    value: 3,
  },
  {
    key: 4,
    name: '120°≤屈曲角度 <135°',
    value: 4,
  },
  {
    key: 5,
    name: '135°≤屈曲角度',
    value: 5,
  },
]

const kneeHurtKuanGuanJieNeiXuan = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '明显不对称（左右差异>10%)',
    value: 1,
  },
  {
    key: 2,
    name: '胫骨外旋角度 <20°',
    value: 2,
  },
  {
    key: 3,
    name: '20°≤外旋角度 <30°',
    value: 3,
  },
  {
    key: 4,
    name: '30°≤外旋角度 <40°',
    value: 4,
  },
  {
    key: 5,
    name: '40°≤外旋角度',
    value: 5,
  },
]

const kneeHurtHuaiGuanJieLingHuo = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '明显不对称（左右差异>10%)',
    value: 1,
  },
  {
    key: 2,
    name: '胫骨前倾角度 <20°',
    value: 2,
  },
  {
    key: 3,
    name: '20°≤前倾角度 <30°',
    value: 3,
  },
  {
    key: 4,
    name: '30°≤前倾角度 <40°',
    value: 4,
  },
  {
    key: 5,
    name: '40°≤前倾角度',
    value: 5,
  },
]

const kneeHurtGongJianBu = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '膝关节内扣',
    value: 1,
  },
  {
    key: 2,
    name: '髋关节和肩关节没有保持水平',
    value: 2,
  },
  {
    key: 3,
    name: '躯干旋转',
    value: 3,
  },
  {
    key: 4,
    name: '身体出现明显晃动',
    value: 4,
  },
]

const kneeHurtLuoDiHuanChong = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '至少一侧膝关节出现内扣',
    value: 1,
  },
  {
    key: 2,
    name: '双脚落地姿势差异明显',
    value: 2,
  },
  {
    key: 3,
    name: '缓冲质量不佳',
    value: 3,
  },
  {
    key: 4,
    name: '落地动作不稳',
    value: 4,
  },
]

const kneeHurtShenDun = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '至少一侧膝关节出现内扣',
    value: 1,
  },
  {
    key: 2,
    name: '脚跟翘起或脚尖外旋',
    value: 2,
  },
  {
    key: 3,
    name: '大腿没有低于水平面',
    value: 3,
  },
  {
    key: 4,
    name: '躯干过度前倾或出现侧倾',
    value: 4,
  },
]

const kneeHurtKuanGuanJieJiaoLian = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '长杆没有贴紧身体',
    value: 1,
  },
  {
    key: 2,
    name: '屈髋幅度<60°',
    value: 2,
  },
  {
    key: 3,
    name: '小腿不能垂直于地面',
    value: 3,
  },
  {
    key: 4,
    name: '躯干偏离中心线',
    value: 4,
  },
]

const kneeHurtDanTuiZhanLi = [
  {
    key: 0,
    name: '出现疼痛',
    value: 0,
  },
  {
    key: 1,
    name: '明显不对称（左右差异>10%)',
    value: 1,
  },
  {
    key: 2,
    name: '0＜时间≤7s',
    value: 2,
  }
  ,
  {
    key: 3,
    name: '7s＜时间≤14s',
    value: 3,
  }
  ,
  {
    key: 4,
    name: '14s＜时间≤20s',
    value: 4,
  }
  ,
  {
    key: 5,
    name: '＞20s',
    value: 5,
  }
]

const runType = [
  {
    key: 'None',
    name: '不跑步',
    value: 0,
  },
  {
    key: 'ThreeKM',
    name: '3km最好成绩',
    value: 3,
  },
  {
    key: 'FiveKM',
    name: '5km最好成绩',
    value: 5,
  },
  {
    key: 'OneKM',
    name: '10km最好成绩',
    value: 10,
  },
  {
    key: 'BanMa',
    name: '半马最好成绩',
    value: 20,
  },
  {
    key: 'QuanMa',
    name: '全马最好成绩',
    value: 30,
  },
]

const healths = [
  {
    value: 1,
    label: '胸痛',
  },
  {
    value: 2,
    label: '胸闷',
  },
  {
    value: 3,
    label: '心悸',
  },
  {
    value: 4,
    label: '大汗',
  },
  {
    value: 5,
    label: '黑曚',
  },
  {
    value: 6,
    label: '晕厥',
  },
  {
    value: 7,
    label: '发热',
  },
  {
    value: 8,
    label: '明显四肢乏力',
  },
  {
    value: 9,
    label: '喘憋',
  },
]

const medicals = [{
  value: 1,
  label: "抗血小板药"
},
{
  value: 2,
  label: "抗心律失常药"
},
{
  value: 3,
  label: "降压药"
},
{
  value: 4,
  label: "降脂药"
},
{
  value: 5,
  label: "降糖药"
},
{
  value: 6,
  label: "其他常用药"
},

]
const inheritances = [
  {
    value: 1,
    label: "先天性心脏病",
  },
  {
    value: 2,
    label: "遗传性心脏病",
  },
  {
    value: 3,
    label: "其他家族病史",
  },
]

const diseases = [
  {
    value: 1,
    label: '房颤',
  },
  {
    value: 2,
    label: '高度房室传导阻滞',
  },
  {
    value: 3,
    label: '起搏器植入术',
  },
  {
    value: 4,
    label: '瓣膜病',
  },
  {
    value: 5,
    label: '糖尿病',
  },
  {
    value: 6,
    label: '高血压',
  },
  {
    value: 7,
    label: '低血糖发作',
  },
  {
    value: 8,
    label: '骨关节疾患',
  },
  {
    value: 9,
    label: '肺动脉高压',
  },
  {
    value: 10,
    label: '肿瘤',
  },
  {
    value: 11,
    label: '鼾症',
  },
  {
    value: 12,
    label: '支架或冠脉搭桥手术史',
  },
  {
    value: 13,
    label: '发冠心病/高血压家族史',
  },
]

const attachmentType = [
  {
    key: 'FootStatic',
    name: '静态测试',
    value: 100,
  },
  {
    key: 'FootPose',
    name: '姿势测试',
    value: 110,
  },
  {
    key: 'FootDynamic',
    name: '动态测试',
    value: 120,
  },
  {
    key: 'FootShape',
    name: '足形扫描',
    value: 130,
  },
  {
    key: 'BodyComposition',
    name: '体成分',
    value: 400,
  },

  {
    key: 'HeartLungTotal',
    name: '心肺整体报告',
    value: 500,
  },
  {
    key: 'HeartLungMove',
    name: '运动肺',
    value: 510,
  },
  {
    key: 'HeartLungStatic',
    name: '静态肺',
    value: 520,
  },

  {
    key: 'HeartLungJiuTu',
    name: '心肺九图',
    value: 530,
  },
  {
    key: 'HeartLungAll',
    name: '全阶段数据',
    value: 540,
  },
  {
    key: 'MaxFatYangHua',
    name: '最大脂肪氧化强度',
    value: 550,
  },
  {
    key: 'RunPosture',
    name: '姿态报告',
    value: 600,
  },
  {
    key: 'BreathStaticStandard',
    name: '静态肺标准报告',
    value: 700,
  },
  {
    key: 'BreatheReport',
    name: '呼吸报告',
    value: 710,
  },
  {
    key: 'HeartEcgReport',
    name: '心电图运动负荷试验',
    value: 800,
  },
  {
    key: 'Other',
    name: '其他',
    value: 1000,
  }
]

const exercise = [
  {
    value: 1,
    label: "跑步"
  }, {
    value: 2,
    label: "足球"
  },
  {
    value: 3,
    label: "篮球"
  },
  {
    value: 4,
    label: "乒乓球"
  },
  {
    value: 5,
    label: "游泳"
  },
  {
    value: 6,
    label: "滑雪"
  },
  {
    value: 7,
    label: "其他"
  }]

const memberType = [
  {
    key: 'Member',
    name: '受试者',
    value: 0,
  },
  {
    key: 'Manager',
    name: '测试管理',
    value: 10,
  },
  {
    key: 'Expert',
    name: '测评专家',
    value: 20,
  },
]

const zuxing = [{
  key: '扁平',
  name: '扁平',
  value: "扁平",
},
{
  key: '正常',
  name: '正常',
  value: '正常',
},
{
  key: '高弓',
  name: '高弓',
  value: '高弓',
}]

const zuhuai = [{
  key: '旋内',
  name: '旋内',
  value: "旋内",
},
{
  key: '正常',
  name: '正常',
  value: '正常',
},
{
  key: '旋外',
  name: '旋外',
  value: '旋外',
}]

const standards = [{
  key: '低于标准',
  name: '低于标准',
  value: "低于标准",
},
{
  key: '标准',
  name: '标准',
  value: '标准',
},
{
  key: '超出标准',
  name: '超出标准',
  value: '超出标准',
}]

const bodyStyleStandards = [{
  key: '标准',
  name: '标准',
  value: "标准",
},
{
  key: '超重',
  name: '超重',
  value: '超重',
},
{
  key: '轻度肥胖',
  name: '轻度肥胖',
  value: '轻度肥胖',
},
{
  key: '中度肥胖',
  name: '中度肥胖',
  value: '中度肥胖',
}, {
  key: '重度肥胖',
  name: '重度肥胖',
  value: '重度肥胖',
},
{
  key: '隐性肥胖1级',
  name: '隐性肥胖1级',
  value: '隐性肥胖1级',
},
{
  key: '隐性肥胖2级',
  name: '隐性肥胖2级',
  value: '隐性肥胖2级',
}, {
  key: '脂肪过量1级',
  name: '脂肪过量1级',
  value: '脂肪过量1级',
}, {
  key: '脂肪过量2级',
  name: '脂肪过量2级',
  value: '脂肪过量2级',
}, {
  key: '脂肪过量3级',
  name: '脂肪过量3级',
  value: '脂肪过量3级',
}, {
  key: '偏瘦',
  name: '偏瘦',
  value: '偏瘦',
}, {
  key: '低脂肪偏瘦',
  name: '低脂肪偏瘦',
  value: '低脂肪偏瘦',
}, {
  key: '标准肌肉型',
  name: '标准肌肉型',
  value: '标准肌肉型',
}, {
  key: '肌肉型',
  name: '肌肉型',
  value: '肌肉型',
}, {
  key: '肌肉型超重1级',
  name: '肌肉型超重1级',
  value: '肌肉型超重1级',
}, {
  key: '肌肉型超重2级',
  name: '肌肉型超重2级',
  value: '肌肉型超重2级',
}, {
  key: '运动员型',
  name: '运动员型',
  value: '运动员型',
}, {
  key: '肌肉不足',
  name: '肌肉不足',
  value: '肌肉不足',
}]

const traningStatus = [
  {
    key: 'Waiting',
    name: '预约',
    value: 0,
  },
  {
    key: 'Executed',
    name: '完成',
    value: 1,
  },
  {
    key: 'Cancel',
    name: '取消',
    value: 2,
  },
]

const nzDengji = [{
  key: '皮下型',
  name: '皮下型',
  value: "皮下型",
},
{
  key: '均衡型',
  name: '均衡型',
  value: '均衡型',
},
{
  key: '临界',
  name: '临界',
  value: '临界',
}, {
  key: '内脏肥胖',
  name: '内脏肥胖',
  value: '内脏肥胖',
}, {
  key: '严重内脏肥胖',
  name: '严重内脏肥胖',
  value: '严重内脏肥胖',
}]

export default {
  acceptableStatus,
  checkType,
  improveType,
  checkSource,
  healths,
  diseases,
  medicals,
  inheritances,
  appAccoutTypes,
  wineType,
  runType,
  exercise,
  attachmentType,
  memberType,
  appointTimes,

  kneeHurtZhiTuiShangTai,
  kneeHurtKuanGuanJieShenZhan,
  kneeHurtXiGuanJieQuShen,
  kneeHurtKuanGuanJieNeiXuan,
  kneeHurtHuaiGuanJieLingHuo,
  kneeHurtType,
  kneeHurtGongJianBu,
  kneeHurtLuoDiHuanChong,
  kneeHurtShenDun,
  kneeHurtKuanGuanJieJiaoLian,
  kneeHurtDanTuiZhanLi,

  fengZhiYaLiPostions,
  standards,
  bodyStyleStandards,
  nzDengji,
  zuxing,
  zuhuai,
  prescType,
  traningStatus
}
