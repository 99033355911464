var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("attchmentEditor", {
        attrs: {
          attachments: _vm.footPressure.Attachments,
          bussiness: "FootPressure",
          readOnly: _vm.disable
        },
        on: { uploaded: _vm.uploadSuccess }
      }),
      _c(
        "el-form",
        {
          ref: "FootPressure",
          attrs: {
            model: _vm.footPressure,
            rules: _vm.footPressureRules,
            "label-width": "120px",
            disabled: _vm.disable
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("静态测试")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "峰值压力位置",
                            prop: "FengZhiYaLiPostion"
                          }
                        },
                        [
                          _c("enumSelector", {
                            attrs: { options: _vm.$config.fengZhiYaLiPostions },
                            model: {
                              value: _vm.footPressure.FengZhiYaLiPostion,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.footPressure,
                                  "FengZhiYaLiPostion",
                                  $$v
                                )
                              },
                              expression: "footPressure.FengZhiYaLiPostion"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("左足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积前",
                              prop: "StaticLeftAreaFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftAreaFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftAreaFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftAreaFront"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积后",
                              prop: "StaticLeftAreaBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftAreaBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftAreaBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftAreaBack"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "总面积", prop: "StaticLeftArea" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftArea",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftArea"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最大压力",
                              prop: "StaticLeftMaxHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftMaxHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftMaxHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftMaxHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平均压力",
                              prop: "StaticLeftAveHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "input",
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftAveHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftAveHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftAveHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比前",
                              prop: "StaticLeftHeatRatioFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.StaticLeftHeatRatioFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftHeatRatioFront",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.StaticLeftHeatRatioFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比后",
                              prop: "StaticLeftHeatRatioBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftHeatRatioBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftHeatRatioBack",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.StaticLeftHeatRatioBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "总压力比",
                              prop: "StaticLeftHeatRatio"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftHeatRatio,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftHeatRatio",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftHeatRatio"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布前",
                              prop: "StaticLeftCoverFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftCoverFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftCoverFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftCoverFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布后",
                              prop: "StaticLeftCoverBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftCoverBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftCoverBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftCoverBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "重量", prop: "StaticLeftWeight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticLeftWeight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticLeftWeight",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticLeftWeight"
                              }
                            }),
                            _vm._v("kg ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "鞋码", prop: "DataLeftSize" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataLeftSize,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataLeftSize",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataLeftSize"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("右足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积前",
                              prop: "StaticRightAreaFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightAreaFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightAreaFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightAreaFront"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积后",
                              prop: "StaticRightAreaBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightAreaBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightAreaBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightAreaBack"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "面积", prop: "StaticRightArea" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightArea",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightArea"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最大压力",
                              prop: "StaticRightMaxHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightMaxHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightMaxHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightMaxHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平均压力",
                              prop: "StaticRightAveHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightAveHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightAveHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightAveHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比前",
                              prop: "StaticRightHeatRatioFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.StaticRightHeatRatioFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightHeatRatioFront",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.StaticRightHeatRatioFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比后",
                              prop: "StaticRightHeatRatioBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.StaticRightHeatRatioBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightHeatRatioBack",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.StaticRightHeatRatioBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "总压力比",
                              prop: "StaticRightHeatRatio"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightHeatRatio,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightHeatRatio",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightHeatRatio"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布前",
                              prop: "StaticRightCoverFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightCoverFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightCoverFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightCoverFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布后",
                              prop: "StaticRightCoverBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightCoverBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightCoverBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightCoverBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "重量", prop: "StaticRightWeight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.StaticRightWeight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "StaticRightWeight",
                                    $$v
                                  )
                                },
                                expression: "footPressure.StaticRightWeight"
                              }
                            }),
                            _vm._v("kg ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "鞋码", prop: "DataRightSize" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataRightSize,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataRightSize",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataRightSize"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("姿势测试")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("左足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积前",
                              prop: "PostureLeftAreaFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftAreaFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftAreaFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftAreaFront"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积后",
                              prop: "PostureLeftAreaBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftAreaBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftAreaBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftAreaBack"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "总面积", prop: "PostureLeftArea" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftArea",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftArea"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最大压力",
                              prop: "PostureLeftMaxHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftMaxHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftMaxHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftMaxHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平均压力",
                              prop: "PostureLeftAveHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "input",
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftAveHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftAveHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftAveHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比前",
                              prop: "PostureLeftHeatRatioFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.PostureLeftHeatRatioFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftHeatRatioFront",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.PostureLeftHeatRatioFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比后",
                              prop: "PostureLeftHeatRatioBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.PostureLeftHeatRatioBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftHeatRatioBack",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.PostureLeftHeatRatioBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "总压力比",
                              prop: "PostureLeftHeatRatio"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftHeatRatio,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftHeatRatio",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftHeatRatio"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布前",
                              prop: "PostureLeftCoverFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftCoverFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftCoverFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftCoverFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布后",
                              prop: "PostureLeftCoverBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftCoverBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftCoverBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftCoverBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "重量", prop: "PostureLeftWeight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureLeftWeight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureLeftWeight",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureLeftWeight"
                              }
                            }),
                            _vm._v("kg ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("右足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积前",
                              prop: "PostureRightAreaFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightAreaFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightAreaFront",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightAreaFront"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "面积后",
                              prop: "PostureRightAreaBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightAreaBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightAreaBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightAreaBack"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "面积", prop: "PostureRightArea" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightArea",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightArea"
                              }
                            }),
                            _vm._v("cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最大压力",
                              prop: "PostureRightMaxHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightMaxHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightMaxHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightMaxHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平均压力",
                              prop: "PostureRightAveHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightAveHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightAveHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightAveHeat"
                              }
                            }),
                            _vm._v("g/cm2 ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比前",
                              prop: "PostureRightHeatRatioFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.PostureRightHeatRatioFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightHeatRatioFront",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.PostureRightHeatRatioFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "压力比后",
                              prop: "PostureRightHeatRatioBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value:
                                  _vm.footPressure.PostureRightHeatRatioBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightHeatRatioBack",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.PostureRightHeatRatioBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "总压力比",
                              prop: "PostureRightHeatRatio"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightHeatRatio,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightHeatRatio",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightHeatRatio"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布前",
                              prop: "PostureRightCoverFront"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightCoverFront,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightCoverFront",
                                    $$v
                                  )
                                },
                                expression:
                                  "footPressure.PostureRightCoverFront"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分布后",
                              prop: "PostureRightCoverBack"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightCoverBack,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightCoverBack",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightCoverBack"
                              }
                            }),
                            _vm._v("% ")
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "重量", prop: "PostureRightWeight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.PostureRightWeight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "PostureRightWeight",
                                    $$v
                                  )
                                },
                                expression: "footPressure.PostureRightWeight"
                              }
                            }),
                            _vm._v("kg ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "20px" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("动态测试")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("左足")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "CPEI", prop: "DynamicLeftCPEI" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicLeftCPEI,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicLeftCPEI",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicLeftCPEI"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "面积", prop: "DynamicLeftArea" } },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicLeftArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicLeftArea",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicLeftArea"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最大压力",
                              prop: "DynamicLeftMaxHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicLeftMaxHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicLeftMaxHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicLeftMaxHeat"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平均压力",
                              prop: "DynamicLeftAveHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicLeftAveHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicLeftAveHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicLeftAveHeat"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("右足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "CPEI", prop: "DynamicRightCPEI" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicRightCPEI,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicRightCPEI",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicRightCPEI"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "面积", prop: "DynamicRightArea" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicRightArea,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicRightArea",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicRightArea"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最大压力",
                              prop: "DynamicRightMaxHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicRightMaxHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicRightMaxHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicRightMaxHeat"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平均压力",
                              prop: "DynamicRightAveHeat"
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DynamicRightAveHeat,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicRightAveHeat",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicRightAveHeat"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("足底扫描")])]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("左足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "足形", prop: "DataArchLeftType" }
                          },
                          [
                            _c("enumSelector", {
                              staticStyle: { width: "200px" },
                              attrs: { options: _vm.$config.zuxing },
                              model: {
                                value: _vm.footPressure.DataArchLeftType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataArchLeftType",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataArchLeftType"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "旋外度数:", prop: "DataEveLeft" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataEveLeft,
                                callback: function($$v) {
                                  _vm.$set(_vm.footPressure, "DataEveLeft", $$v)
                                },
                                expression: "footPressure.DataEveLeft"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "旋内度数:", prop: "DataInvLeft" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataInvLeft,
                                callback: function($$v) {
                                  _vm.$set(_vm.footPressure, "DataInvLeft", $$v)
                                },
                                expression: "footPressure.DataInvLeft"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "足踝", prop: "DynamicLeftShape" }
                          },
                          [
                            _c("enumSelector", {
                              staticStyle: { width: "200px" },
                              attrs: { options: _vm.$config.zuhuai },
                              model: {
                                value: _vm.footPressure.DynamicLeftShape,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicLeftShape",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicLeftShape"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "足长:", prop: "DataFootLeftSize" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataFootLeftSize,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataFootLeftSize",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataFootLeftSize"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "form-item-label" }, [
                          _vm._v("右足")
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "足形", prop: "DataArchRightType" }
                          },
                          [
                            _c("enumSelector", {
                              staticStyle: { width: "200px" },
                              attrs: { options: _vm.$config.zuxing },
                              model: {
                                value: _vm.footPressure.DataArchRightType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataArchRightType",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataArchRightType"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "旋外度数:", prop: "DataEveRight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataEveRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataEveRight",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataEveRight"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "旋内度数:", prop: "DataInvRight" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataInvRight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataInvRight",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataInvRight"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "足踝", prop: "DynamicRightShape" }
                          },
                          [
                            _c("enumSelector", {
                              staticStyle: { width: "200px" },
                              attrs: { options: _vm.$config.zuhuai },
                              model: {
                                value: _vm.footPressure.DynamicRightShape,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DynamicRightShape",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DynamicRightShape"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "足长:", prop: "DataFootRightSize" }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.footPressure.DataFootRightSize,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.footPressure,
                                    "DataFootRightSize",
                                    $$v
                                  )
                                },
                                expression: "footPressure.DataFootRightSize"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("评估结果")])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "得分", prop: "Score" } },
                    [_c("el-tag", [_vm._v(_vm._s(_vm.score))])],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "压力结果", prop: "HeatSummary" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入压力结果"
                        },
                        model: {
                          value: _vm.footPressure.HeatSummary,
                          callback: function($$v) {
                            _vm.$set(_vm.footPressure, "HeatSummary", $$v)
                          },
                          expression: "footPressure.HeatSummary"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "足形结果", prop: "DataArchSummary" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入足形结果"
                        },
                        model: {
                          value: _vm.footPressure.DataArchSummary,
                          callback: function($$v) {
                            _vm.$set(_vm.footPressure, "DataArchSummary", $$v)
                          },
                          expression: "footPressure.DataArchSummary"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "足踝结果", prop: "ShapeSummary" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入足踝结果"
                        },
                        model: {
                          value: _vm.footPressure.ShapeSummary,
                          callback: function($$v) {
                            _vm.$set(_vm.footPressure, "ShapeSummary", $$v)
                          },
                          expression: "footPressure.ShapeSummary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }