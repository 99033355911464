import { constantRouterMap } from '@/router'

const route = {
  state: {
    routers: [],
    addRouters: [],
    routeHistory:[]
  },
  mutations: {
    routers: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    routeHistory: (state, routeHistory) => {
      state.routeHistory = routeHistory
    }
  },
  actions: {
    addRouteHistory(e,route){
      e.state.routeHistory.push(route)
    },
    removeRouteHistory(e,route){
      e.state.routeHistory = e.state.routeHistory.filter(r=>r.name != route.name)
    },
    clearRouteHistory(e){
      e.state.routeHistory=[];
    },
  }
}

export default route
