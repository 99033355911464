<template>
  <div class="app-container">
    <div class="menu">
      <sidebar class="sidebar-container" :isCollapse='isCollapse'></sidebar>
    </div>
    <div class="route-view" :class="[isCollapse?'resize':'']">
      <navbar></navbar>
      <div class="page">
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <router-view v-if='keepAlive'></router-view>
          </keep-alive>
        </transition>
        <transition name="fade-transform" mode="out-in">
          <router-view v-if='!keepAlive'></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import sidebar from './sidebar/index'
import navbar from './navbar/index'
import { mapState } from "vuex";
export default {
  components: { sidebar, navbar },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      isCollapse: state => state.app.isCollapse,
    }),
    keepAlive () {
      console.log(this.$route)
      console.log(this.$route.meta.keepAlive)
      console.log(this.$route.meta.icon)
      return this.$route.meta.keepAlive
    }
  },
  methods: {
  }
}
</script>
<style lang='scss' scoped>
.app-container {
  .menu {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .route-view {
    width: 100%;
    padding-left: 220px;
    transition: padding-left 0.1s ease-in-out;
    box-sizing: border-box;
    .page {
      height: calc(100vh - 93px);
      overflow-y: auto;
      overflow-x: auto;
      padding: 6px 10px 10px 10px;
      box-sizing: border-box;
      width: 100%;
      background: #f6f6f6;
    }
  }
  .resize {
    padding-left: 64px;
    transition: padding-left 0.1s ease-in-out;
  }
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all 0.5s;
  }

  .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
  }

  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}
</style>
<style lang='scss'>
.user-dpd {
  border-radius: 2px !important;
  background: $menu-bg !important;
  border: 0 !important;
  .el-dropdown-menu__item {
    color: white;
  }
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: transparent !important;
  }
  .popper__arrow {
    display: none !important;
  }
}
</style>