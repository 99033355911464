import request from '@/utils/request'

export function getSingle(data) {
  return request({
    url: 'check/GetCheckByKey',
    method: 'post',
    data,
  })
}

export function getPageList(data) {
  return request({
    url: 'check/getPageList',
    method: 'post',
    data,
  })
}

export function getWaitAuditCount(data) {
  return request({
    url: 'check/getWaitAuditCount',
    method: 'post',
    data,
  })
}


export function invertFootPressureImages(data) {
  return request({
    url: 'check/invertFootPressureImages',
    method: 'post',
    data,
  })
}


export function updateCheckMemberBaseInfo(data) {
  return request({
    url: 'check/updateCheckMemberBaseInfo',
    method: 'post',
    data,
  })
}

export function saveCheck(data) {
  return request({
    url: 'check/save',
    method: 'post',
    data,
  })
}

export function deleteCheck(data) {
  return request({
    url: 'check/delete',
    method: 'post',
    data,
  })
}

export function updateCheckStatus(data) {
  return request({
    url: 'check/updateCheckStatus',
    method: 'post',
    data,
  })
}

export function saveCheckItem(data) {
  return request({
    url: 'check/saveCheckItem',
    method: 'post',
    data,
  })
}

export function loadFileData(data) {
  return request({
    url: 'check/loadFileData',
    method: 'post',
    data,
  })
}


export function getLocalCheck(data) {
  return request({
    url: 'check/getLocalCheck',
    method: 'post',
    data,
  })
}

export function getCheckAnswer(data) {
  return request({
    url: 'check/getCheckAnswer',
    method: 'post',
    data,
  })
}

export function generateReport(data) {
  return request({
    url: 'check/generateReport',
    method: 'post',
    data,
  })
}

export function pcAppoint(data) {
  return request({
    url: 'check/pcAppoint',
    method: 'post',
    data,
  })
}

export function saveCheckImage(data) {
  return request({
    url: 'check/saveCheckImage',
    method: 'post',
    data,
  })
}

export function setPrescription(data) {
  return request({
    url: 'check/setPrescription',
    method: 'post',
    data,
  })
}

export function setCheckImproves(data) {
  return request({
    url: 'check/setCheckImproves',
    method: 'post',
    data,
  })
}
