var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer" },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 5 } },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-tickets" }),
                _vm._v(" 编号 ")
              ]),
              _vm._v(_vm._s(_vm.checkInfo.Code) + " ")
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-user" }),
                _vm._v("受试人")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.checkInfo.Member.RealName))]),
              _c("span", [_vm._v("@" + _vm._s(_vm.checkInfo.Member.Mobile))])
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm.checkInfo.Member
                  ? _c("span", [
                      _vm.checkInfo.Member.Sex == "女"
                        ? _c("i", { staticClass: "el-icon-female" })
                        : _c("i", { staticClass: "el-icon-male" }, [
                            _vm._v("性别年龄")
                          ])
                    ])
                  : _vm._e()
              ]),
              _vm.checkInfo.Member
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.checkInfo.Member.Sex) +
                        "/ " +
                        _vm._s(
                          _vm.format.getAgeByDateString(
                            _vm.format.dateFormat(_vm.checkInfo.Member.Birthday)
                          )
                        ) +
                        "岁"
                    )
                  ])
                : _vm._e()
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v("BMI ")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.checkInfo.Member.Weight) + "kg")]),
              _vm._v("/ "),
              _vm.checkInfo.Member
                ? _c("span", [
                    _vm._v(_vm._s(_vm.checkInfo.Member.Height) + "cm")
                  ])
                : _vm._e(),
              _vm.checkInfo.Member
                ? _c("el-tag", { attrs: { type: "danger", size: "small" } }, [
                    _vm._v(_vm._s(_vm.checkInfo.Member.BMI.toFixed(2)))
                  ])
                : _vm._e()
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v("血压 ")
              ]),
              _c("div", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.checkInfo.Member.BloodPressureMin) +
                      "-" +
                      _vm._s(_vm.checkInfo.Member.BloodPressureMax)
                  )
                ])
              ])
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v("体脂率 ")
              ]),
              _vm.checkInfo.Member
                ? _c("div", [_vm._v(_vm._s(_vm.checkInfo.Member.FatRate))])
                : _vm._e()
            ],
            2
          ),
          _vm.checkInfo.CheckSelfHeart && _vm.checkInfo.CheckSelfHeart.Key
            ? _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-star-off" }),
                    _vm._v("心血管筛查")
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.showAnswerViewer("Heart")
                        }
                      }
                    },
                    [
                      _c(
                        "el-tag",
                        { attrs: { type: "warning", size: "small" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.checkInfo.CheckSelfHeart.AcceptableStatus
                            )
                          )
                        ]
                      ),
                      _c("span", { staticStyle: { color: "#3c5be6" } }, [
                        _vm._v("查看")
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.checkInfo.CheckSelfVAS && _vm.checkInfo.CheckSelfVAS.Key
            ? _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-star-off" }),
                    _vm._v("VAS ")
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.showAnswerViewer("VAS")
                        }
                      }
                    },
                    [
                      _c(
                        "el-tag",
                        { attrs: { type: "warning", size: "small" } },
                        [
                          _vm._v(
                            _vm._s(_vm.checkInfo.CheckSelfVAS.Count) + "处"
                          )
                        ]
                      ),
                      _c("span", { staticStyle: { color: "#3c5be6" } }, [
                        _vm._v("查看")
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.checkInfo.CheckSelfIKDC && _vm.checkInfo.CheckSelfIKDC.Key
            ? _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-star-off" }),
                    _vm._v("IKDC")
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.showAnswerViewer("IKDC")
                        }
                      }
                    },
                    [
                      _c(
                        "el-tag",
                        { attrs: { type: "warning", size: "small" } },
                        [_vm._v(_vm._s(_vm.checkInfo.CheckSelfIKDC.IKDCScore))]
                      ),
                      _c("span", { staticStyle: { color: "#3c5be6" } }, [
                        _vm._v("查看")
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v("鞋码")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.checkInfo.Member.ShoesSize))]),
              !_vm.readOnly
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "50px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.showMemberEditor }
                    },
                    [_vm._v("完善基本信息")]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("answerViewer", { ref: "answerViewer" }),
      _c("customerForm", {
        ref: "customerForm",
        attrs: { checkKey: _vm.checkInfo.Key }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }