var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "value-key": "key",
        clearable: _vm.clearable,
        multiple: _vm.multiple,
        disabled: _vm.disabled,
        size: _vm.size
      },
      on: { change: _vm.dataChange, clear: _vm.dataChange },
      model: {
        value: _vm.data,
        callback: function($$v) {
          _vm.data = $$v
        },
        expression: "data"
      }
    },
    _vm._l(_vm.options, function(item) {
      return _c("el-option", {
        key: item.key,
        attrs: { label: item.name, value: item.key }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }