<template>
  <div class="component-container">
    <el-card shadow="never">
      <el-form :inline="false" label-position="top">
        <div class="test">
          <div class="item" style="text-align: center">
            <img class="img" src="/images/vas.jpg" />
          </div>
        </div>
        <el-form-item>
          <div class="answer-title">左踝疼痛评分</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.AnkleLeftScore"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item>
          <div class="answer-title">右踝疼痛评分</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.AnkleRightScore"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item>
          <div class="answer-title">左膝疼痛评分</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.KneeLeftScore"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item>
          <div class="answer-title">右膝疼痛评分</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.KneeRightScore"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item>
          <div class="answer-title">左髋疼痛评分</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.HipLeftScore"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item>
          <div class="answer-title">右髋疼痛评分</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.HipRightScore"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never" v-if="!readOnly">
      <div class="footer">
        <el-button
          class="submit-btn"
          v-if="step"
          type="warning"
          @click="handlePreStep"
        >
          上一步
        </el-button>
        <el-button class="submit-btn" type="success" @click="handleSubmit">{{
          step ? "下一步" : "保存"
        }}</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getByKey, save } from "@/api/check/checkSelf.js";
export default {
  props: {
    memberKey: {
      default: "",
    },
    keyValue: {
      default: "",
    },
    readOnly: {
      default: false,
    },
    step: {
      default: false,
    },
  },
  watch: {
    keyValue(val) {
      this.getVAS();
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      dataForm: {
        Member: { Key: this.memberKey },
        HipLeftScore: 0,
        HipRightScore: 0,
        KneeLeftScore: 0,
        KneeRightScore: 0,
        AnkleLeftScore: 0,
        AnkleRightScore: 0,
      },
    };
  },
  methods: {
    getVAS() {
      if (this.keyValue) {
        let param = {
          key: this.keyValue,
          type: "VAS",
        };
        getByKey(param).then((res) => {
          if (res) {
            this.dataForm = res;
          }
        });
      } else {
        this.handleCreate();
      }
    },
    handleCreate() {
      this.dataForm = {
        Member: { Key: this.memberKey },
        HipLeftScore: 0,
        HipRightScore: 0,
        KneeLeftScore: 0,
        KneeRightScore: 0,
        AnkleLeftScore: 0,
        AnkleRightScore: 0,
      };
    },
    handleEdit(row) {
      this.dataForm = row;
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate();
      });
    },
    handleSubmit() {
      if (this.step) {
        this.$confirm("确认已填写完整，进行下一步操作吗？", "提示", {}).then(
          () => {
            this.$emit("next", this.dataForm);
          }
        );
      } else {
        save({ type: "VAS", data: this.dataForm }).then((res) => {
          if (res) {
            this.msg.successMsg("操作成功");
            this.$emit("update", this.dataForm);
          }
        });
      }
    },
    handlePreStep() {
      this.$emit("prev", this.dataForm);
    },
  },
  mounted() {
    this.getVAS();
  },
};
</script>
  <style lang="scss" scoped>
/deep/.el-slider__bar {
  background-color: rgb(0, 178, 106);
}
/deep/.el-slider__button {
  border-color: rgb(0, 178, 106);
}
/deep/.el-slider__runway.disabled .el-slider__bar {
  background-color: rgb(0, 178, 106);
}
/deep/.el-slider__runway.disabled .el-slider__button {
  background-color: rgb(0, 178, 106);
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: rgb(0, 178, 106);
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  background-color: rgb(0, 178, 106);
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(0, 178, 106);
}
/deep/.el-radio {
  line-height: 30px;
}
.component-container {
  width: 100%;
}
.answer-title {
  font-size: 20px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  line-height: 35px;
  .option-title {
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  .option-title {
  }
}
.test {
  border-top: 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  .item {
    width: 100%;
    .name {
      font-size: 20px;
      padding: 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      line-height: 35px;
      .no {
        flex-shrink: 0;
        margin-right: 5px;
      }
    }
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
      }
    }
  }
}
</style>