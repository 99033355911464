<template>
  <div class="menu-wrapper">
    <template v-for="item in routes" v-if="!item.hidden&&item.children">
      <!--如果是叶子菜单-->
      <div v-if="isLeafMenu(item)" :key="item.path" @click="goToPage(item)">
        <el-menu-item :index="item.path" :class="{'submenu-title-noDropdown':!isNest}">
          <i class="iconfont mgr10" :class="item.meta.icon"></i>
          <span v-if="item.meta&&item.meta.title" slot="title">{{item.meta.title}}</span>
        </el-menu-item>
      </div>
      <!--如果是叶子菜单-->
      <template v-else-if="isDesktopGroup(item)" v-for="topItem in item.children">
        <div :key="topItem.path" @click="goToPage(topItem)">
          <el-menu-item :index="topItem.path" :class="{'submenu-title-noDropdown':!isNest}">
            <i class="iconfont mgr10" :class="topItem.meta.icon"></i>
            <span v-if="topItem.meta&&topItem.meta.title" slot="title">{{topItem.meta.title}}</span>
          </el-menu-item>
        </div>
      </template>
      <!--创建子菜单组-->
      <el-submenu v-else :index="item.name||item.path" :key="item.name">
        <template slot="title">
          <i class="iconfont mgr10" :class="item.meta.icon"></i>
          <span v-if="item.meta&&item.meta.title" slot="title">{{item.meta.title}}</span>
        </template>
        <template v-for="child in item.children">

          <sidebar-item :is-nest="true" class="nest-menu" v-if="child.children&&child.children.length>0" :routes="[child]" :key="child.path">
          </sidebar-item>

          <!-- <router-link v-else :to="item.path+'/'+child.path" :key="child.name"> -->
          <div v-else :key="child.path" @click="goToPage(child,item)">
            <el-menu-item :index="item.path+'/'+child.path">
              <i class="iconfont mgr10" :class="child.meta.icon"></i>
              <span v-if="child.meta&&child.meta.title">{{child.meta.title}}</span>
            </el-menu-item>
          </div>

        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>

export default {
  name: 'sidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isDesktopGroup (item) {
      if (item.path == "desktop") {
        return true;
      }
      return false;
    },
    isLeafMenu (item) {
      if (item.children && item.children.length > 0)
        return false;
      return true;
    },
    goToPage (route, item) {
      route.meta['keepAlive'] = false
      setTimeout(() => {
        if (item) {
          this.$router.push({ path: item.path + '/' + route.path });
        } else {
          this.$router.push({ path: route.path });
        }
      }, 10);
    }
  }
}
</script>

<style>
a {
  text-decoration: none !important;
}
.icon-font {
  font-size: 18px;
  font-weight: 400;
  margin-right: 5px;
}
.icon-fontCare {
  font-size: 18px;
  font-weight: 400;
  margin-right: 5px;
  font-weight: bold;
  color: #ee7031;
}
.mgr10 {
  margin-right: 10px;
}
.mgr5 {
  margin-right: 5px;
}
</style>



