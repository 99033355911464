function notNegativeZeroNumber(rule, value, callback) {
    if (value <= 0) {
        callback(new Error("金额不能为0和负数"));
    } else {
        callback();
    }
}

function mobileValidator(rule, value, callback){
    if (value === "" || !value) {
      callback();
    } else {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      if (!reg.test(value)) {
        callback(new Error("手机号码格式不符合规范"));
      }
    }
    callback();
  };
function emailValidator(rule, value, callback) {
    if (value === "" || !value) {
      callback();
    } else {
      let temp = /^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/;
      //let tempOne = /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/;
      if (value && !temp.test(value)) {
        callback(new Error("邮箱格式不符合规范"));
      }
    }
    callback();
  };

export default
{
   notNegativeZeroNumber,
   mobileValidator,
   emailValidator,
}
