export default {
  namespaced: true,
  state: {
    isCollapse:false,
    showLogo : true,
  },
  actions: {
    
  },
  mutations: {
    isCollapse (state, isCollapse) {
      state.isCollapse = isCollapse
    },
    showLogo (state, showLogo) {
      state.showLogo = showLogo
    }
  }
}
