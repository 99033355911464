<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  watch: {},
};
</script>
<style lang='scss'>
#app {
  overflow-x: hidden;
  height: 100%;
}
/deep/.el-drawer__header {
  margin-bottom: 5px;
  font-size: 16px;
}
:deep(.el-drawer__header) {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f !important;
}
:deep(.el-drawer__body) {
  overflow-y: scroll !important;
}
:deep(.el-card__header) {
  font-size: 16px;
}
.el-card__body {
  padding-bottom: 0px;
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
}
::-webkit-scrollbar-track {
  background-color: #f6f6f6;
  box-shadow: none;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
}
::-webkit-scrollbar-button {
  display: block;
  background-color: #f6f6f6;
  width: 0px;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(87, 193, 255, 0.5);
  border-radius: 3px;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
