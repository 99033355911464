<template>
  <span style="margin-left: 10px; margin-right: 10px" v-if="readonly">{{
    name
  }}</span>
  <el-select
    v-else
    v-model="data"
    @change="dataChange"
    value-key="key"
    :size="size"
    :clearable="clearable"
    @clear="dataChange"
    style="width: 100%"
    :disabled="disabled"
  >
    <el-option
      v-for="item in options"
      :key="item.key"
      :label="item.name"
      :value="item.value"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default: "",
    },
    options: {
      default: [],
    },
    clearable: {
      default: true,
    },
    size: {
      default: "small",
    },
    disabled: {
      default: false,
    },
    readonly: {
      default: false,
    },
  },
  data() {
    return {
      data: "",
      name: "",
    };
  },
  watch: {
    value: {
      handler(v) {
        this.data = v;
        let findObj = this.options.find(
          (r) => r.key == this.data || r.value == this.data
        );
        if (findObj) {
          this.name = findObj.name;
        } else {
          this.name = "";
        }
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler(v) {},
      deep: true,
      immediate: true,
    },
  },
  methods: {
    dataChange(v) {
      this.$emit("change", v);
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>