<template>
    <div>
      <attchmentEditor
        :attachments="heartEcg.Attachments"
        bussiness="HeartEcg"
        @uploaded="uploadSuccess"
        :readOnly="disable"
        desc="请上心电图负荷试验报告"
      ></attchmentEditor>
      <el-form
        :model="heartEcg"
        :rules="heartEcgRules"
        ref="HeartEcg"
        label-width="200px"
        :disabled="disable"
        label-position="left"
      >
        <div class="row">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>心电图负荷试验</span>
            </div>
            <div class="box-card-body">
              <div class="form">
                <div class="form-row">
                  <div class="form-item">
                    <el-form-item label="最大负荷时间" prop="MaxLoadTime">
                      <el-input
                        v-model="heartEcg.MaxLoadTime"
                        size="small"
                        style="width:80%"
                      ></el-input
                      >
                    </el-form-item>
                    <el-form-item label="最大工作级别" prop="MaxMet">
                      <el-input
                        v-model="heartEcg.MaxMet"
                        size="small"
                        style="width:80%"
                      ></el-input
                      >L
                    </el-form-item>
                    <el-form-item label="静息心率" prop="StaticHeartRate">
                      <el-input-number
                        v-model="heartEcg.StaticHeartRate"
                        size="small"
                        style="width:80%"
                      ></el-input-number
                      >b/min
                    </el-form-item>
                    <el-form-item label="静息心率升至最大心率" prop="StaticToMaxHeartRate">
                      <el-input-number
                        v-model="heartEcg.StaticToMaxHeartRate"
                        size="small"
                        style="width:80%"
                      ></el-input-number
                      >b/min
                    </el-form-item>
                    <el-form-item label="静息血压" prop="StaticMaxBlood">
                      <el-input
                        v-model="heartEcg.StaticMaxBlood"
                        size="small"
                        style="width:80%"
                      ></el-input
                      >mhg
                    </el-form-item>
                    <el-form-item
                      label="最大血压"
                      prop="MaxBlood"
                    >
                      <el-input
                        v-model="heartEcg.MaxBlood"
                        size="small"
                        style="width:80%"
                      ></el-input
                      >mhg
                    </el-form-item>
                    <el-form-item
                      label="结果"
                      prop="Result"
                    >
                      <el-input
                        v-model="heartEcg.Result"
                        size="small"
                        style="width:80%"
                      ></el-input
                      >
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </el-form>
    </div>
  </template>
  <script>
  import { loadFileData } from "@/api/check/check.js";
  import attchmentEditor from "@/components/attchmentEditor.vue";
  import enumSelector from "@/components/enumSelector.vue";
  export default {
    model: {
      prop: "value",
      event: "change",
    },
    props: {
      checkKey: {
        default: "",
      },
      disable: {
        default: false,
      },
      value: {
        default: {},
      },
    },
    watch: {
      value: {
        handler(v) {
          this.heartEcg = v;
          this.ensureAttachments();
        },
        deep: true,
        immediate: true,
      },
    },
    components: { attchmentEditor, enumSelector },
    computed: {
    },
    data() {
      return {
        checkType: "HeartEcg",
        //膝关节损伤
        heartEcg: {},
        heartEcgRules: {
            MaxLoadTime: { required: true, message: "请输入", trigger: "null" },
            MaxMet: { required: true, message: "请输入", trigger: "null" },
            StaticHeartRate: { required: true, message: "请输入", trigger: "null" },
            StaticToMaxHeartRate: {
            required: true,
            message: "请输入",
            trigger: "null",
          },
          AgeToMaxHeartRate: { required: true, message: "请输入", trigger: "null" },
          MaxBlood: { required: true, message: "请输入", trigger: "null" },
          StaticMaxBlood: { required: true, message: "请输入", trigger: "null" },
          Result: {
            required: true,
            message: "请输入",
            trigger: "null",
          }
        },
      };
    },
    methods: {
      uploadSuccess(val) {
        if (
          val.file.Type == "HeartEcgReport"
        ) {
          let param = {
            checkKey: this.checkKey,
            checkType: val.bussiness,
            attachType: val.file.Type,
            dataFile: val.file.Path,
            checkItem: this.heartEcg,
          };
          loadFileData(param).then((res) => {
            if (res) {
              this.heartEcg = res;
            }
          });
        }
      },
      //确保有附件集合对象;
      ensureAttachments() {
        if (!this.heartEcg.Attachments) {
          this.$set(this.heartEcg, "Attachments", {
            ItemType: "XTH.Attachment",
            Items: [],
          });
        }
      },
      doValidate() {
        this.$refs[this.checkType].validate((valid) => {
          if (valid) {
            this.$emit("passValidate", this.heartEcg);
          } else {
            setTimeout(() => {
              this.$refs[this.checkType].clearValidate();
            }, 3000);
          }
        });
      },
    },
  };
  </script>
    <style lang="scss" scoped>
  /deep/.el-form {
    margin-top: 15px;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/.el-form-item__error {
    padding-top: 0 !important;
  }
  /deep/.is-disabled {
    .el-input__inner {
      color: #333 !important;
    }
  }
  .done {
    color: #008000;
    font-size: 20px;
    font-weight: bolder;
  }
  .wait {
    color: #ffa500;
  }
  .component-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        padding-right: 50px;
        .form-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .form-item-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
          }
          .form-item-label {
            text-align: center;
            padding: 10px 0;
            font-size: 14px;
            width: 100%;
            font-weight: bolder;
          }
        }
      }
    }
  
    .input200 {
      width: 200px;
    }
    .btns {
      padding: 10px;
      width: 100%;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 9999;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    /deep/.el-card {
      flex: 1;
      margin-right: 10px;
      height: inherit !important;
      .el-card__body {
        // text-align: center;
        .box-card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &:last-child {
      padding-bottom: 60px;
    }
  }
  </style>