<template>
  <div>
    <section>
      <div v-if="changePwdFormVisible">
      <el-dialog v-dragDialog title="修改密码" :visible.sync="changePwdFormVisible" width=500px :close-on-click-modal="false">
        <el-form :model="changePwdForm" ref="dataForm" label-width="130px" :rules="addFormRules">
          <el-form-item label="登录名：" prop="Name">
            <el-col :span="19">
               <el-input v-model="changePwdForm.Name" readonly="readonly"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="新密码：" prop="NewPwd1">
            <el-col :span="19">
               <el-input v-model="changePwdForm.NewPwd1" type="password" auto-complete="off"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="确认新密码：" prop="NewPwd2">
            <el-col :span="19">
               <el-input v-model="changePwdForm.NewPwd2" type="password" auto-complete="off"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changePwdFormVisible = false">取消</el-button>
          <el-button type="primary" @click="saveNewPwd">提交</el-button>
        </div>
      </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
  import {
    changePassword
  } from "@/api/setting/member"
  export default {
    // props: ['UserName'],
    data() {
      const _this = this
      const pwdValidator1 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else if (_this.changePwdForm.NewPwd2 != "") {
          this.$refs.dataForm.validateField("NewPwd2");
          callback();
        } else {
          callback();
        }
      }
      let pwdValidator2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== _this.changePwdForm.NewPwd1) {
          callback(new Error("两次输入密码不一致"));
        } else {
          callback();
        }
      };
      return {
        changeLoading: false,
        changePwdFormVisible: false,
        changePwdForm: {
          Name: "",
          NewPwd1: "",
          NewPwd2: ""
        },
        addFormRules: {
          NewPwd1: [{
            validator: pwdValidator1,
            trigger: "change"
          }],
          NewPwd2: [{
            validator: pwdValidator2,
            trigger: "change"
          }]
        }
      }
    },
    methods: {
      showForm: function(UserName) {
        this.changePwdForm.Name = UserName
        this.changePwdFormVisible = true
        this.$nextTick(() => {
           this.$refs['dataForm'].clearValidate()
        })
      },
      saveNewPwd: function() {
        var _this = this;
        this.$refs.dataForm.validate(valid => {
          if (valid) {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
              _this.changeLoading = true;
              let para = {
                userName: this.changePwdForm.Name,
                password: this.changePwdForm.NewPwd1
              };
              changePassword(para).then(response => {
                _this.changeLoading = false
                if (response) {
                  this.msg.successMsg("操作成功")
                  this.changePwdFormVisible = false
                }
              });
            });
          }
        });
      }
    }
  };
</script>
<style>
.el-dialog {
  margin-top: 10px !important;
}
</style>
