import { Message,	Notification,	MessageBox} from 'element-ui'

export default class message {
  successNotify(content, duration){
    let d = 0
    if (duration) {
      d = duration
    }
    Notification({
      title: '成功',
      message: content,
      type: 'success',
      showClose: true,
      duration: d
    })
  }
  warningNotify(content, duration){
    let d = 0
    if (duration) {
      d = duration
    }
    Notification({
      title: '警告',
      message: content,
      type: 'warning',
      showClose: true,
      duration: d
    })
  }
  infoNotify(content, duration){
    let d = 0
    if (duration) {
      d = duration
    }
    Notification({
      title: '提示',
      message: content,
      type: 'info',
      showClose: true,
      duration: d
    })
  }
  errorNotify(content, duration){
    let d = 0
    if (duration) {
      d = duration
    }
    Notification({
      title: '错误',
      message: content,
      type: 'error',
      showClose: true,
      duration: d
    })
  }
  successMsg(content){
      Message({
      showClose: true,
      message: content,
      type: 'success'
    })
  }
  errorMsg(content){
      Message({
      showClose: true,
      message: content,
      type: 'error'
    })
  }
  warningMsg(content){
      Message({
      showClose: true,
      message: content,
      type: 'warning'
    })
  }
  confirm(content, type, thenCallback, cancelCallback) {
    MessageBox.confirm(content, '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: type
    }).then(thenCallback).catch(cancelCallback ? cancelCallback : () => {})
  }
}
  


