<template>
  <div class="component-container">
    <el-card shadow="never">
      <div slot="header">
        <span
          >IKDC国际膝关节文献委员会膝关节评估表（the international knee
          documentation committee knee evaluation
          from）是目前国际上公认的对于膝关节韧带损伤特别是前交叉韧带损伤、缺损的评估量表,有着比较高的可靠性、有效性和敏感性。</span
        >
      </div>
      <el-form
        :model="dataForm"
        ref="dataForm"
        :inline="false"
        label-position="top"
      >
        <el-form-item prop="dataForm.Answer1">
          <div class="answer-title">
            1.如果膝关节没有显著的疼痛，您认为您能达到的最好活动水平是?
          </div>
          <el-radio-group v-model="dataForm.Answer1">
            <el-radio :label="5"
              >非常剧烈的运动，如篮球、足球运动中的跳跃、旋转等</el-radio
            >
            <br />
            <el-radio :label="4"
              >剧烈运动，如重体力劳动，滑雪，乒乓球，网球</el-radio
            >
            <br />
            <el-radio :label="3"
              >中等程度活动，如中度体力劳动，跑步，慢跑</el-radio
            >
            <br />
            <el-radio :label="2"
              >轻体力活动，如散步，家务劳动，或庭院劳动</el-radio
            >
            <br />
            <el-radio :label="1">由于膝关节的疼痛，以上活动都不能进行</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="dataForm.Answer2">
          <div class="answer-title">
            2.在过去的4周里，或从您受伤开始（受伤至今小于4周)，疼痛的频率有多少?
          </div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">持续疼痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.Answer2"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item prop="dataForm.Answer3">
          <div class="answer-title">3.如果有疼痛，疼痛的程度有多重？</div>
          <div class="options">
            <div class="option-title">无痛</div>
            <div class="option-title">想象中最严重的痛</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.Answer3"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item prop="dataForm.Answer4">
          <div class="answer-title">
            4.在过去的4周里,或从您受伤开始(如果从受伤至今小于4周),膝关节僵硬或肿胀程度如何?
          </div>
          <el-radio-group v-model="dataForm.Answer4">
            <el-radio :label="5">完全没有僵硬或肿胀</el-radio><br />
            <el-radio :label="4">轻度僵硬或肿胀</el-radio><br />
            <el-radio :label="3">中度僵硬或肿胀</el-radio><br />
            <el-radio :label="2">重度僵硬或肿胀</el-radio><br />
            <el-radio :label="1">极重度僵硬或肿胀</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="dataForm.Answer5">
          <div class="answer-title">
            5.如果膝关节没有显著肿胀，您能达到的最好活动水平是?
          </div>
          <el-radio-group v-model="dataForm.Answer5">
            <el-radio :label="5"
              >非常剧烈的运动，如篮球、足球运动中的跳跃，旋转等</el-radio
            ><br />
            <el-radio :label="4"
              >剧烈运动，如重体力劳动,滑雪，乒乓球，网球</el-radio
            ><br />
            <el-radio :label="3"
              >中等程度活动，如中度体力活动,跑步，慢跑</el-radio
            ><br />
            <el-radio :label="2"
              >轻体力活动，如散步，家务劳动，或庭院劳动</el-radio
            ><br />
            <el-radio :label="1">由于膝关节的疼痛，以上活动都不能进行</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="dataForm.Answer6">
          <div class="answer-title">
            6.在过去的4周里，或从您受伤开始（如果从受伤至今小于周)，膝关节有过交锁现象（指膝关节在进行屈伸活动时会出现卡顿、卡压、或者有明显活动受限的表现）吗?
          </div>
          <el-radio-group v-model="dataForm.Answer6">
            <el-radio :label="1">有</el-radio><br />
            <el-radio :label="5">无</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="dataForm.Answer7">
          <div class="answer-title">
            7.如果没有膝关节打软腿（走路时膝盖发软，不受控制要摔倒）的现象，您能达到的最好活动水平是?
          </div>
          <el-radio-group v-model="dataForm.Answer7">
            <el-radio :label="5"
              >非常剧烈的运动，如篮球、足球运动中的跳跃，旋转等</el-radio
            ><br />
            <el-radio :label="4"
              >剧烈运动，如重体力劳动，滑雪，乒乓球，网球</el-radio
            ><br />
            <el-radio :label="3"
              >中等程度活动，如中度体力活动，跑步，慢跑</el-radio
            ><br />
            <el-radio :label="2"
              >轻体力活动，如散步，家务劳动，或庭院劳动</el-radio
            ><br />
            <el-radio :label="1">由于膝关节的疼痛，以上活动都不能进行</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="dataForm.Answer8">
          <div class="answer-title">
            8.一般情况下，您最好可以参加哪个水平的运动?
          </div>
          <el-radio-group v-model="dataForm.Answer8">
            <el-radio :label="5"
              >非常剧烈的运动，如篮球、足球运动中的跳跃，旋转等</el-radio
            ><br />
            <el-radio :label="4"
              >剧烈运动，如重体力劳动，滑雪，乒乓球，网球</el-radio
            ><br />
            <el-radio :label="3"
              >中等程度活动，如中度体力活动，跑步，慢跑</el-radio
            ><br />
            <el-radio :label="2"
              >轻体力活动，如散步，家务劳动，或庭院劳动</el-radio
            ><br />
            <el-radio :label="1">由于膝关节的疼痛，以上活动都不能进行</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div class="answer-title">
            9.膝关节的问题对您的日常生活有影响吗？ 如果有，影响程度如何？
          </div>
          <el-form-item prop="dataForm.Answer9_1">
            <div class="option-title">上楼</div>
            <el-radio-group v-model="dataForm.Answer9_1">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_2">
            <div class="option-title">下楼</div>
            <el-radio-group v-model="dataForm.Answer9_2">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_3">
            <div class="option-title">直跪</div>
            <el-radio-group v-model="dataForm.Answer9_3">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_4">
            <div class="option-title">下蹲</div>
            <el-radio-group v-model="dataForm.Answer9_4">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_5">
            <div class="option-title">膝关节弯曲坐下</div>
            <el-radio-group v-model="dataForm.Answer9_5">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_6">
            <div class="option-title">从椅子上站起</div>
            <el-radio-group v-model="dataForm.Answer9_6">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_7">
            <div class="option-title">向前奔跑</div>
            <el-radio-group v-model="dataForm.Answer9_7">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_8">
            <div class="option-title">用伤腿跳起并落地</div>
            <el-radio-group v-model="dataForm.Answer9_8">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="dataForm.Answer9_9">
            <div class="option-title">迅速停止并开始</div>
            <el-radio-group v-model="dataForm.Answer9_9">
              <el-radio :label="4">无影响</el-radio>
              <el-radio :label="3">轻度影响</el-radio>
              <el-radio :label="2">中度影响</el-radio>
              <el-radio :label="1">重度影响</el-radio>
              <el-radio :label="0">不能进行</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form-item>
        <el-form-item prop="dataForm.Answer10">
          <div class="answer-title">
            10.用0到10的等级来评价您的膝关节的功能，0代表不能进行一般的日常活动，10代表正常的功能，受伤前膝关节的功能？
          </div>
          <el-slider
            show-stops
            v-model="dataForm.Answer10"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
        <el-form-item prop="dataForm.Answer11">
          <div class="answer-title">
            11.用0到10的等级来评价您的膝关节的功能，0代表不能进行一般的日常活动，10代表正常的功能，目前膝关节的功能？
          </div>
          <div class="options">
            <div class="option-title">不能进行日常活动</div>
            <div class="option-title">日常活动不受限制</div>
          </div>
          <el-slider
            show-stops
            v-model="dataForm.Answer11"
            :min="0"
            :max="10"
          ></el-slider>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never" v-if="!readOnly">
      <div class="footer">
        <el-button
          class="submit-btn"
          v-if="step"
          type="warning"
          @click="handlePreStep"
        >
          上一步
        </el-button>
        <el-button class="submit-btn" type="success" @click="handleSubmit">{{
          step ? "下一步" : "保存"
        }}</el-button>
      </div>
    </el-card>
  </div>
</template>
  <script>
import { getByKey, save } from "@/api/check/checkSelf.js";
export default {
  props: {
    memberKey: {
      default: "",
    },
    keyValue: {
      default: "",
    },
    readOnly: {
      default: false,
    },
    step: {
      default: false,
    },
  },
  watch: {
    keyValue(val) {
      this.getIKDC();
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      dataForm: {
        Member: { Key: "" },
        Answer1: 4,
        Answer2: 0,
        Answer3: 0,
        Answer4: 5,
        Answer5: 4,
        Answer6: 5,
        Answer7: 4,
        Answer8: 4,
        Answer9_1: 4,
        Answer9_2: 4,
        Answer9_3: 4,
        Answer9_4: 4,
        Answer9_5: 4,
        Answer9_6: 4,
        Answer9_7: 4,
        Answer9_8: 4,
        Answer9_9: 4,
        Answer10: 10,
        Answer11: 10,
      },
    };
  },
  methods: {
    getIKDC() {
      if (this.keyValue) {
        let param = {
          key: this.keyValue,
          type: "IKDC",
        };
        getByKey(param).then((res) => {
          if (res) {
            this.dataForm = res;
          }
        });
      } else {
        this.handleCreate();
      }
    },
    handleCreate() {
      this.dataForm = {
        Member: { Key: this.memberKey },
        Answer1: 4,
        Answer2: 0,
        Answer3: 0,
        Answer4: 5,
        Answer5: 4,
        Answer6: 5,
        Answer7: 4,
        Answer8: 4,
        Answer9_1: 4,
        Answer9_2: 4,
        Answer9_3: 4,
        Answer9_4: 4,
        Answer9_5: 4,
        Answer9_6: 4,
        Answer9_7: 4,
        Answer9_8: 4,
        Answer9_9: 4,
        Answer10: 10,
        Answer11: 10,
      };
    },
    handleEdit(row) {
      this.dataForm = row;
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate();
      });
    },
    handleSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.step) {
            this.$confirm(
              "确认11题已填写完整，进行下一步操作吗？",
              "提示",
              {}
            ).then(() => {
              this.$emit("next", this.dataForm);
            });
          } else {
            save({ type: "IKDC", data: this.dataForm }).then((res) => {
              if (res) {
                this.msg.successMsg("操作成功");
                this.$emit("update", this.dataForm);
              }
            });
          }
        } else {
          setTimeout(() => {
            this.$refs.dataForm.clearValidate();
          }, 3000);
        }
      });
    },
    handlePreStep() {
      this.$emit("prev", this.dataForm);
    },
  },
  mounted() {
    this.getIKDC();
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-slider__bar {
  background-color: rgb(0, 178, 106);
}
/deep/.el-slider__button {
  border-color: rgb(0, 178, 106);
}
/deep/.el-slider__runway.disabled .el-slider__bar {
  background-color: rgb(0, 178, 106);
}
/deep/.el-slider__runway.disabled .el-slider__button {
  background-color: rgb(0, 178, 106);
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: rgb(0, 178, 106);
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  background-color: rgb(0, 178, 106);
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(0, 178, 106);
}
/deep/.el-radio {
  line-height: 30px;
}
.component-container {
  width: 100%;
}
.answer-title {
  font-size: 20px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  line-height: 35px;
  .option-title {
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  .option-title {
  }
}
</style>