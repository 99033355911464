var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { attrs: { shadow: "never" } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        !_vm.readOnly
          ? _c("el-tag", { attrs: { type: "warning" } }, [
              _vm._v(_vm._s(_vm.desc))
            ])
          : _vm._e(),
        _c("div", { staticStyle: { float: "right" } }, [
          !_vm.readOnly
            ? _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("el-tag", [_vm._v("附件类型")]),
                  _c("enumSelector", {
                    ref: "enumSelector",
                    attrs: {
                      options: _vm.options,
                      clearable: false,
                      size: "small"
                    },
                    model: {
                      value: _vm.attachmentType,
                      callback: function($$v) {
                        _vm.attachmentType = $$v
                      },
                      expression: "attachmentType"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "submit-btn",
                      attrs: { type: "warning", size: "mini" },
                      on: { click: _vm.selectFile }
                    },
                    [_vm._v("点此上传")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "files", staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-table",
          {
            attrs: {
              size: "small",
              data: _vm.attachments.Items,
              "highlight-current-row": "",
              "expand-on-click-node": "false",
              "show-header": false
            }
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", width: "50", sortable: "" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "Name",
                label: "文件名称",
                "min-width": "150",
                sortable: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(scope.row.Name))
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "Name",
                label: "文件类别",
                "min-width": "120",
                sortable: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.format.attachmentTypeFormat(scope.row.Type)
                          )
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "Url", label: "查看", width: "200", sortable: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.format.estimateIsImage(scope.row.Name)
                        ? _c("viewer", [
                            scope.row.Path
                              ? _c("img", {
                                  key: scope.row.Path,
                                  staticStyle: { height: "40px" },
                                  attrs: { src: _vm.baseUrl + scope.row.Path }
                                })
                              : _vm._e()
                          ])
                        : _c("span", { staticStyle: { "font-size": "14px" } }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.baseUrl + scope.row.Path,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(" 点此下载")]
                            )
                          ])
                    ]
                  }
                }
              ])
            }),
            !_vm.readOnly
              ? _c("el-table-column", {
                  attrs: {
                    prop: "Url",
                    label: "操作",
                    width: "100",
                    sortable: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "submit-btn",
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRemoveFile(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    891155176
                  )
                })
              : _vm._e()
          ],
          1
        ),
        _c("fileUploadNoUI", {
          ref: "fileUploadNoUI",
          on: { uploadSuccess: _vm.uploadSuccess }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }