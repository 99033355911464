import Vue from 'vue'
import App from './App.vue'
import store from './store'
//element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//进度条
import NProgress from 'nprogress' // 进度条；
import 'nprogress/nprogress.css' // 进度条样式
import '@/css/layout.scss'
import '@/js/lib-flexible'
NProgress.configure({
  showSpinner: false,
}) // 进度配置;

//拖动弹框
import elDragDialog from './js/directive/el-dragDialog'
Vue.directive('dragDialog', elDragDialog)

//图片查看
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

//echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  },
})

Vue.prototype.baseUrl = process.env.VUE_APP_BASE_API
Vue.prototype.store = store

import { getUserPageInfo } from '@/api/setting/member'
import { filterAsyncRouter } from '@/utils/asyncRoute'

const whiteList = ['/login', '/authredirect'] // 白名单，可直接访问;

import router from './router'
router.beforeEach((to, from, next) => {
  NProgress.start() // 开始显示进度条
  var token = localStorage.getItem('X-Token')
  /** ********************/
  if (token) {
    if (to.path === '/login') {
      next({
        path: '/',
      })
      NProgress.done() // 结束进度条
    } else {
      if (store.state.route.routers.length <= 0) {
        let baseRouters = localStorage.getItem('X-routes')
        getUserPageInfo().then((r) => {
          let base_router = JSON.stringify(r)
          const filterAsyncRouters = filterAsyncRouter(JSON.parse(base_router))
          store.commit('routers', filterAsyncRouters)
          localStorage.setItem('X-routes', JSON.stringify(base_router))
          router.addRoutes(filterAsyncRouters)
        })
        next()
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next('/login') // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})
router.afterEach(() => {
  NProgress.done() // finish progress bar
})

//配置及格式化
import message from '@/utils/message'
import config from '@/utils/config.js'
import format from '@/utils/format'
import validator from '@/utils/validator'

let msg = new message()
Vue.prototype.msg = msg
Vue.prototype.$config = config
Vue.prototype.format = format
Vue.prototype.validator = validator

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
