<template>
  <el-dialog
    v-dragDialog
    title="预约问卷自测详情"
    :visible="formVisible"
    width="1100px"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="close"
  >
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="心血管风险筛查" name="Heart" v-if="heartKey">
        <heartEditor
          ref="heartEditor"
          :memberKey="memberKey"
          :keyValue="heartKey"
          :readOnly="true"
        />
      </el-tab-pane>
      <el-tab-pane label="IKDC" name="IKDC" v-if="ikdcKey">
        <ikdceditor
          ref="ikdceditor"
          :memberKey="memberKey"
          :keyValue="ikdcKey"
          :readOnly="true"
        />
      </el-tab-pane>
      <el-tab-pane label="VAS" name="VAS" v-if="vasKey">
        <vasEditor
          ref="vasEditor"
          :memberKey="memberKey"
          :keyValue="vasKey"
          :readOnly="true"
        />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import ikdceditor from "./ikdceditor.vue";
import vasEditor from "./vasEditor.vue";
import heartEditor from "./heartEditor.vue";
export default {
  components: { ikdceditor, vasEditor, heartEditor },
  data() {
    return {
      formVisible: false,
      activeTab: "",
      member: "",
      memberKey: "",
      ikdcKey: "",
      vasKey: "",
      heartKey: "",
    };
  },
  methods: {
    showByKey(heartKey, ikdcKey, vasKey, tab) {
      debugger;
      if (tab) {
        this.activeTab = tab;
      } else {
        this.activeTab = "Heart";
      }
      this.ikdcKey = ikdcKey;
      this.heartKey = heartKey;
      this.vasKey = vasKey;
      this.formVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.heartEditor.getHeart();
      //   this.$refs.ikdceditor.getIKDC();
      //   this.$refs.vasEditor.getVAS();
      // });
    },
    show(member, tab) {
      this.member = member;
      this.memberKey = member.Key;
      if (tab) {
        this.activeTab = tab;
      } else {
        this.activeTab = "Heart";
      }
  
      if (member.CheckSelfHeart && member.CheckSelfHeart.Key) {
        this.heartKey = member.CheckSelfHeart.Key;
        console.log(this.heartKey);
      }
      if (member.CheckSelfIKDC && member.CheckSelfIKDC.Key) {
        this.ikdcKey = member.CheckSelfIKDC.Key;
      }
      if (member.CheckSelfVAS && member.CheckSelfVAS.Key) {
        this.vasKey = member.CheckSelfVAS.Key;
      }
      this.formVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.heartEditor.getHeart();
      //   this.$refs.ikdceditor.getIKDC();
      //   this.$refs.vasEditor.getVAS();
      // });
    },
    close() {
      this.formVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px 20px 20px;
}
</style>