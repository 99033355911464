import { render, staticRenderFns } from "./fileUploadNoUI.vue?vue&type=template&id=632c753c&scoped=true&"
import script from "./fileUploadNoUI.vue?vue&type=script&lang=js&"
export * from "./fileUploadNoUI.vue?vue&type=script&lang=js&"
import style0 from "./fileUploadNoUI.vue?vue&type=style&index=0&id=632c753c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632c753c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\source\\200xth\\xth2.web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('632c753c')) {
      api.createRecord('632c753c', component.options)
    } else {
      api.reload('632c753c', component.options)
    }
    module.hot.accept("./fileUploadNoUI.vue?vue&type=template&id=632c753c&scoped=true&", function () {
      api.rerender('632c753c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/fileUploadNoUI.vue"
export default component.exports