<template>
  <div>
    <attchmentEditor
      :attachments="runPosture.Attachments"
      bussiness="RunPosture"
      @uploaded="uploadSuccess"
      :readOnly="disable"
    ></attchmentEditor>
    <el-form
      :model="runPosture"
      ref="RunPosture"
      :rules="runPostureRules"
      label-width="120px"
      :disabled="disable"
    >
      <div class="row">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>步态参数</span>
          </div>
          <div class="form">
            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">基础指标</div>
                <el-form-item label="配速:" prop="Speed">
                  <el-input-number
                    v-model="runPosture.Speed"
                    size="small"
                  ></el-input-number
                  >千米/小时(km/h)
                </el-form-item>
                <el-form-item label="弹性能量交换" prop="ElasticExchange">
                  <el-input-number
                    v-model="runPosture.ElasticExchange"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="步频" prop="Striderate">
                  <el-input-number
                    v-model="runPosture.Striderate"
                    size="small"
                  ></el-input-number
                  >次/分钟
                </el-form-item>
                <el-form-item label="跨步时间" prop="StrideTime">
                  <el-input-number v-model="runPosture.StrideTime" size="small">
                  </el-input-number
                  >秒（s）
                </el-form-item>
                <el-form-item label="步幅" prop="Stridelength">
                  <el-input-number
                    v-model="runPosture.Stridelength"
                    size="small"
                  >
                  </el-input-number
                  >米(m)
                </el-form-item>
                <el-form-item label="垂直振幅" prop="Vdisp">
                  <el-input-number v-model="runPosture.Vdisp" size="small">
                  </el-input-number
                  >米(m)
                </el-form-item>
                <el-form-item label="触地时间" prop="Contacttime">
                  <el-input-number
                    v-model="runPosture.Contacttime"
                    size="small"
                  ></el-input-number
                  >秒（s）
                </el-form-item>
                <div class="form-item-label">角度</div>
                <el-form-item label="足部触地角度" prop="Footstrikeangle">
                  <el-input-number
                    v-model="runPosture.Footstrikeangle"
                    size="small"
                  ></el-input-number
                  >度
                </el-form-item>
                <el-form-item label="前倾角度" prop="Spineangle">
                  <el-input-number
                    v-model="runPosture.Spineangle"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">作用力</div>
                <el-form-item label="垂直作用力" prop="Vertforce">
                  <el-input-number
                    v-model="runPosture.Vertforce"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item label="制动力" prop="Brakeforce">
                  <el-input-number
                    v-model="runPosture.Brakeforce"
                    size="small"
                  ></el-input-number
                  >力(N)
                </el-form-item>
                <el-form-item label="侧向作用力" prop="Latforce">
                  <el-input-number
                    v-model="runPosture.Latforce"
                    size="small"
                  ></el-input-number
                  >力(N)
                </el-form-item>
                <div class="form-item-label">评估结果</div>
                <el-form-item label="跑步经济性" prop="WorkTotalNorm">
                  <el-input-number
                    v-model="runPosture.WorkTotalNorm"
                    size="small"
                  ></el-input-number
                  >Joules/kg/m
                </el-form-item>
                <el-form-item label="步态评分" prop="Striderating">
                  <el-input-number
                    v-model="runPosture.Striderating"
                    size="small"
                  ></el-input-number
                  >星级/5星
                </el-form-item>

                <div class="form-item-label">总机械功</div>
                <el-form-item label="身体做功" prop="WorkExternalVlocityNorm">
                  <el-input-number
                    v-model="runPosture.WorkExternalVlocityNorm"
                    size="small"
                  ></el-input-number
                  >Joules/kg/m
                </el-form-item>
                <el-form-item label="恢复做功" prop="WorkInternalNorm">
                  <el-input-number
                    v-model="runPosture.WorkInternalNorm"
                    size="small"
                  ></el-input-number
                  >Joules/kg/m
                </el-form-item>
                <el-form-item label="重力做功" prop="WorkExternalGravityNorm">
                  <el-input-number
                    v-model="runPosture.WorkExternalGravityNorm"
                    size="small"
                  ></el-input-number
                  >Joules/kg/m
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>力与力矩</span>
          </div>
          <div class="form">
            <div class="form-row">
              <el-form-item label="关节压力指数" prop="Jointloading">
                <el-input-number
                  v-model="runPosture.Jointloading"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </div>

            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">膝关节</div>
                <el-form-item
                  label="矢状面力矩左"
                  prop="KneeMomentSagittalLeft"
                >
                  <el-input-number
                    v-model="runPosture.KneeMomentSagittalLeft"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item
                  label="矢状面力矩右"
                  prop="KneeMomentSagittalRight"
                >
                  <el-input-number
                    v-model="runPosture.KneeMomentSagittalRight"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item label="额状面力矩左" prop="KneemomentFrontalLeft">
                  <el-input-number
                    v-model="runPosture.KneemomentFrontalLeft"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item
                  label="额状面力矩右"
                  prop="KneemomentFrontalRight"
                >
                  <el-input-number
                    v-model="runPosture.KneemomentFrontalRight"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item label="垂直作用力左" prop="KneeforceVerticalLeft">
                  <el-input-number
                    v-model="runPosture.KneeforceVerticalLeft"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item
                  label="垂直作用力右"
                  prop="KneeforceVerticalRight"
                >
                  <el-input-number
                    v-model="runPosture.KneeforceVerticalRight"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item
                  label="侧向作用力左"
                  prop="KneeforceMediolateralLeft"
                >
                  <el-input-number
                    v-model="runPosture.KneeforceMediolateralLeft"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item
                  label="侧向作用力右"
                  prop="KneeforceMediolateralRight"
                >
                  <el-input-number
                    v-model="runPosture.KneeforceMediolateralRight"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">髋关节</div>
                <el-form-item label="矢状面力矩左" prop="HipMomentSagittalLeft">
                  <el-input-number
                    v-model="runPosture.HipMomentSagittalLeft"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item
                  label="矢状面力矩右"
                  prop="HipMomentSagittalRight"
                >
                  <el-input-number
                    v-model="runPosture.HipMomentSagittalRight"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item label="额状面力矩左" prop="HipmomentFrontalLeft">
                  <el-input-number
                    v-model="runPosture.HipmomentFrontalLeft"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item label="额状面力矩右" prop="HipmomentFrontalRight">
                  <el-input-number
                    v-model="runPosture.HipmomentFrontalRight"
                    size="small"
                  ></el-input-number
                  >BWm
                </el-form-item>
                <el-form-item label="垂直作用力左" prop="HipforceVerticalLeft">
                  <el-input-number
                    v-model="runPosture.HipforceVerticalLeft"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item label="垂直作用力右" prop="HipforceVerticalRight">
                  <el-input-number
                    v-model="runPosture.HipforceVerticalRight"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item
                  label="侧向作用力左"
                  prop="HipforceMediolateralLeft"
                >
                  <el-input-number
                    v-model="runPosture.HipforceMediolateralLeft"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
                <el-form-item
                  label="侧向作用力右"
                  prop="HipforceMediolateralRight"
                >
                  <el-input-number
                    v-model="runPosture.HipforceMediolateralRight"
                    size="small"
                  ></el-input-number
                  >BW
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>步态分析-矢状面</span>
          </div>
          <div class="form">
            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">触地时膝关节屈曲度</div>
                <el-form-item label="总" prop="KneeFlexLanding">
                  <el-input-number
                    v-model="runPosture.KneeFlexLanding"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="左" prop="KneeflexlandingLeft">
                  <el-input-number
                    v-model="runPosture.KneeflexlandingLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="KneeflexlandingRight">
                  <el-input-number
                    v-model="runPosture.KneeflexlandingRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <div class="form-item-label">站立时最大膝关节屈曲度</div>
                <el-form-item label="总" prop="KneeFlexStanceMax">
                  <el-input-number
                    v-model="runPosture.KneeFlexStanceMax"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="左" prop="KneeFlexStanceLeft">
                  <el-input-number
                    v-model="runPosture.KneeFlexStanceLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="KneeFlexStanceRight">
                  <el-input-number
                    v-model="runPosture.KneeFlexStanceRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <div class="form-item-label">摆动时最大膝关节屈曲度</div>
                <el-form-item label="总" prop="KneeflexswingMax">
                  <el-input-number
                    v-model="runPosture.KneeflexswingMax"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="左" prop="KneeflexswingLeft">
                  <el-input-number
                    v-model="runPosture.KneeflexswingLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="KneeflexswingRight">
                  <el-input-number
                    v-model="runPosture.KneeflexswingRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">股骨最大屈曲度</div>
                <el-form-item label="总" prop="ThighflexMax">
                  <el-input-number
                    v-model="runPosture.ThighflexMax"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="左" prop="ThighflexLeft">
                  <el-input-number
                    v-model="runPosture.ThighflexLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="ThighflexRight">
                  <el-input-number
                    v-model="runPosture.ThighflexRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <div class="form-item-label">股骨最大伸展度</div>
                <el-form-item label="总" prop="ThighextMax">
                  <el-input-number
                    v-model="runPosture.ThighextMax"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="左" prop="ThighextLeft">
                  <el-input-number
                    v-model="runPosture.ThighextLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="ThighextRight">
                  <el-input-number
                    v-model="runPosture.ThighextRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <div class="form-item-label">触地时小腿角度</div>
                <el-form-item label="总" prop="ShankangleLeft">
                  {{ Shankangle }}度(°)
                </el-form-item>
                <el-form-item label="左" prop="ShankangleLeft">
                  <el-input-number
                    v-model="runPosture.ShankangleLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="ShankangleRight">
                  <el-input-number
                    v-model="runPosture.ShankangleRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>步态分析-额状面+三维动作评分</span>
          </div>
          <div class="form">
            <div class="form-row" style="width: 100%">
              <div class="form-item">
                <div class="form-item-label">步态离散度</div>
                <el-form-item label="左" prop="StepwidthLeft">
                  <el-input-number
                    v-model="runPosture.StepwidthLeft"
                    size="small"
                  ></el-input-number
                  >米(m)
                </el-form-item>
                <el-form-item label="右" prop="StepwidthRight">
                  <el-input-number
                    v-model="runPosture.StepwidthRight"
                    size="small"
                  ></el-input-number
                  >米(m)
                </el-form-item>
                <div class="form-item-label">左膝内外翻角度</div>
                <el-form-item label="左" prop="KneerotLeft">
                  <el-input-number
                    v-model="runPosture.KneerotLeft"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
                <el-form-item label="右" prop="KneerotRight">
                  <el-input-number
                    v-model="runPosture.KneerotRight"
                    size="small"
                  ></el-input-number
                  >度(°)
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">评分</div>
                <el-form-item label="跑步性能得分" prop="Score1">
                  <el-tag>{{ score1 }}</el-tag>
                </el-form-item>
                <el-form-item label="跑步特征得分" prop="Score2">
                  <el-tag>{{ score2 }}</el-tag>
                </el-form-item>
                <el-form-item label="关节负荷得分" prop="Score3">
                  <el-tag>{{ score3 }}</el-tag>
                </el-form-item>
                <el-form-item label="三维动作总分" prop="Score">
                  <el-tag>{{ runPosture.Score.toFixed(0) }}</el-tag>
                </el-form-item>
              </div>
            </div>
            <div class="form-row" style="width: 100%">
              <div class="form-item" style="width: 100%">
                <div class="form-item-label">评估结果</div>
                <el-form-item label="跑者类型" prop="Level">
                  <el-select
                    v-model="runPosture.Level"
                    size="small"
                    placeholder="请选择"
                    @change="caculateSummary"
                  >
                    <el-option
                      v-for="item in runTypes"
                      :key="item.value"
                      :value="item.value"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="评估结果" prop="Summary">
                  <el-input
                    type="textarea"
                    rows="5"
                    style="width: 100%"
                    v-model="runPosture.Summary"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </el-form>
  </div>
</template>
<script>
import { loadFileData } from "@/api/check/check.js";
import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
    value: {
      default: {},
    },
  },
  watch: {
    value: {
      handler(v) {
        this.runPosture = v;
        this.ensureAttachments();
      },
      deep: true,
      immediate: true,
    },
  },
  components: { attchmentEditor, enumSelector },
  data() {
    return {
      runTypes: [
        { value: "C", name: "C" },
        { value: "E", name: "E" },
        { value: "L", name: "L" },
        { value: "P", name: "P" },
        { value: "ECO", name: "ECO" },
        { value: "Q", name: "Q" },
      ],
      checkType: "RunPosture",
      runPosture: {},
      runPostureRules: {
        Summary: { required: true, message: "请输入", trigger: "null" },
        Speed: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        WorkExternalVlocityNorm: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        WorkInternalNorm: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        WorkExternalGravityNorm: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ElasticExchange: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        WorkTotalNorm: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StrideTime: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Striderate: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Stridelength: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Footstrikeangle: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Vdisp: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        VdispLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        VdispRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Contacttime: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ContacttimeLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ContacttimeRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Vertforce: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        VertforceLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        VertforceRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        Brakeforce: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Latforce: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Spineangle: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Striderating: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeFlexLanding: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeflexlandingLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeflexlandingRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeFlexStanceMax: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeFlexStanceLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeFlexStanceRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeflexswingMax: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeflexswingLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeflexswingRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ThighextMax: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ThighextLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ThighextRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ThighflexMax: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ThighflexLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ThighflexRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ShankangleLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        ShankangleRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneerotLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneerotRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StepwidthLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StepwidthRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Jointloading: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeforceMediolateralLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeforceMediolateralRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeforceVerticalLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        KneeforceVerticalRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeMomentSagittalLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        KneeMomentSagittalRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        HipforceMediolateralLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        HipforceMediolateralRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        HipforceVerticalLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        HipforceVerticalRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        KneemomentFrontalLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        KneemomentFrontalRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        HipmomentFrontalLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        HipmomentFrontalRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        HipMomentSagittalLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        HipMomentSagittalRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        LoadingrateMax: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        PropulsionrateMax: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        DeltalLegStance: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        Legstiffness: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        Vertimpulse: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        Brakingimpulse: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Lateralimpulse: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Level: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
      },
    };
  },
  computed: {
    Shankangle() {
      return Math.round(
        (this.runPosture.ShankangleLeft + this.runPosture.ShankangleRight) / 2
      ).toFixed(2);
    },

    score1() {
      if (this.runPosture.WorkTotalNorm == undefined) {
        this.runPosture.Score1 = 0;
      } else {
        let score1 = (4.7 - this.runPosture.WorkTotalNorm) * 50;
        if (isNaN(score1)) {
          this.runPosture.Score1 = 0;
        } else {
          this.runPosture.Score1 = Math.round(score1);
        }
      }
      this.calcateTotal();
      return this.runPosture.Score1;
    },
    score2() {
      if (this.runPosture.Striderating == undefined) {
        this.runPosture.Score2 = 0;
      } else {
        let score2 = this.runPosture.Striderating * 20;
        if (isNaN(score2)) {
          this.runPosture.Score2 = 0;
        } else {
          this.runPosture.Score2 = Math.round(score2);
        }
      }
      this.calcateTotal();
      return this.runPosture.Score2;
    },
    score3() {
      if (this.runPosture.Jointloading == undefined) {
        this.runPosture.Score3 = 0;
      } else {
        let score3 = 100 - this.runPosture.Jointloading;
        if (isNaN(score3)) {
          this.runPosture.Score3 = 0;
        } else {
          this.runPosture.Score3 = Math.round(score3);
        }
      }
      this.calcateTotal();
      return this.runPosture.Score3;
    },
  },
  methods: {
    caculateSummary() {
      switch (this.runPosture.Level) {
        case "C":
          this.runPosture.Summary =
            "关节负荷温和、下肢弹性能量有限，不适合进行快跑。";
          break;
        case "E":
          this.runPosture.Summary =
            "最常见的跑步类型，对不同的跑鞋敏感，具有中等的速度能力、跑步经济性和受伤风险。";
          break;
        case "L":
          this.runPosture.Summary =
            "适合强壮的运动员、男性跑者的跑步类型，但伤病风险最高，对膝关节考验很大。";
          break;
        case "P":
          this.runPosture.Summary =
            "速度最快的跑步类型，适合中距离跑者，下肢弹性能量极佳，但踝关节负荷极大。";
        case "ECO":
          this.runPosture.Summary =
            "属于长距离精英跑者，在速度能力、跑步经济性以及伤病风险之间达到了最佳的平衡点。";
          break;
        case "Q":
          this.runPosture.Summary =
            "最不容易受伤的跑步类型，多数超级马拉松跑者以及女性马拉松跑者采用这种跑法。";
          break;
        default:
          this.runPosture.Summary = "缺少跑者类型，暂无三维动作捕捉结论。";
          break;
      }
    },
    calcateTotal() {
      let total =
        (this.runPosture.Score1 +
          this.runPosture.Score2 +
          this.runPosture.Score3) /
        3;
      console.log(total);
      if (isNaN(total)) {
        this.runPosture.Score = 0;
      } else {
        this.runPosture.Score = Math.round(total);
      }
    },

    uploadSuccess(val) {
      if (val.file.Type == "RunPosture") {
        let param = {
          checkKey: this.checkKey,
          checkType: val.bussiness,
          attachType: val.file.Type,
          dataFile: val.file.Path,
          checkItem: this.runPosture,
        };
        loadFileData(param).then((res) => {
          if (res) {
            this.runPosture = res;
          }
        });
      }
    },
    //确保有附件集合对象;
    ensureAttachments() {
      if (!this.runPosture.Attachments) {
        this.$set(this.runPosture, "Attachments", {
          ItemType: "XTH.Attachment",
          Items: [],
        });
      }
    },
    doValidate() {
      this.$refs[this.checkType].validate((valid) => {
        if (valid) {
          this.$emit("passValidate", this.runPosture);
        } else {
          setTimeout(() => {
            this.$refs[this.checkType].clearValidate();
          }, 3000);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}

/deep/.el-form-item__error {
  padding-top: 0 !important;
}

/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}

.done {
  color: #008000;
  font-size: 20px;
  font-weight: bolder;
}

.wait {
  color: #ffa500;
}

.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 40px;

      .form-item {
        .form-item-label {
          text-align: center;
          padding: 10px 0;
          font-size: 14px;
          font-weight: bolder;
        }
      }
    }
  }

  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;

    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &:last-child {
    padding-bottom: 60px;
  }
}
</style>