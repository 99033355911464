<template>
  <div class="bread-container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="route in routers" :key="route.path">{{route.name}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  computed: {
    routers () {
      return this.$route.matched
    },
    routeName () {
      return this.$route.name
    }
  },
}
</script>
<style lang='scss' scoped>
.bread-container {
  margin-left: 10px;
}
</style>