var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("checkHeader", {
        ref: "checkHeader",
        attrs: { readOnly: _vm.readOnly },
        model: {
          value: _vm.checkInfo,
          callback: function($$v) {
            _vm.checkInfo = $$v
          },
          expression: "checkInfo"
        }
      }),
      _c(
        "div",
        { staticClass: "panel" },
        [
          _c("checkPanel", {
            ref: "checkPanel",
            attrs: {
              disable: _vm.readOnly,
              accordion: false,
              checkKey: _vm.key
            },
            on: { completed: _vm.completedCheck }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }