<template>
  <el-upload action="1" ref='upload' :auto-upload='true' accept=".jpg,.png,.jpeg" :limit="1" :show-file-list="false" :on-change='fileChange' :before-upload='beforeUpload' :http-request="uploadSubmit"></el-upload>
</template>
<script>
import { uploadHead } from "@/api/upload";
export default {
  data () {
    return {
      file: ''
    }
  },
  methods: {
    chooseImage () {
      this.$refs['upload'].$refs['upload-inner'].handleClick()
    },
    fileChange (file) {
      this.file = file
    },
    beforeUpload (file) {
      const imglist = ['png', 'jpg', 'jpeg']
      const flieArr = file.type.split('/')
      const result = imglist.indexOf(flieArr[1].toLowerCase())
      let isJPG = true
      if (result === -1) {
        this.$message.error('请上传png、jpg、jpeg格式的图片！')
        isJPG = false
      }
      return isJPG
    },
    uploadSubmit () {
      const formData = new FormData()
      formData.append('file', this.file.raw)
      uploadHead(formData).then(res => {
        if (res) {
          this.$emit('updateSuccess', res);
          this.$refs['upload'].clearFiles();
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/.el-upload {
  display: none;
}
</style>