<template>
  <div class='menu-container'>
    <el-menu mode="vertical" class="el-menu-vertical" :default-active="$route.path" :collapse="isCollapse" background-color="rgb(48, 65, 86)" text-color="#fff" active-text-color="#ee7031" :collapse-transition="false">
      <div class="logo">
        <transition v-if="isCollapse && showLogo" name="el-fade-in">
            <img src="/images/logo2.png"  height="40" width="40" />
        </transition>
        <transition v-else name="el-fade-in">
          <div v-if="!isCollapse && showLogo" class="logo2">
            <img src="/images/logo2.png"  height="30" width="30" />
            <span style="font-weight:bold;font-size:20px;">心体汇</span>
          </div>
        </transition>
      </div>
      <sidebar-item :routes="routers"></sidebar-item>
    </el-menu>
  </div>
</template>
<script>
var apiUrl = process.env.BASE_API; // 要保证取到
import sidebarItem from "./sidebarItem.vue";
import { mapState } from 'vuex'
export default {
  components: {
    sidebarItem,
    // imageUploader,
  },
  data () {
    return {
      formVisible: false,
      logoImage: "",
      logoForm: {
        logoImage: "",
      },
    };
  },
  computed: {
    ...mapState({
      routers: state => state.route.routers,
      isCollapse: state => state.app.isCollapse,
      showLogo: state => state.app.showLogo,
    })
  },
  methods: {
    handleChangeLogo: function () {
      this.formVisible = true;
      this.logoForm.logoImage = "";
    },
    handleSubmit: function () {
      if (this.logoForm.logoImage != "") {
        changeLogo({ logo: this.logoForm.logoImage }).then((response) => {
          if (response) {
            this.logoImage = apiUrl + this.logoForm.logoImage;
            this.formVisible = false;
            localStorage.setItem('X-companyLogo', this.logoForm.logoImage);
          }
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.menu-container {
  height: 100%;
}
a {
  text-decoration: none !important;
  text-decoration: underline;
}

/deep/.el-menu {
  border: 0;
  height: 100%;
  user-select: none;
  // background: $menu-bg;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    div {
      font-size: 18px;
      letter-spacing: 2px;
      color: white;
    }
    .logo1 {
      margin-left: 0px;
    }
    .logo2 {
      margin-left: -20px;
       display: flex;
    align-items: center;
    justify-content: center;
    }
  }
  /deep/.el-submenu__title i {
    color: white !important;
  }
  /deep/.el-menu-item i {
    color: white !important;
  }
}
/deep/.horizontal-collapse-transition {
  transition: 0.1s width ease-in-out, 0.1s padding-left ease-in-out,
    0.1s padding-right ease-in-out;
}
/deep/.el-submenu__title {
  box-sizing: border-box;
}
/deep/.el-menu-vertical:not(.el-menu--collapse) {
  width: 220px;
}
/deep/.el-menu--collapse .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
/deep/.el-menu--collapse .el-submenu__icon-arrow {
  display: none;
}
</style>
<style >
.el-menu--vertical {
  margin-top: 5px !important;
}
.el-menu-item,
.el-submenu__title {
  height: 45px !important;
  line-height: 45px !important;
}
.el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
</style>
