var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-containder" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("div", { staticClass: "menu-user-info" }, [
            _c(
              "div",
              { staticClass: "menu-icon", on: { click: _vm.collapse } },
              [
                _c("i", {
                  staticClass: "iconfont icon-caidan",
                  class: {
                    "hide-menu-icon": _vm.isCollapse,
                    "show-menu-icon": !_vm.isCollapse
                  }
                })
              ]
            ),
            _c("div", { staticClass: "route-user-info" }, [
              _c("div", { staticClass: "route-logo" }, [_c("bread")], 1),
              _c("div", { staticClass: "messsage" }, [
                _c("i", { staticClass: "el-icon-s-flag" }),
                _vm._v(_vm._s(_vm.count1) + " "),
                _c("i", { staticClass: "el-icon-time" }),
                _vm._v(_vm._s(_vm.count2) + " ")
              ]),
              _c(
                "div",
                { staticClass: "user-info" },
                [
                  _c(
                    "el-dropdown",
                    { attrs: { placement: "bottom", trigger: "click" } },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _c(
                          "div",
                          { staticClass: "user-img" },
                          [
                            _c("el-avatar", {
                              attrs: {
                                size: "medium",
                                src:
                                  _vm.headerImage.indexOf("http") == 0
                                    ? _vm.headerImage
                                    : _vm.baseUrl + _vm.headerImage
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "user-name" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.realName === "null"
                                  ? _vm.userName
                                  : _vm.realName
                              ) +
                              "@" +
                              _vm._s(_vm.tenantName) +
                              " "
                          ),
                          _c("i", { staticClass: "el-icon-caret-bottom" })
                        ])
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "user-dpd",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        [
                          _c("el-dropdown-item", [
                            _c("div", { on: { click: _vm.changeHeadImg } }, [
                              _vm._v("更换头像")
                            ])
                          ]),
                          _c("el-dropdown-item", [
                            _c("div", { on: { click: _vm.changePassWord } }, [
                              _vm._v("修改密码")
                            ])
                          ]),
                          _c("el-dropdown-item", [
                            _c("div", { on: { click: _vm.loginOut } }, [
                              _vm._v("退出登录")
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("tags")
        ],
        1
      ),
      _c("change-pwd", { ref: "changePwd" }),
      _c("change-head-img", {
        ref: "changeHeadImg",
        on: { updateSuccess: _vm.headChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }