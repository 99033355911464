var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bread-container" },
    [
      _c(
        "el-breadcrumb",
        { attrs: { separator: "/" } },
        _vm._l(_vm.routers, function(route) {
          return _c("el-breadcrumb-item", { key: route.path }, [
            _vm._v(_vm._s(route.name))
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }