var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.routes, function(item) {
        return !item.hidden && item.children
          ? [
              _vm.isLeafMenu(item)
                ? _c(
                    "div",
                    {
                      key: item.path,
                      on: {
                        click: function($event) {
                          return _vm.goToPage(item)
                        }
                      }
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          class: { "submenu-title-noDropdown": !_vm.isNest },
                          attrs: { index: item.path }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont mgr10",
                            class: item.meta.icon
                          }),
                          item.meta && item.meta.title
                            ? _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(item.meta.title))]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm.isDesktopGroup(item)
                ? _vm._l(item.children, function(topItem) {
                    return [
                      _c(
                        "div",
                        {
                          key: topItem.path,
                          on: {
                            click: function($event) {
                              return _vm.goToPage(topItem)
                            }
                          }
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              class: {
                                "submenu-title-noDropdown": !_vm.isNest
                              },
                              attrs: { index: topItem.path }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont mgr10",
                                class: topItem.meta.icon
                              }),
                              topItem.meta && topItem.meta.title
                                ? _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(topItem.meta.title))]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  })
                : _c(
                    "el-submenu",
                    {
                      key: item.name,
                      attrs: { index: item.name || item.path }
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", {
                          staticClass: "iconfont mgr10",
                          class: item.meta.icon
                        }),
                        item.meta && item.meta.title
                          ? _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(item.meta.title))]
                            )
                          : _vm._e()
                      ]),
                      _vm._l(item.children, function(child) {
                        return [
                          child.children && child.children.length > 0
                            ? _c("sidebar-item", {
                                key: child.path,
                                staticClass: "nest-menu",
                                attrs: { "is-nest": true, routes: [child] }
                              })
                            : _c(
                                "div",
                                {
                                  key: child.path,
                                  on: {
                                    click: function($event) {
                                      return _vm.goToPage(child, item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-menu-item",
                                    {
                                      attrs: {
                                        index: item.path + "/" + child.path
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont mgr10",
                                        class: child.meta.icon
                                      }),
                                      child.meta && child.meta.title
                                        ? _c("span", [
                                            _vm._v(_vm._s(child.meta.title))
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                        ]
                      })
                    ],
                    2
                  )
            ]
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }