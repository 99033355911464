import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
/* Layout */
import Layout from '@/layout/index'
import localCheck from '@/views/check/local.vue'
/** *************登录后所有角色具备的固定菜单*************** */
export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    hidden: false,
  },
  {
    path: '/local',
    component: localCheck,
    hidden: false,
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404.vue'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401.vue'),
    hidden: true,
  },
]

export default new Router({
  scrollBehavior: () => ({
    y: 0,
  }),
  model: history, // 发布后需要配置web.config防止404出现。
  routes: constantRouterMap,
})
//* **************根据角色权限控制的菜单***************
export const asyncRouterMap = []

//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
