var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.readonly
    ? _c(
        "span",
        { staticStyle: { "margin-left": "10px", "margin-right": "10px" } },
        [_vm._v(_vm._s(_vm.name))]
      )
    : _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "value-key": "key",
            size: _vm.size,
            clearable: _vm.clearable,
            disabled: _vm.disabled
          },
          on: { change: _vm.dataChange, clear: _vm.dataChange },
          model: {
            value: _vm.data,
            callback: function($$v) {
              _vm.data = $$v
            },
            expression: "data"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.key,
            attrs: { label: item.name, value: item.value }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }