var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.activeChange },
          model: {
            value: _vm.checkType,
            callback: function($$v) {
              _vm.checkType = $$v
            },
            expression: "checkType"
          }
        },
        [
          (_vm.check.CheckTypes & 128) == 128 && _vm.heartEcg
            ? _c(
                "el-tab-pane",
                { attrs: { name: "HeartEcg" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.heartEcg.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "心电图运动负荷试验(" +
                            _vm._s(
                              _vm.heartEcg.Result ? _vm.heartEcg.Result : "-"
                            ) +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorHeartEcg", {
                    ref: "HeartEcg",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.heartEcg,
                      callback: function($$v) {
                        _vm.heartEcg = $$v
                      },
                      expression: "heartEcg"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.check.CheckTypes & 2) == 2 && _vm.bodyComposition
            ? _c(
                "el-tab-pane",
                { attrs: { name: "BodyComposition" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.bodyComposition.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "人体成分评估(" +
                            _vm._s(
                              _vm.bodyComposition.Score
                                ? _vm.bodyComposition.Score
                                : "-"
                            ) +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorBodyComposition", {
                    ref: "BodyComposition",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.bodyComposition,
                      callback: function($$v) {
                        _vm.bodyComposition = $$v
                      },
                      expression: "bodyComposition"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.check.CheckTypes & 4) == 4 && _vm.footPressure
            ? _c(
                "el-tab-pane",
                { attrs: { name: "FootPressure" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.footPressure.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "足底健康评估(" +
                            _vm._s(
                              _vm.footPressure.Score
                                ? _vm.footPressure.Score
                                : "-"
                            ) +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorFootPressure", {
                    ref: "FootPressure",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.footPressure,
                      callback: function($$v) {
                        _vm.footPressure = $$v
                      },
                      expression: "footPressure"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.check.CheckTypes & 8) == 8 && _vm.kneeHurt
            ? _c(
                "el-tab-pane",
                { attrs: { name: "KneeHurt" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.kneeHurt.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "关节功能评估(" +
                            _vm._s(
                              _vm.kneeHurt.Score ? _vm.kneeHurt.Score : "-"
                            ) +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorKneeHurt", {
                    ref: "KneeHurt",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.kneeHurt,
                      callback: function($$v) {
                        _vm.kneeHurt = $$v
                      },
                      expression: "kneeHurt"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.check.CheckTypes & 16) == 16 && _vm.runPosture
            ? _c(
                "el-tab-pane",
                { attrs: { name: "RunPosture" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.runPosture.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "三维动作捕捉(" +
                            _vm._s(
                              _vm.runPosture.Score ? _vm.runPosture.Score : "-"
                            ) +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorRunPosture", {
                    ref: "RunPosture",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.runPosture,
                      callback: function($$v) {
                        _vm.runPosture = $$v
                      },
                      expression: "runPosture"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.check.CheckTypes & 32) == 32 && _vm.breath
            ? _c(
                "el-tab-pane",
                { attrs: { name: "Breath" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.breath.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "呼吸能力评估(" +
                            _vm._s(_vm.breath.Score ? _vm.breath.Score : "-") +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorBreath", {
                    ref: "Breath",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.breath,
                      callback: function($$v) {
                        _vm.breath = $$v
                      },
                      expression: "breath"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.check.CheckTypes & 64) == 64 && _vm.heartLung
            ? _c(
                "el-tab-pane",
                { attrs: { name: "HeartLung" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c(
                      "i",
                      {
                        class: _vm.heartLung.Key
                          ? "el-icon-check done"
                          : "el-icon-time wait"
                      },
                      [
                        _vm._v(
                          "心肺耐力评估(" +
                            _vm._s(
                              _vm.heartLung.Score ? _vm.heartLung.Score : "-"
                            ) +
                            ")"
                        )
                      ]
                    )
                  ]),
                  _c("checkEditorHeartLung", {
                    ref: "HeartLung",
                    attrs: { checkKey: _vm.checkKey },
                    on: { passValidate: _vm.doSave },
                    model: {
                      value: _vm.heartLung,
                      callback: function($$v) {
                        _vm.heartLung = $$v
                      },
                      expression: "heartLung"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btns" },
        [
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.saveCheck } },
            [_vm._v("保存测评结果")]
          ),
          _c(
            "el-button",
            { attrs: { type: "success" }, on: { click: _vm.completedCheck } },
            [_vm._v("结束客户测评")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }