import Layout from '@/layout/index.vue'
export function filterAsyncRouter(constantRouterMap) {
  const accessedRouters = constantRouterMap.filter(route => {
    if (route.component) {
      if (route.component === 'Layout' || route.component === 'Desktop') { // Layout组件特殊处理
        route.component = Layout
      } else {
        const tt = route.component
        route.component = () => import(`@/views/${tt}`)
      }
    }
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
  return accessedRouters
}