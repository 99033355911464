<template>
  <div class="header-containder">
    <div class="header">
      <div class="menu-user-info">
        <div class="menu-icon" @click="collapse">
          <i
            class="iconfont icon-caidan"
            :class="{
              'hide-menu-icon': isCollapse,
              'show-menu-icon': !isCollapse,
            }"
          ></i>
        </div>
        <div class="route-user-info">
          <div class="route-logo">
            <bread></bread>
          </div>
          <div class="messsage">
            <i class="el-icon-s-flag"/>{{ count1 }}
            <i class="el-icon-time"/>{{ count2 }}
          </div>
          <div class="user-info">
            <el-dropdown placement="bottom" trigger="click">
              <span class="el-dropdown-link">
                <div class="user-img">
                  <el-avatar
                    size="medium"
                    :src="
                      headerImage.indexOf('http') == 0
                        ? headerImage
                        : baseUrl + headerImage
                    "
                  ></el-avatar>
                </div>
                <div class="user-name">
                  {{ realName === "null" ? userName : realName }}@{{
                    tenantName
                  }}
                  <i class="el-icon-caret-bottom"></i>
                </div>
              </span>
              <el-dropdown-menu class="user-dpd" slot="dropdown">
                <el-dropdown-item>
                  <div @click="changeHeadImg">更换头像</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="changePassWord">修改密码</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="loginOut">退出登录</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <tags></tags>
    </div>
    <change-pwd ref="changePwd" />
    <change-head-img ref="changeHeadImg" @updateSuccess="headChanged" />
  </div>
</template>
<script>
import changePwd from "@/components/changePwd";
import changeHeadImg from "@/components/changeHeadImg";
import bread from "./bread";
import tags from "./tags";
import { logout } from "@/api/setting/member";
import { mapState } from "vuex";
import { getWaitAuditCount } from "@/api/check/check";
export default {
  components: { changePwd, changeHeadImg, bread, tags },
  data() {
    return {
      headerImage: localStorage.getItem("X-UserHeadImage"),
      realName: localStorage.getItem("X-UserRealName"),
      userName: localStorage.getItem("X-UserName"),
      tenantName: localStorage.getItem("X-TenantName"),
      count1: 0,
      count2:1,
      intervalId: "",
    };
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.app.isCollapse,
    }),
  },
  mounted() {
    if (this.intervalId) {
      clearInterval(intervalId);
    }
    let that = this;
    this.intervalId = setInterval(function () {
      getWaitAuditCount({}).then((res) => {
        that.count1 = res.count1;
        that.count2 = res.count2;
      });
    }, 60000);
  },
  methods: {
    getMessage() {
      
    },
    collapse() {
      this.$store.commit("app/showLogo", false);
      setTimeout(() => {
        this.$store.commit("app/isCollapse", !this.isCollapse);
        setTimeout(() => {
          this.$store.commit("app/showLogo", true);
        }, 100);
      }, 0);
    },
    changeHeadImg() {
      this.$refs.changeHeadImg.chooseImage();
    },
    headChanged(val) {
      this.headerImage = this.baseUrl + val;
      localStorage.setItem("X-UserHeadImage", this.baseUrl + val);
    },
    changePassWord() {
      this.$refs.changePwd.showForm(this.userName);
    },

    loginOut() {
      let token = localStorage.getItem("X-Token");
      logout(token).then(() => {
        //登录成功
        localStorage.setItem("X-Token", "");
        localStorage.setItem("X-UserName", "");
        localStorage.setItem("X-UserKey", "");
        localStorage.setItem("X-UserNickName", "");
        localStorage.setItem("X-TenantName", "");
        localStorage.setItem("X-TenantKey", "");
        this.$store.commit("userInfo", { name: "", key: "" });
        this.$store.commit("userTenantInfo", { name: "", key: "" });
        localStorage.setItem("X-routes", "");
        this.$store.commit("routers", []);
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/.el-avatar {
  background-color: aliceblue;
}
.header-containder {
  background: #f6f6f6;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 4px;
  .header {
    height: 100%;
    .menu-user-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      background: $head-bg;
      .menu-icon {
        height: 50px;
        width: 50px;
        cursor: pointer;
        line-height: 50px;
        text-align: center;
        i {
          display: inline-block;
          color: black;
          font-size: 27px;
        }
        .show-menu-icon {
          transform: rotate(0deg);
          transition: all 0.3s linear;
        }
        .hide-menu-icon {
          transform: rotate(180deg);
          transition: all 0.3s linear;
        }
        &:hover {
          box-shadow: 0px 0px 10px 1px #eee inset;
        }
      }
      .route-user-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .messsage{
          font-size: 24px;
          font-weight: bold;
          color:#e95104;
        }
        .route-logo {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .logo-name {
            margin-top: -3px;
            font-size: 22px;
            margin-left: 10px;
          }
        }
        .user-info {
          .el-dropdown-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-right: 15px;
            cursor: pointer;
            user-select: none;
            .user-img {
              margin-right: 10px;
              i {
                font-size: 26px;
                color: #e95104;
              }
            }
            .user-name {
              font-size: 17px;
              color: #e95104;
            }
          }
        }
      }
    }
  }
}
</style>