import request from '@/utils/request'

export function uploadFile(data) {
  return request({
    url: 'xthUpload/uploadFile',
    method: 'post',
    data,
  })
}


export function uploadFileWithName(data) {
  return request({
    url: 'xthUpload/uploadFileWithName',
    method: 'post',
    data
  })
}