<template>
  <div class="tags-container">
    <el-tag closable class="tag" :class="{'tag-active':routeName===item.name}" v-for="item in routeHistory" :key="item.name" @click="tagTo(item)" @close='tagClose(item)'>{{item.name}}</el-tag>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      routeHistory: state => state.route.routeHistory,
    }),
    routeName () {
      return this.$route.name
    }
  },
  watch: {
    $route (v) {
      if (v.name) {
        let historyList = this.routeHistory;
        let ex = historyList.find(r => r.path === v.path)
        if (!ex) {
          this.$store.dispatch('addRouteHistory', v)
        }
      }
    }
  },
  methods: {
    tagTo (route) {
      route.meta['keepAlive'] = true
      this.$router.push({ path: route.path });
    },
    tagClose (route) {
      this.$store.dispatch('removeRouteHistory', route)
      if (route.name == this.$route.name) {
        this.$router.push('/')
      }
    },
  },
  mounted () {
    let routeName = this.$route.name
    if (routeName) {
      this.$store.dispatch('addRouteHistory', this.$route)
    }
  }
}
</script>
<style lang='scss' scoped>
.tags-container {
  overflow-x: auto;
  margin-top: 2px;
  height: 35px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  background: white;
  /deep/.el-tag {
    height: 25px;
    padding: 0 10px;
    line-height: 22.5px;
    border-radius: 1px;
    cursor: pointer;
  }
  .tag {
    color: black;
    margin-right: 10px;
  }
  .tag-active {
    background: #ee7031;
    color: white;
    /deep/.el-icon-close {
      color: black !important;
      &:hover {
        background: white !important;
      }
    }
  }
}
</style>