<template>
  <div style="width: 1000px">
    <el-form
      :model="dataForm"
      ref="dataForm"
      :rules="dataFormRule"
      label-width="120px"
    >
      <el-card shadow="never">
        <div slot="header">
          <span style="font-size: 16px">客户基本信息</span>
          <div
            style="display: flex; align-items: center; float: right"
            v-if="step"
          >
            <el-tag type="success">选择已有客户：</el-tag
            ><customerSelector
              ref="customerSelector"
              @change="customerChanged"
            />
          </div>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号" prop="Code">
              <el-input
                v-model="dataForm.Code"
                size="small"
                :disabled="true"
                placeholder="系统自动产生"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="RealName">
              <el-input
                v-model="dataForm.RealName"
                :disabled="readOnly"
                size="small"
                placeholder="请输入真实姓名"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别" prop="Sex">
              <el-select
                v-model="dataForm.Sex"
                size="small"
                placeholder="请输入性别"
                :disabled="readOnly"
              >
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生日" prop="Birthday">
              <el-date-picker
                v-model="dataForm.Birthday"
                :disabled="readOnly"
                type="date"
                size="small"
                placeholder="请选择出生日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="身高" prop="Height">
              <el-input-number
                v-model="dataForm.Height"
                :precision="1"
                size="small"
                :min="0"
                placeholder="请输入身高"
                :disabled="readOnly"
              >
              </el-input-number>
              cm
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重" prop="Weight">
              <el-input-number
                :precision="2"
                v-model="dataForm.Weight"
                size="small"
                :min="0"
                placeholder="请输入体重"
                :disabled="readOnly"
              >
              </el-input-number>
              kg
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="BMI" prop="BMI">
              {{ dataForm.BMI.toFixed(2) }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="鞋码" prop="ShoesSize">
              <el-input-number
                :precision="2"
                v-model="dataForm.ShoesSize"
                size="small"
                :min="0"
                placeholder="请输入鞋码"
                :disabled="readOnly"
              >
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="Mobile">
              <el-input
                v-model="dataForm.Mobile"
                :disabled="readOnly"
                size="small"
                placeholder="请输入手机号"
              ></el-input>
              <el-tag type="danger"
                >手机号是关联微信小程序标识，请准确输入。</el-tag
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="IdCode">
              <el-input
                v-model="dataForm.IdCode"
                :disabled="readOnly"
                size="small"
                class="input"
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="低血压:" prop="BloodPressureMin">
              <div style="display: flex; align-items: center">
                <el-input-number
                  :disabled="readOnly"
                  v-model="dataForm.BloodPressureMin"
                  size="small"
                  :precision="1"
                  :min="0"
                  placeholder="低血压值"
                ></el-input-number>
                mmHg
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="高血压:" prop="BloodPressureMax">
              <div style="display: flex; align-items: center">
                <el-input-number
                  :disabled="readOnly"
                  v-model="dataForm.BloodPressureMax"
                  size="small"
                  :precision="1"
                  :min="0"
                  placeholder="高血压值"
                >
                </el-input-number>
                mmHg
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card shadow="never" v-if="!readOnly">
      <div class="footer">
        <el-button class="submit-btn" type="success" @click="submit">{{
          step ? "下一步" : "保存"
        }}</el-button>
      </div>
    </el-card>
  </div>
</template>
  <script>
import { save, getSingle } from "@/api/setting/member.js";
import enumSelector2 from "@/components/enumSelector2";
import customerSelector from "@/components/customerSelector";
export default {
  components: {
    enumSelector2,
    customerSelector,
  },
  props: {
    readOnly: {
      default: false,
    },
    step: {
      default: false,
    },
  },
  data() {
    return {
      dataForm: {
        Key:"",
        Code: "",
        RealName: "",
        Mobile: "",
        Sex: "男",
        BMI: 0,
        Birthday: "",
        IsLongSit: false,
        HealthStatus: "",
        MedicalHistory: "",
        DiseaseHistory: "",
        Inheritance: "",
        ShoesSize: 40,
        Height: 170,
        Weight: 60,
        FatRate: 0,

        BloodPressureMin: 0,
        BloodPressureMax: 0,

        SmokeLength: 0,
        SmokeDayCount: 0,
        SmokeStopLength: 0,

        DrinkWine: 0,
        DrinkWineType: 0,
        DrinkWeekCount: 0,
        DrinkWineAmount: 0,
        DrinkStopLength: 0,

        Exercise: "",
        ExerciseAmount: 0,
        ExerciseFrequency: 0,
        JoinDangerExercise: false,
        RunType: 0,
        RunScoreHour: 0,
        RunScoreMin: 0,
        RunScore: "",
        IdCode: "",
      },
      dataFormRule: {
        RealName: [{ required: true, message: "请输入姓名", trigger: "change" }],
        Sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        Birthday: [
          { required: true, message: "请选择出生日期", trigger: "change" },
        ],
        Mobile: [
          { required: true, message: "请填写手机号", trigger: "change" },
        ],
        IdCode: [
          { required: true, message: "请输入身份证号", trigger: "change" },
        ],
        Height: [{ required: true, message: "请输入身高", trigger: "change" }],
        Weight: [{ required: true, message: "请输入体重", trigger: "change" }],
        BMI: [{ required: true, message: "请输入BMI", trigger: "change" }],
        SmokeLength: [
          { required: true, message: "请输入吸烟史", trigger: "change" },
        ],
        DrinkWine: [
          { required: true, message: "请输入饮酒史", trigger: "change" },
        ],
        BloodPressureMin: [
          { required: true, message: "请输入低血压值", trigger: "change" },
        ],
        BloodPressureMax: [
          { required: true, message: "请输入高血压值", trigger: "change" },
        ],
        ExerciseAmount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        ExerciseFrequency: [
          { required: true, message: "请输入", trigger: "change" },
        ],

        RunType: [{ required: true, message: "请输入", trigger: "change" }],
        RunScoreHour: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        RunScoreMin: [{ required: true, message: "请输入", trigger: "change" }],

        DrinkWineType: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        DrinkWeekCount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        DrinkWineAmount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        DrinkStopLength: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        SmokeLength: [{ required: true, message: "请输入", trigger: "change" }],
        SmokeDayCount: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        SmokeStopLength: [
          { required: true, message: "请输入", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    customerChanged(v) {
      if (v) {
        let data = JSON.parse(JSON.stringify(v));
        this.handleEdit(data);
      } else {
        this.handleCreate();
      }
    },
    show(key) {
      getSingle({ key: key }).then((res) => {
        this.dataForm = res;
        this.dataForm.Birthday = this.format.dateFormat(res.Birthday);
        if (res.DiseaseHistory)
          this.dataForm.DiseaseHistory = res.DiseaseHistory.split("、");
        else this.dataForm.DiseaseHistory = [];

        if (res.MedicalHistory)
          this.dataForm.MedicalHistory = res.MedicalHistory.split("、");
        else this.dataForm.MedicalHistory = [];

        if (res.Inheritance)
          this.dataForm.Inheritance = res.Inheritance.split("、");
        else this.dataForm.Inheritance = [];

        if (res.HealthStatus)
          this.dataForm.HealthStatus = res.HealthStatus.split("、");
        else this.dataForm.HealthStatus = [];

        if (res.Exercise) this.dataForm.Exercise = res.Exercise.split("、");
        else this.dataForm.Exercise = [];
      });
    },
    handleCreate() {
      this.dataForm = {
        Key:"",
        Code: "",
        Name: "",
        Mobile: "",
        IdCode: "",
        Sex: "男",
        BMI: 0,
        Birthday: "",
        ShoesSize: 40,
        Height: 170,
        Weight: 60,
        FatRate: 0,
        BloodPressureMin: 0,
        BloodPressureMax: 0,
      };
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate();
      });
    },
    handleEdit(row) {
      row.Birthday = this.format.dateFormat(row.Birthday);
      this.dataForm = row;
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate();
      });
    },
    submit() {
      let data = JSON.parse(JSON.stringify(this.dataForm));
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.step) {
            this.$emit("next", data);
          } else {
            save(data).then((res) => {
              if (res) {
                this.msg.successMsg("操作成功");
                this.$emit("update", data);
              }
            });
          }
        } else {
          setTimeout(() => {
            this.$refs.dataForm.clearValidate();
          }, 3000);
        }
      });
    },
  },
  mounted() {},
};
</script>
  <style lang="scss">
/deep/.el-dialog__body {
  padding: 10px 20px;
}
/deep/.el-form-item__error {
  padding-top: 0;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
:deep(.el-drawer__header) {
  margin-bottom: 5px;
}
.footer {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
}
</style>