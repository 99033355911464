import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import route from './modules/route'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userObj: '',
    userInfo: '',
    userTenantInfo: '',
  },
  mutations: {
    userTenantInfo: (state, userTenantInfo) => {
      state.userTenantInfo = userTenantInfo
    },
    userInfo: (state, userInfo) => {
      state.userInfo = userInfo
    },
    userObj: (state, userObj) => {
      state.userObj = userObj
    },
  },
  modules: {
    app,
    route,
  },
})
