<template>
  <div>
    <attchmentEditor
      :attachments="bodyComposition.Attachments"
      bussiness="BodyComposition"
      @uploaded="uploadSuccess"
      :readOnly="disable"
    ></attchmentEditor>
    <el-form
      :model="bodyComposition"
      ref="BodyComposition"
      :rules="bodyCompositionRules"
      label-width="110px"
      :disabled="disable"
    >
      <div class="row">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>骨骼肌</span>
          </div>
          <el-form-item label="左上肢" prop="GGJTopLeft">
            <el-input-number
              v-model="bodyComposition.GGJTopLeft"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="右上肢" prop="GGJTopRight">
            <el-input-number
              v-model="bodyComposition.GGJTopRight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="左下肢" prop="GGJBottomLeft">
            <el-input-number
              v-model="bodyComposition.GGJBottomLeft"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="右下肢" prop="GGJBottomRight">
            <el-input-number
              v-model="bodyComposition.GGJBottomRight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="躯干" prop="GGJBody">
            <el-input-number
              v-model="bodyComposition.GGJBody"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="骨骼肌总量" prop="GGJ">
            <el-input-number
              v-model="bodyComposition.GGJ"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="判定" prop="GGJJudge">
            <enumSelector
              :options="$config.standards"
              style="width: 200px"
              v-model="bodyComposition.GGJJudge"
            />
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>体脂</span>
          </div>
          <el-form-item label="左上肢" prop="TZTopLeft">
            <el-input-number
              v-model="bodyComposition.TZTopLeft"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="右上肢" prop="TZTopRight">
            <el-input-number
              v-model="bodyComposition.TZTopRight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="左下肢" prop="TZBottomLeft">
            <el-input-number
              v-model="bodyComposition.TZBottomLeft"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="右下肢" prop="TZBottomRight">
            <el-input-number
              v-model="bodyComposition.TZBottomRight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="躯干" prop="TZBody">
            <el-input-number
              v-model="bodyComposition.TZBody"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="体脂量" prop="TZ">
            <el-input-number
              v-model="bodyComposition.TZ"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="体脂率" prop="TZRate">
            <el-input-number
              v-model="bodyComposition.TZRate"
              size="small"
            ></el-input-number
            >%
          </el-form-item>
          <el-form-item label="判定" prop="TZJudge">
            <enumSelector
              :options="$config.standards"
              style="width: 200px"
              v-model="bodyComposition.TZJudge"
            />
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>内脏脂肪</span>
          </div>
          <el-form-item label="内脏脂肪" prop="NZ">
            <el-input-number
              v-model="bodyComposition.NZ"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="内脏脂肪面积" prop="NZArea">
            <el-input-number
              v-model="bodyComposition.NZArea"
              size="small"
            ></el-input-number
            >cm2
          </el-form-item>
          <el-form-item label="内脏脂肪等级" prop="NZDengJi">
            <el-input-number
              v-model="bodyComposition.NZDengJi"
              size="small"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="判定" prop="NZJudge">
            <enumSelector
              :options="$config.nzDengji"
              style="width: 200px"
              v-model="bodyComposition.NZJudge"
            />
          </el-form-item>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>代谢</span>
          </div>
          <el-form-item label="基础代谢量" prop="DXBase">
            <el-input-number
              v-model="bodyComposition.DXBase"
              size="small"
            ></el-input-number
            >kcal
          </el-form-item>
          <el-form-item label="总代谢消耗" prop="DX">
            <el-input-number
              v-model="bodyComposition.DX"
              size="small"
            ></el-input-number
            >kcal
          </el-form-item>
          <el-form-item label="判定" prop="DXJudge">
            <enumSelector
              :options="$config.standards"
              style="width: 200px"
              v-model="bodyComposition.DXJudge"
            />
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>体型</span>
          </div>
          <el-form-item label="身高" prop="BodyHeight">
            <el-input-number
              v-model="bodyComposition.BodyHeight"
              size="small"
            ></el-input-number
            >cm
          </el-form-item>
          <el-form-item label="体重" prop="Jcdxl">
            <el-input-number
              v-model="bodyComposition.BodyWeight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="去脂体重" prop="BodyWeightNoTZ">
            <el-input-number
              v-model="bodyComposition.BodyWeightNoTZ"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="判定" prop="BodyJudge">
            <enumSelector
              :options="$config.bodyStyleStandards"
              style="width: 200px"
              v-model="bodyComposition.BodyJudge"
            />
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>评估结果</span>
          </div>
          <el-form-item label="得分" prop="Score">
            <el-tag>{{ bodyComposition.Score }}</el-tag>
          </el-form-item>
          <el-form-item label="建议体重" prop="RecWeight">
            <el-input-number
              v-model="bodyComposition.RecWeight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="体重控制" prop="ConWeight">
            <el-input-number
              v-model="bodyComposition.ConWeight"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="肌肉控制" prop="ConJiRou">
            <el-input-number
              v-model="bodyComposition.ConJiRou"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
          <el-form-item label="脂肪控制" prop="ConZhiFang">
            <el-input-number
              v-model="bodyComposition.ConZhiFang"
              size="small"
            ></el-input-number
            >kg
          </el-form-item>
        </el-card>
      </div>
    </el-form>
  </div>
</template>
<script>
import { loadFileData } from "@/api/check/check.js";
import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
    value: {
      default: {},
    },
  },
  watch: {
    value: {
      handler(v) {
        this.bodyComposition = v;
        this.ensureAttachments();
      },
      deep: true,
      immediate: true,
    },
  },
  components: { attchmentEditor, enumSelector },
  data() {
    return {
      checkType: "BodyComposition",
      bodyComposition: {},
      bodyCompositionRules: {
        Score: {
          required: true,
          message: "请完成测评",
          trigger: "null",
        },
        RecWeight: { required: true, message: "请输入", trigger: "null" },
        ConWeight: { required: true, message: "请输入", trigger: "null" },
        ConJiRou: { required: true, message: "请输入", trigger: "null" },
        ConZhiFang: { required: true, message: "请输入", trigger: "null" },
        TZBottomLeft: { required: true, message: "请输入", trigger: "null" },
        TZBottomRight: { required: true, message: "请输入", trigger: "null" },
        TZBody: { required: true, message: "请输入", trigger: "null" },
        TZTopLeft: { required: true, message: "请输入", trigger: "null" },
        TZTopRight: { required: true, message: "请输入", trigger: "null" },
        TZ: { required: true, message: "请输入", trigger: "null" },
        TZRate: { required: true, message: "请输入", trigger: "null" },
        TZJudge: { required: true, message: "请输入", trigger: "null" },

        NZDengJi: { required: true, message: "请输入", trigger: "null" },
        NZArea: { required: true, message: "请输入", trigger: "null" },
        NZ: { required: true, message: "请输入", trigger: "null" },
        NZJudge: { required: true, message: "请输入", trigger: "null" },

        GGJJudge: { required: true, message: "请输入", trigger: "null" },
        GGJ: { required: true, message: "请输入", trigger: "null" },
        GGJTopRight: { required: true, message: "请输入", trigger: "null" },
        GGJTopLeft: { required: true, message: "请输入", trigger: "null" },
        GGJBody: { required: true, message: "请输入", trigger: "null" },
        GGJBottomRight: { required: true, message: "请输入", trigger: "null" },
        GGJBottomLeft: { required: true, message: "请输入", trigger: "null" },

        DXJudge: { required: true, message: "请输入", trigger: "null" },
        DX: { required: true, message: "请输入", trigger: "null" },
        DXBase: { required: true, message: "请输入", trigger: "null" },

        BodyYaoTunBi: { required: true, message: "请输入", trigger: "null" },
        BodyWeight: { required: true, message: "请输入", trigger: "null" },
        BodyHeight: { required: true, message: "请输入", trigger: "null" },
        BodyWeightNoTZ: { required: true, message: "请输入", trigger: "null" },
        BodyJudge: { required: true, message: "请输入", trigger: "null" },
      },
    };
  },
  computed: {},
  methods: {
    uploadSuccess(val) {
      if (val.file.Type == "BodyComposition") {
        let param = {
          checkKey: this.checkKey,
          checkType: val.bussiness,
          attachType: val.file.Type,
          dataFile: val.file.Path,
          checkItem: this.bodyComposition,
        };
        loadFileData(param).then((res) => {
          if (res) {
            this.bodyComposition = res;
          }
        });
      }
    },
    //确保有附件集合对象;
    ensureAttachments() {
      if (!this.bodyComposition.Attachments) {
        this.$set(this.bodyComposition, "Attachments", {
          ItemType: "XTH.Attachment",
          Items: [],
        });
      }
    },
    doValidate() {
      this.$refs[this.checkType].validate((valid) => {
        if (valid) {
          this.$emit("passValidate", this.bodyComposition);
        } else {
          setTimeout(() => {
            this.$refs[this.checkType].clearValidate();
          }, 3000);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 20px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .form-item {
    .form-item-label {
      text-align: center;
      padding: 10px 0;
    }
  }
  .input200 {
    width: 200px;
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>