<template>
  <div>
    <attchmentEditor
      :attachments="footPressure.Attachments"
      bussiness="FootPressure"
      @uploaded="uploadSuccess"
      :readOnly="disable"
    ></attchmentEditor>
    <el-form
      :model="footPressure"
      ref="FootPressure"
      :rules="footPressureRules"
      label-width="120px"
      :disabled="disable"
    >
      <div class="row">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>静态测试</span>
          </div>
          <div class="form">
            <div class="form-row">
              <el-form-item label="峰值压力位置" prop="FengZhiYaLiPostion">
                <enumSelector
                  :options="$config.fengZhiYaLiPostions"
                  v-model="footPressure.FengZhiYaLiPostion"
                />
              </el-form-item>
            </div>
            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">左足</div>
                <el-form-item label="面积前" prop="StaticLeftAreaFront">
                  <el-input-number
                    v-model="footPressure.StaticLeftAreaFront"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="面积后" prop="StaticLeftAreaBack">
                  <el-input-number
                    v-model="footPressure.StaticLeftAreaBack"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="总面积" prop="StaticLeftArea">
                  <el-input-number
                    v-model="footPressure.StaticLeftArea"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="最大压力" prop="StaticLeftMaxHeat">
                  <el-input-number
                    v-model="footPressure.StaticLeftMaxHeat"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="平均压力" prop="StaticLeftAveHeat">
                  <el-input-number
                    v-model="footPressure.StaticLeftAveHeat"
                    class="input"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="压力比前" prop="StaticLeftHeatRatioFront">
                  <el-input-number
                    v-model="footPressure.StaticLeftHeatRatioFront"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="压力比后" prop="StaticLeftHeatRatioBack">
                  <el-input-number
                    v-model="footPressure.StaticLeftHeatRatioBack"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="总压力比" prop="StaticLeftHeatRatio">
                  <el-input-number
                    v-model="footPressure.StaticLeftHeatRatio"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布前" prop="StaticLeftCoverFront">
                  <el-input-number
                    v-model="footPressure.StaticLeftCoverFront"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布后" prop="StaticLeftCoverBack">
                  <el-input-number
                    v-model="footPressure.StaticLeftCoverBack"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="重量" prop="StaticLeftWeight">
                  <el-input-number
                    v-model="footPressure.StaticLeftWeight"
                    size="small"
                  ></el-input-number
                  >kg
                </el-form-item>
                <el-form-item label="鞋码" prop="DataLeftSize">
                  <el-input-number
                    v-model="footPressure.DataLeftSize"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">右足</div>
                <el-form-item label="面积前" prop="StaticRightAreaFront">
                  <el-input-number
                    v-model="footPressure.StaticRightAreaFront"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="面积后" prop="StaticRightAreaBack">
                  <el-input-number
                    v-model="footPressure.StaticRightAreaBack"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="面积" prop="StaticRightArea">
                  <el-input-number
                    v-model="footPressure.StaticRightArea"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="最大压力" prop="StaticRightMaxHeat">
                  <el-input-number
                    v-model="footPressure.StaticRightMaxHeat"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="平均压力" prop="StaticRightAveHeat">
                  <el-input-number
                    v-model="footPressure.StaticRightAveHeat"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="压力比前" prop="StaticRightHeatRatioFront">
                  <el-input-number
                    v-model="footPressure.StaticRightHeatRatioFront"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="压力比后" prop="StaticRightHeatRatioBack">
                  <el-input-number
                    v-model="footPressure.StaticRightHeatRatioBack"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="总压力比" prop="StaticRightHeatRatio">
                  <el-input-number
                    v-model="footPressure.StaticRightHeatRatio"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布前" prop="StaticRightCoverFront">
                  <el-input-number
                    v-model="footPressure.StaticRightCoverFront"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布后" prop="StaticRightCoverBack">
                  <el-input-number
                    v-model="footPressure.StaticRightCoverBack"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="重量" prop="StaticRightWeight">
                  <el-input-number
                    v-model="footPressure.StaticRightWeight"
                    size="small"
                  ></el-input-number
                  >kg
                </el-form-item>
                <el-form-item label="鞋码" prop="DataRightSize">
                  <el-input-number
                    v-model="footPressure.DataRightSize"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>姿势测试</span>
          </div>
          <div class="form">
            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">左足</div>
                <el-form-item label="面积前" prop="PostureLeftAreaFront">
                  <el-input-number
                    v-model="footPressure.PostureLeftAreaFront"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="面积后" prop="PostureLeftAreaBack">
                  <el-input-number
                    v-model="footPressure.PostureLeftAreaBack"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="总面积" prop="PostureLeftArea">
                  <el-input-number
                    v-model="footPressure.PostureLeftArea"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="最大压力" prop="PostureLeftMaxHeat">
                  <el-input-number
                    v-model="footPressure.PostureLeftMaxHeat"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="平均压力" prop="PostureLeftAveHeat">
                  <el-input-number
                    v-model="footPressure.PostureLeftAveHeat"
                    class="input"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="压力比前" prop="PostureLeftHeatRatioFront">
                  <el-input-number
                    v-model="footPressure.PostureLeftHeatRatioFront"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="压力比后" prop="PostureLeftHeatRatioBack">
                  <el-input-number
                    v-model="footPressure.PostureLeftHeatRatioBack"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="总压力比" prop="PostureLeftHeatRatio">
                  <el-input-number
                    v-model="footPressure.PostureLeftHeatRatio"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布前" prop="PostureLeftCoverFront">
                  <el-input-number
                    v-model="footPressure.PostureLeftCoverFront"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布后" prop="PostureLeftCoverBack">
                  <el-input-number
                    v-model="footPressure.PostureLeftCoverBack"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="重量" prop="PostureLeftWeight">
                  <el-input-number
                    v-model="footPressure.PostureLeftWeight"
                    size="small"
                  ></el-input-number
                  >kg
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">右足</div>
                <el-form-item label="面积前" prop="PostureRightAreaFront">
                  <el-input-number
                    v-model="footPressure.PostureRightAreaFront"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="面积后" prop="PostureRightAreaBack">
                  <el-input-number
                    v-model="footPressure.PostureRightAreaBack"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="面积" prop="PostureRightArea">
                  <el-input-number
                    v-model="footPressure.PostureRightArea"
                    size="small"
                  ></el-input-number
                  >cm2
                </el-form-item>
                <el-form-item label="最大压力" prop="PostureRightMaxHeat">
                  <el-input-number
                    v-model="footPressure.PostureRightMaxHeat"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item label="平均压力" prop="PostureRightAveHeat">
                  <el-input-number
                    v-model="footPressure.PostureRightAveHeat"
                    size="small"
                  ></el-input-number
                  >g/cm2
                </el-form-item>
                <el-form-item
                  label="压力比前"
                  prop="PostureRightHeatRatioFront"
                >
                  <el-input-number
                    v-model="footPressure.PostureRightHeatRatioFront"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="压力比后" prop="PostureRightHeatRatioBack">
                  <el-input-number
                    v-model="footPressure.PostureRightHeatRatioBack"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="总压力比" prop="PostureRightHeatRatio">
                  <el-input-number
                    v-model="footPressure.PostureRightHeatRatio"
                    size="small"
                  >
                  </el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布前" prop="PostureRightCoverFront">
                  <el-input-number
                    v-model="footPressure.PostureRightCoverFront"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="分布后" prop="PostureRightCoverBack">
                  <el-input-number
                    v-model="footPressure.PostureRightCoverBack"
                    size="small"
                  ></el-input-number
                  >%
                </el-form-item>
                <el-form-item label="重量" prop="PostureRightWeight">
                  <el-input-number
                    v-model="footPressure.PostureRightWeight"
                    size="small"
                  ></el-input-number
                  >kg
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>动态测试</span>
          </div>
          <div class="form">
            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">左足</div>
                <el-form-item label="CPEI" prop="DynamicLeftCPEI">
                  <el-input-number
                    v-model="footPressure.DynamicLeftCPEI"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="面积" prop="DynamicLeftArea">
                  <el-input-number
                    v-model="footPressure.DynamicLeftArea"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="最大压力" prop="DynamicLeftMaxHeat">
                  <el-input-number
                    v-model="footPressure.DynamicLeftMaxHeat"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="平均压力" prop="DynamicLeftAveHeat">
                  <el-input-number
                    v-model="footPressure.DynamicLeftAveHeat"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">右足</div>
                <el-form-item label="CPEI" prop="DynamicRightCPEI">
                  <el-input-number
                    v-model="footPressure.DynamicRightCPEI"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="面积" prop="DynamicRightArea">
                  <el-input-number
                    v-model="footPressure.DynamicRightArea"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="最大压力" prop="DynamicRightMaxHeat">
                  <el-input-number
                    v-model="footPressure.DynamicRightMaxHeat"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="平均压力" prop="DynamicRightAveHeat">
                  <el-input-number
                    v-model="footPressure.DynamicRightAveHeat"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>足底扫描</span>
          </div>
          <div class="form">
            <div class="form-row">
              <div class="form-item">
                <div class="form-item-label">左足</div>
                <el-form-item label="足形" prop="DataArchLeftType">
                  <enumSelector
                    :options="$config.zuxing"
                    style="width: 200px"
                    v-model="footPressure.DataArchLeftType"
                  />
                </el-form-item>
                <el-form-item label="旋外度数:" prop="DataEveLeft">
                  <el-input-number
                    v-model="footPressure.DataEveLeft"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="旋内度数:" prop="DataInvLeft">
                  <el-input-number
                    v-model="footPressure.DataInvLeft"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="足踝" prop="DynamicLeftShape">
                  <enumSelector
                    :options="$config.zuhuai"
                    style="width: 200px"
                    v-model="footPressure.DynamicLeftShape"
                  />
                </el-form-item>
                <el-form-item label="足长:" prop="DataFootLeftSize">
                  <el-input-number
                    v-model="footPressure.DataFootLeftSize"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-item-label">右足</div>
                <el-form-item label="足形" prop="DataArchRightType">
                  <enumSelector
                    :options="$config.zuxing"
                    style="width: 200px"
                    v-model="footPressure.DataArchRightType"
                  />
                </el-form-item>
                <el-form-item label="旋外度数:" prop="DataEveRight">
                  <el-input-number
                    v-model="footPressure.DataEveRight"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="旋内度数:" prop="DataInvRight">
                  <el-input-number
                    v-model="footPressure.DataInvRight"
                    size="small"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="足踝" prop="DynamicRightShape">
                  <enumSelector
                    :options="$config.zuhuai"
                    style="width: 200px"
                    v-model="footPressure.DynamicRightShape"
                  />
                </el-form-item>
                <el-form-item label="足长:" prop="DataFootRightSize">
                  <el-input-number
                    v-model="footPressure.DataFootRightSize"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>评估结果</span>
          </div>
          <el-form-item label="得分" prop="Score">
            <el-tag>{{ score }}</el-tag>
          </el-form-item>
          <el-form-item label="压力结果" prop="HeatSummary">
            <el-input
              v-model="footPressure.HeatSummary"
              type="textarea"
              placeholder="请输入压力结果"
            ></el-input>
          </el-form-item>
          <el-form-item label="足形结果" prop="DataArchSummary">
            <el-input
              v-model="footPressure.DataArchSummary"
              type="textarea"
              placeholder="请输入足形结果"
            ></el-input>
          </el-form-item>
          <el-form-item label="足踝结果" prop="ShapeSummary">
            <el-input
              v-model="footPressure.ShapeSummary"
              type="textarea"
              placeholder="请输入足踝结果"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="评估结论(删)" prop="Summary">
            <el-input
              v-model="footPressure.Summary"
              type="textarea"
              placeholder="请输入评估结论,结论呈现在报告文件中。"
            ></el-input>
          </el-form-item> -->
        </el-card>
      </div>
    </el-form>
  </div>
</template>
<script>
import { loadFileData } from "@/api/check/check.js";
import attchmentEditor from "@/components/attchmentEditor.vue";
import enumSelector from "@/components/enumSelector.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkKey: {
      default: "",
    },
    disable: {
      default: false,
    },
    value: {
      default: {},
    },
  },
  watch: {
    value: {
      handler(v) {
        this.footPressure = v;
        this.ensureAttachments();
      },
      deep: true,
      immediate: true,
    },
  },
  components: { attchmentEditor, enumSelector },
  computed: {
    score() {
      let strShapeSummary = "";
      let strArchSummary = "";
      let strHeatSummary = "";
      let totalScore = 100;
      let leftShape = "";
      let rightShape = "";
      if (this.footPressure.DynamicLeftShape) {
        leftShape = this.footPressure.DynamicLeftShape;
      }
      if (this.footPressure.DynamicRightShape) {
        rightShape = this.footPressure.DynamicRightShape;
      }
      if (leftShape != "正常") totalScore -= 10;
      if (rightShape != "正常") totalScore -= 10;
      if (leftShape == rightShape) {
        if (leftShape == "正常")
          strShapeSummary =
            "经检测您左右脚踝均正常，建议您在日常训练前后松解足踝周围肌群，保持良好足部功能。";
        if (leftShape == "旋内")
          strShapeSummary =
            "左右足踝旋内，易导致脚踝扭伤及诱发骨性关节炎。建议您松解、牵拉足内侧肌群，并配合矫形鞋垫恢复正常足功能。";

        if (leftShape == "旋外")
          strShapeSummary =
            "左右足踝旋外，易导致导致膝盖内侧受伤。建议您松解足外侧肌群强化足内侧肌群，并配合矫形鞋垫恢复正常足功能。";
      } else {
        strShapeSummary = "经检测您";
        let shapeSuggest = "建议您";
        if (leftShape == "旋内") {
          strShapeSummary += "左足踝旋内，易导致脚踝扭伤及诱发骨性关节炎；";
          shapeSuggest += "松解、牵拉左足内侧肌群,";
        }
        if (leftShape == "旋外") {
          strShapeSummary += "左足踝旋外，易导致导致膝盖内侧受伤；";
          shapeSuggest += "松解足外侧肌群强化左足内侧肌群,";
        }
        if (rightShape == "旋内") {
          strShapeSummary += "右足踝旋内，易导致脚踝扭伤及诱发骨性关节炎。";
          shapeSuggest +=
            "松解、牵拉右足内侧肌群,并配合矫形鞋垫恢复正常足功能。";
        }
        if (rightShape == "旋外") {
          strShapeSummary += "右足踝旋外，易导致导致膝盖内侧受伤。";
          shapeSuggest +=
            "松解足外侧肌群强化右足内侧肌群，并配合矫形鞋垫恢复正常足功能。";
        }
        strShapeSummary += shapeSuggest;
      }

      if (
        this.footPressure.StaticLeftHeatRatio != undefined &&
        this.footPressure.StaticRightHeatRatio != undefined &&
        this.footPressure.FengZhiYaLiPostion
      ) {
        let distance =
          this.footPressure.StaticLeftHeatRatio -
          this.footPressure.StaticRightHeatRatio;
        if (distance / 100 > 0.05) {
          totalScore -= 10;
        }
        if (distance > 0) {
          strHeatSummary += `经检测您双侧负重不均匀，左${this.footPressure.StaticLeftHeatRatio}%、右${this.footPressure.StaticRightHeatRatio}%，最大负重区域在${this.footPressure.FengZhiYaLiPostion}，建议您加强核心区控制能力，调整静态站立及动态运动时的身体重心分布，将重心均匀分布在躯干中心，避免足底负荷过大造成损伤。`;
        } else {
          strHeatSummary += `经检测您双侧负重均匀，最大负重区域在${this.footPressure.FengZhiYaLiPostion}，足底损伤风险较小。建议运动时继续保持核心肌群发力，维持重心稳定。`;
        }
      }

      let archLeft = "";
      let archRight = "";
      if (this.footPressure.DataArchLeftType) {
        archLeft = this.footPressure.DataArchLeftType;
      }
      if (this.footPressure.DataArchRightType) {
        archRight = this.footPressure.DataArchRightType;
      }
      if (archRight != "正常") totalScore -= 5;
      if (archLeft != "正常") totalScore -= 5;
      if (archLeft == archRight) {
        if (archLeft == "正常")
          strArchSummary =
            "经检测您左右足形均正常，建议您在日常训练前后放松足踝周围肌肉，保持良好足部功能。";
        if (archLeft == "扁平")
          strArchSummary =
            "左右足均为扁平足，易导致跖趾区域应力性骨折。建议练习足趾行走，将前足的负重点从跖骨转移到趾骨，锻炼足背肌力量，恢复足底韧带张力，从而恢复足弓高度。";
        if (archLeft == "高弓")
          strArchSummary =
            "左右足为高弓足，易导致跟腱炎。建议练习足背伸和足外翻动作，并配合足弓矫正器恢复足弓高度。";
      } else {
        strArchSummary = "经检测您";
        let archSuggest = "建议您";
        if (archLeft == "扁平") {
          strArchSummary += "左足为扁平足，易导致跖趾区域应力性骨折；";
          archSuggest +=
            "左足练习足趾行走，将前足的负重点从跖骨转移到趾骨，锻炼足背肌力量，恢复足底韧带张力，从而恢复足弓高度。";
        }
        if (archLeft == "高弓") {
          strArchSummary += "左足为高弓足，易导致跟腱炎；";
          archSuggest +=
            "左足练习足背伸和足外翻动作，并配合足弓矫正器恢复足弓高度。";
        }
        if (archRight == "扁平") {
          strArchSummary += "右足为扁平足，易导致跖趾区域应力性骨折。";
          archSuggest +=
            "右足练习足趾行走，将前足的负重点从跖骨转移到趾骨，锻炼足背肌力量，恢复足底韧带张力，从而恢复足弓高度。";
        }
        if (archRight == "高弓") {
          strArchSummary += "右足为高弓足，易导致跟腱炎。";
          archSuggest +=
            "右足练习足背伸和足外翻动作，并配合足弓矫正器恢复足弓高度。";
        }
        strArchSummary += archSuggest;
      }
      if (totalScore < 60) {
        totalScore = 60;
      }
      this.footPressure.Score = totalScore;
      this.footPressure.ShapeSummary = strShapeSummary.trimEnd("\n");
      this.footPressure.HeatSummary = strHeatSummary.trimEnd("\n");
      this.footPressure.DataArchSummary = strArchSummary.trimEnd("\n");
      return this.footPressure.Score;
    },
  },
  data() {
    return {
      checkType: "FootPressure",
      //膝关节损伤
      footPressure: {},
      footPressureRules: {
        ShapeSummary: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        HeatSummary: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataArchSummary: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        Score: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        // Summary: {
        //   required: true,
        //   message: "请输入",
        //   trigger: "null",
        // },
        FengZhiYaLiPostion: {
          required: true,
          message: "请选择",
          trigger: "null",
        },
        StaticLeftAreaFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftAreaBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftArea: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftMaxHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftAveHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftHeatRatioFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftHeatRatioBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftHeatRatio: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftCoverFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftCoverBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticLeftWeight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        StaticRightAreaFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightAreaBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightArea: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightMaxHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightAveHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightHeatRatioFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightHeatRatioBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightHeatRatio: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightCoverFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightCoverBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        StaticRightWeight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        PostureLeftAreaFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftAreaBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftArea: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftMaxHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftAveHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftHeatRatioFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftHeatRatioBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftHeatRatio: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftCoverFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftCoverBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureLeftWeight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightAreaFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightAreaBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightArea: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightMaxHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightAveHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightHeatRatioFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightHeatRatioBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightHeatRatio: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightCoverFront: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightCoverBack: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        PostureRightWeight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicLeftShape: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicLeftCPEI: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicLeftArea: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicLeftMaxHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicLeftAveHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicRightShape: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicRightCPEI: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        DynamicRightArea: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicRightMaxHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DynamicRightAveHeat: {
          required: true,
          message: "请输入",
          trigger: "null",
        },

        DataLeftSize: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataRightSize: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataArchRightType: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataArchLeftType: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataFootLeftSize: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataFootRightSize: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataEveLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataEveRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataInvRight: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
        DataInvLeft: {
          required: true,
          message: "请输入",
          trigger: "null",
        },
      },
    };
  },
  methods: {
    uploadSuccess(val) {
      if (
        val.file.Type == "FootStatic" ||
        val.file.Type == "FootPose" ||
        val.file.Type == "FootDynamic" ||
        val.file.Type == "FootShape"
      ) {
        let param = {
          checkKey: this.checkKey,
          checkType: val.bussiness,
          attachType: val.file.Type,
          dataFile: val.file.Path,
          checkItem: this.footPressure,
        };
        loadFileData(param).then((res) => {
          if (res) {
            this.footPressure = res;
          }
        });
      }
    },
    //确保有附件集合对象;
    ensureAttachments() {
      if (!this.footPressure.Attachments) {
        this.$set(this.footPressure, "Attachments", {
          ItemType: "XTH.Attachment",
          Items: [],
        });
      }
    },
    doValidate() {
      this.$refs[this.checkType].validate((valid) => {
        if (valid) {
          this.$emit("passValidate", this.footPressure);
        } else {
          setTimeout(() => {
            this.$refs[this.checkType].clearValidate();
          }, 3000);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-form {
  margin-top: 15px;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  padding-top: 0 !important;
}
/deep/.is-disabled {
  .el-input__inner {
    color: #333 !important;
  }
}
.done {
  color: #008000;
  font-size: 20px;
  font-weight: bolder;
}
.wait {
  color: #ffa500;
}
.component-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-right: 50px;

    .form-item {
      .form-item-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
      .form-item-label {
        text-align: center;
        padding: 10px 0;
        font-size: 14px;
        width: 100%;
        font-weight: bolder;
      }
    }
  }
  .btns {
    padding: 10px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /deep/.el-card {
    flex: 1;
    margin-right: 10px;
    height: inherit !important;
    .el-card__body {
      // text-align: center;
      .box-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    padding-bottom: 60px;
  }
}
</style>