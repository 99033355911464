var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [
              _vm._v(
                "IKDC国际膝关节文献委员会膝关节评估表（the international knee documentation committee knee evaluation from）是目前国际上公认的对于膝关节韧带损伤特别是前交叉韧带损伤、缺损的评估量表,有着比较高的可靠性、有效性和敏感性。"
              )
            ])
          ]),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.dataForm,
                inline: false,
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer1" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 1.如果膝关节没有显著的疼痛，您认为您能达到的最好活动水平是? "
                    )
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.Answer1,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "Answer1", $$v)
                        },
                        expression: "dataForm.Answer1"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 5 } }, [
                        _vm._v(
                          "非常剧烈的运动，如篮球、足球运动中的跳跃、旋转等"
                        )
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("剧烈运动，如重体力劳动，滑雪，乒乓球，网球")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("中等程度活动，如中度体力劳动，跑步，慢跑")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("轻体力活动，如散步，家务劳动，或庭院劳动")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("由于膝关节的疼痛，以上活动都不能进行")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer2" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 2.在过去的4周里，或从您受伤开始（受伤至今小于4周)，疼痛的频率有多少? "
                    )
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("持续疼痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.Answer2,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "Answer2", $$v)
                      },
                      expression: "dataForm.Answer2"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer3" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("3.如果有疼痛，疼痛的程度有多重？")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.Answer3,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "Answer3", $$v)
                      },
                      expression: "dataForm.Answer3"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer4" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 4.在过去的4周里,或从您受伤开始(如果从受伤至今小于4周),膝关节僵硬或肿胀程度如何? "
                    )
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.Answer4,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "Answer4", $$v)
                        },
                        expression: "dataForm.Answer4"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 5 } }, [
                        _vm._v("完全没有僵硬或肿胀")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("轻度僵硬或肿胀")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("中度僵硬或肿胀")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("重度僵硬或肿胀")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("极重度僵硬或肿胀")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer5" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 5.如果膝关节没有显著肿胀，您能达到的最好活动水平是? "
                    )
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.Answer5,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "Answer5", $$v)
                        },
                        expression: "dataForm.Answer5"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 5 } }, [
                        _vm._v(
                          "非常剧烈的运动，如篮球、足球运动中的跳跃，旋转等"
                        )
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("剧烈运动，如重体力劳动,滑雪，乒乓球，网球")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("中等程度活动，如中度体力活动,跑步，慢跑")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("轻体力活动，如散步，家务劳动，或庭院劳动")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("由于膝关节的疼痛，以上活动都不能进行")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "dataForm.Answer6" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 6.在过去的4周里，或从您受伤开始（如果从受伤至今小于周)，膝关节有过交锁现象（指膝关节在进行屈伸活动时会出现卡顿、卡压、或者有明显活动受限的表现）吗? "
                    )
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.Answer6,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "Answer6", $$v)
                        },
                        expression: "dataForm.Answer6"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 5 } }, [_vm._v("无")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer7" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 7.如果没有膝关节打软腿（走路时膝盖发软，不受控制要摔倒）的现象，您能达到的最好活动水平是? "
                    )
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.Answer7,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "Answer7", $$v)
                        },
                        expression: "dataForm.Answer7"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 5 } }, [
                        _vm._v(
                          "非常剧烈的运动，如篮球、足球运动中的跳跃，旋转等"
                        )
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("剧烈运动，如重体力劳动，滑雪，乒乓球，网球")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("中等程度活动，如中度体力活动，跑步，慢跑")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("轻体力活动，如散步，家务劳动，或庭院劳动")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("由于膝关节的疼痛，以上活动都不能进行")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer8" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(" 8.一般情况下，您最好可以参加哪个水平的运动? ")
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.Answer8,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "Answer8", $$v)
                        },
                        expression: "dataForm.Answer8"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 5 } }, [
                        _vm._v(
                          "非常剧烈的运动，如篮球、足球运动中的跳跃，旋转等"
                        )
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("剧烈运动，如重体力劳动，滑雪，乒乓球，网球")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("中等程度活动，如中度体力活动，跑步，慢跑")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("轻体力活动，如散步，家务劳动，或庭院劳动")
                      ]),
                      _c("br"),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("由于膝关节的疼痛，以上活动都不能进行")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 9.膝关节的问题对您的日常生活有影响吗？ 如果有，影响程度如何？ "
                    )
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_1" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("上楼")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_1,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_1", $$v)
                            },
                            expression: "dataForm.Answer9_1"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_2" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("下楼")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_2,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_2", $$v)
                            },
                            expression: "dataForm.Answer9_2"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_3" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("直跪")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_3,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_3", $$v)
                            },
                            expression: "dataForm.Answer9_3"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_4" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("下蹲")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_4,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_4", $$v)
                            },
                            expression: "dataForm.Answer9_4"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_5" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("膝关节弯曲坐下")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_5,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_5", $$v)
                            },
                            expression: "dataForm.Answer9_5"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_6" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("从椅子上站起")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_6,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_6", $$v)
                            },
                            expression: "dataForm.Answer9_6"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_7" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("向前奔跑")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_7,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_7", $$v)
                            },
                            expression: "dataForm.Answer9_7"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_8" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("用伤腿跳起并落地")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_8,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_8", $$v)
                            },
                            expression: "dataForm.Answer9_8"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dataForm.Answer9_9" } },
                    [
                      _c("div", { staticClass: "option-title" }, [
                        _vm._v("迅速停止并开始")
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dataForm.Answer9_9,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "Answer9_9", $$v)
                            },
                            expression: "dataForm.Answer9_9"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("无影响")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("轻度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("中度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("重度影响")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不能进行")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer10" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 10.用0到10的等级来评价您的膝关节的功能，0代表不能进行一般的日常活动，10代表正常的功能，受伤前膝关节的功能？ "
                    )
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.Answer10,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "Answer10", $$v)
                      },
                      expression: "dataForm.Answer10"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "dataForm.Answer11" } },
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v(
                      " 11.用0到10的等级来评价您的膝关节的功能，0代表不能进行一般的日常活动，10代表正常的功能，目前膝关节的功能？ "
                    )
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("不能进行日常活动")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("日常活动不受限制")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.Answer11,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "Answer11", $$v)
                      },
                      expression: "dataForm.Answer11"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.readOnly
        ? _c("el-card", { attrs: { shadow: "never" } }, [
            _c(
              "div",
              { staticClass: "footer" },
              [
                _vm.step
                  ? _c(
                      "el-button",
                      {
                        staticClass: "submit-btn",
                        attrs: { type: "warning" },
                        on: { click: _vm.handlePreStep }
                      },
                      [_vm._v(" 上一步 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "submit-btn",
                    attrs: { type: "success" },
                    on: { click: _vm.handleSubmit }
                  },
                  [_vm._v(_vm._s(_vm.step ? "下一步" : "保存"))]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }