import axios from 'axios'
import { Message, MessageBox,Loading } from 'element-ui'
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 100000, // request timeout
})

// 拦截请求
service.interceptors.request.use(
  
  (config) => {
    Loading.service({text:"处理中...",background: 'rgba(0, 0, 0, 0.3)'});
    if (localStorage.getItem('X-Token')) {
      //  存在将api_token写入 request header
      config.headers.common['Authorization'] = localStorage.getItem('X-Token')
    }
    config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    return config
  },
  (error) => {
    // 显示调试错误信息
    console.log(error)
    // 显示错误信息;
    Message({
      showClose: true,
      dangerouslyUseHTMLString: true,
      message: error,
      type: 'error',
      duration: 10000,
    })
    Promise.reject(error)
  }
)

// 拦截返回
service.interceptors.response.use(
  (response) => {
    Loading.service().close();
    if (response.data.msg === '超时') {
      console.log('超时')
      // 服务器端控制用户超时
      MessageBox.alert('登录超时！请重新登录。', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: () => {
          localStorage.removeItem('X-Token')
          localStorage.removeItem('user')
          window.location.href = '/' // 回到登录页
        },
      })
    } else if (response.data.code === 0) {
      // 业务逻辑成功的返回结果,直接处理json
      const resolveData = JSON.parse(response.data.data)
      return Promise.resolve(resolveData)
    } else {
      Message({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: response.data.msg,
        type: 'error',
        duration: 10000,
      })
      // 业务逻辑不成功的返回
      return Promise.reject(response.data.msg)
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    // Toast.clear()
    Loading.service().close();
    Message({
      showClose: true,
      dangerouslyUseHTMLString: true,
      message: error,
      type: 'error',
      duration: 10000,
    })
    return Promise.reject('')
  }
)

export default service
