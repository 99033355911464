<template>
  <el-upload action="1" ref="upload" :auto-upload="true" :limit="1" :show-file-list="false" :on-change="fileChange"
    :before-upload="beforeUpload" :http-request="uploadSubmit"></el-upload>
</template>
<script>
import { uploadFileWithName } from "@/api/upload";
export default {
  data() {
    return {
      file: "",
      type: "Other",
      loading: "",
    };
  },
  methods: {
    chooseFile(type) {
      if (type) this.type = type;
      else this.type = "Other";
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    fileChange(file) {
      this.file = file;
    },
    beforeUpload(file) {
      return true;
    },
    uploadSubmit() {
      this.loading = this.$loading({
        lock: true,
        text: "正在上传...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const formData = new FormData();
      formData.append("file", this.file.raw);
      formData.append("type", this.type);
      formData.append("fileName", this.file.name);
      uploadFileWithName(formData)
        .then((res) => {
          if (res) {
            this.loading.close();
            this.$emit("uploadSuccess", res);
            this.$refs["upload"].clearFiles();
          }
        })
        .catch((ex) => {
          this.loading.close();
          this.msg.errorMsg(ex);
        });
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/.el-upload {
  display: none;
}
</style>