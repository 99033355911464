<template>
  <div class="page-container">
    <checkHeader ref="checkHeader" v-model="checkInfo" :readOnly="readOnly" />
    <div class="panel">
      <checkPanel
        :disable="readOnly"
        ref="checkPanel"
        :accordion="false"
        :checkKey="key"
        @completed="completedCheck"
      ></checkPanel>
    </div>
  </div>
</template>
<script>
import checkPanel from "./components/checkPanel.vue";
import checkHeader from "./components/checkHeader.vue";
import { getSingle, updateCheckStatus } from "@/api/check/check";
export default {
  components: { checkPanel, checkHeader },
  data() {
    return {
      key: "",
      checkInfo: { Key: "" },
    };
  },
  computed: {
    readOnly() {
      if (this.checkInfo) {
        return this.checkInfo.Status >= 30;
      }
      return false;
    },
  },
  methods: {
    loadCheckInfo() {
      if (this.key) {
        let param = { key: this.key };
        getSingle(param).then((res) => {
          if (res) {
            this.checkInfo = res;
          }
        });
      }
    },
    completedCheck() {
      let dataForm = {
        key: this.checkInfo.Key,
        status: "Done",
        desc: "完成评测",
      };
      updateCheckStatus(dataForm)
        .then((res) => {
          this.checkInfo.Status = 100;
          this.msg.successMsg("操作成功！");
        })
        .catch((ex) => {
          this.msg.errorMsg(ex);
        });
    },
  },
  mounted() {
    this.key = this.$route.query.key;
    this.loadCheckInfo();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  .customer {
    height: 130px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    /deep/.el-descriptions__header {
      margin-bottom: 10px;
    }
    .item {
      margin-right: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #e6a23c;
    }
  }
  .panel {
    height: calc(100% - 150px);
    margin-top: 20px;
  }
}
</style>