var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "el-form",
            { attrs: { inline: false, "label-position": "top" } },
            [
              _c("div", { staticClass: "test" }, [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    staticStyle: { "text-align": "center" }
                  },
                  [
                    _c("img", {
                      staticClass: "img",
                      attrs: { src: "/images/vas.jpg" }
                    })
                  ]
                )
              ]),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("左踝疼痛评分")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.AnkleLeftScore,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "AnkleLeftScore", $$v)
                      },
                      expression: "dataForm.AnkleLeftScore"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("右踝疼痛评分")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.AnkleRightScore,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "AnkleRightScore", $$v)
                      },
                      expression: "dataForm.AnkleRightScore"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("左膝疼痛评分")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.KneeLeftScore,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "KneeLeftScore", $$v)
                      },
                      expression: "dataForm.KneeLeftScore"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("右膝疼痛评分")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.KneeRightScore,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "KneeRightScore", $$v)
                      },
                      expression: "dataForm.KneeRightScore"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("左髋疼痛评分")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.HipLeftScore,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "HipLeftScore", $$v)
                      },
                      expression: "dataForm.HipLeftScore"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "answer-title" }, [
                    _vm._v("右髋疼痛评分")
                  ]),
                  _c("div", { staticClass: "options" }, [
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("无痛")
                    ]),
                    _c("div", { staticClass: "option-title" }, [
                      _vm._v("想象中最严重的痛")
                    ])
                  ]),
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 0, max: 10 },
                    model: {
                      value: _vm.dataForm.HipRightScore,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "HipRightScore", $$v)
                      },
                      expression: "dataForm.HipRightScore"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.readOnly
        ? _c("el-card", { attrs: { shadow: "never" } }, [
            _c(
              "div",
              { staticClass: "footer" },
              [
                _vm.step
                  ? _c(
                      "el-button",
                      {
                        staticClass: "submit-btn",
                        attrs: { type: "warning" },
                        on: { click: _vm.handlePreStep }
                      },
                      [_vm._v(" 上一步 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "submit-btn",
                    attrs: { type: "success" },
                    on: { click: _vm.handleSubmit }
                  },
                  [_vm._v(_vm._s(_vm.step ? "下一步" : "保存"))]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }